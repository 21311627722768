import React from "react";
import styled from "styled-components";

const goToAnchor = function(anchorId) {
  if (document.getElementById(anchorId) !== null) {
    document.documentElement.scrollTop = document.body.scrollTop =
      document.getElementById(anchorId).offsetTop - 100;
  }
};

//nadpisanie funkcjonalności klikniecia w linki do kotwic
export const processAnchorLink = {
  shouldProcessNode: node =>
    node.name &&
    node.name === "a" &&
    node.attribs &&
    node.attribs["class"] === "mkanchor",
  processNode: function(node, children, index) {
    const anchorId =
      node.attribs.href.split("#").length > 1
        ? node.attribs.href.split("#")[1]
        : null;
    return (
      <AnchorSpan
        title={node.attribs.title}
        onClick={goToAnchor.bind(this, anchorId)}
      >
        {node.attribs.title}
      </AnchorSpan>
    );
  }
};

const AnchorSpan = styled.span`
  color: ${({ theme }) => theme.text.secondaryColor};
  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
    cursor: pointer;
  }
`;
