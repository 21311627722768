import React from "react";
import { connect } from "react-redux";
import {
  createBreadcrumbItem,
  withAccessKey,
  withRedirect
} from "./../../utils/breadcrumbs";
import {
  setBreadcrumbs,
  clearBreadcrumbs
} from "./../../redux/modules/breadcrumbs";

const withBreadcrumbs = WrappedComponent => {
  class WithBreadcrumbs extends React.Component {
    componentWillUnmount() {
      this.props.clearBreadcrumbs();
    }

    render() {
      const { setBreadcrumbs, clearBreadcrumbs } = this.props;

      const props = {
        ...this.props,
        setBreadcrumbs,
        clearBreadcrumbs,
        setContextBreadcrumbs: this.setContextBreadcrumbs
      };

      return <WrappedComponent {...props} />;
    }

    setContextBreadcrumbs = (items = []) => {
      const contextBreadcrumbs =
        this.props.friendlyUrl === "default"
          ? [withAccessKey(createBreadcrumbItem("Home page", "/"), "G")]
          : [
              withRedirect(
                withAccessKey(createBreadcrumbItem("Home page", "/"), "G")
              ),
              createBreadcrumbItem(this.props.siteTitle, this.props.friendlyUrl)
            ];

      this.props.setBreadcrumbs([...contextBreadcrumbs, ...items]);
    };
  }

  const mapStateToProps = ({ context }) => ({
    friendlyUrl: context.contextFriendlyUrl,
    siteTitle: context.siteTitle
  });

  const mapDispatchToProps = dispatch => ({
    setBreadcrumbs: items => dispatch(setBreadcrumbs(items)),
    clearBreadcrumbs: () => dispatch(clearBreadcrumbs())
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithBreadcrumbs);
};

export default withBreadcrumbs;
