import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { matchPath, useLocation } from "react-router-dom";

import { previewRoutes } from "components/routes";

import {Button, ExternalLinkButton} from "./../buttons";

const PRIVACY_POLICY_KEY = "cookiePolicy";
const PRIVACY_POLICY_ACCEPTED = "true";

const PrivacyPolicy = ({ privatePolicyInfo, privatePolicyUrl }) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const { pathname } = useLocation();
  const isOnPreviewRoutes = previewRoutes.some(previewRoute => {
    return matchPath(pathname, previewRoute.path);
  });

  const acceptPrivacyPolicy = useCallback(() => {
    localStorage.setItem(PRIVACY_POLICY_KEY, PRIVACY_POLICY_ACCEPTED);
    setShowPrivacyPolicy(false);
  }, []);

  useEffect(
    () =>
      setShowPrivacyPolicy(
        localStorage.getItem(PRIVACY_POLICY_KEY) !== PRIVACY_POLICY_ACCEPTED
      ),
    []
  );

  if (isOnPreviewRoutes) {
    return null;
  }

  return showPrivacyPolicy ? (
    <Container id="cookie-bar">
      <PrivacyPolicyContent>{privatePolicyInfo}</PrivacyPolicyContent>
      <Button
        type="button"
        data-color="strongPrimary"
        id="accept-cookie"
        onClick={acceptPrivacyPolicy}
      >
        Rozumiem komunikat, zamknij
      </Button>
      {privatePolicyUrl && (
        <ExternalLinkButton
          data-color="strongSecondary"
          href={privatePolicyUrl}
          id="open-cookie"
          target="_blank"
        >
          Polityka prywatności
        </ExternalLinkButton>
      )}
    </Container>
  ) : null;
};

PrivacyPolicy.propTypes = {
  privatePolicyInfo: PropTypes.node,
  privatePolicyUrl: PropTypes.string
};

const Container = styled.section`
  z-index: 1050;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  background: ${({ theme }) => theme.darkPrimaryColor};

  @media print {
    display: none;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 768px) {
    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const PrivacyPolicyContent = styled.div`
  color: ${({ theme }) => theme.text.lightColor};
  font-size: 0.875em;
  flex-grow: 1;
`;

const mapStateToProps = ({ context }) => ({
  privatePolicyInfo: context.privatePolicyInfo,
  privatePolicyUrl: context.privatePolicyUrl
});

export default connect(mapStateToProps)(PrivacyPolicy);
