import React, { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { FieldPrototype } from "./FieldPrototype";
import CheckboxLabel from "../form-fields/components/CheckboxContainer";

interface IProps {
  label: string;
  name: string;
  options: string[];
  id: string;
  isRequired?: boolean;
}

const CheckboxGroupField = ({
  options,
  label,
  name,
  isRequired = true,
  id
}: IProps) => {
  return (
    <FieldPrototype isRequired={isRequired} label={label} name={name}>
      {({ methods: { setValue, getValues } }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          const checkboxGroupValue = getValues(name) || [];
          const { value, checked } = event.currentTarget;

          const newValue =
            checked && !checkboxGroupValue.includes(Number(value))
              ? [...checkboxGroupValue, Number(value)]
              : checkboxGroupValue
                  .map((singleCheckbox: string) => Number(singleCheckbox))
                  .filter((singleCheckboxValue: number) => {
                    return Number(singleCheckboxValue) !== Number(value);
                  });
          setValue(name, newValue);
        };

        return (
          <Controller
            name={name}
            render={props => {
              return (
                <>
                  {options.map((option, index) => (
                    <CheckboxLabel key={index}>
                      <input
                        id={`${id}-${index}`}
                        type="checkbox"
                        value={index}
                        onChange={handleChange}
                        checked={props.value?.includes(index)}
                      />
                      {option}
                    </CheckboxLabel>
                  ))}
                </>
              );
            }}
          />
        );
      }}
    </FieldPrototype>
  );
};

export { CheckboxGroupField };
