import { default as sanitizeHtmlLib } from "sanitize-html";

import { additionalAllowedTags } from "./additionalAllowedTags";
import { allowedAttributes } from "./allowedAttributes";

export const sanitizeHtml = (html: string) => {
  return sanitizeHtmlLib(html, {
    allowedAttributes: allowedAttributes,
    allowedTags: sanitizeHtmlLib.defaults.allowedTags.concat(
      additionalAllowedTags
    ),
    allowedSchemes: sanitizeHtmlLib.defaults.allowedSchemes.concat(["data"])
  });
};
