import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { BreadcrumbItem } from "./BreadcrumbItem";
import { Divider } from "./Divider";

const Breadcrumb = () => {
  const { items } = useMappedState(mapStateToProps);
  const preprocessedItems = items.filter(item => {
    return !(item.accessKey !== "G" && item.link === "/");
  });
  return (
    <BreadcrumbContainer id="breadcrumb">
      <BreadcrumbList className="breadcrumb">
        {preprocessedItems.map((item, index) => (
          <>
            <BreadcrumbItem
              key={index}
              to={item.link}
              title={item.title}
              current={index + 1 === preprocessedItems.length}
              itemAccessKey={item.accessKey}
              redirect={item.redirect}
            />
            {index !== preprocessedItems.length - 1 && (
              <li>
                <Divider />
              </li>
            )}
          </>
        ))}
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
};

const BreadcrumbContainer = styled.nav`
  min-height: 60px;
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }
`;

const BreadcrumbList = styled.ol`
  && {
    background: transparent;
    padding: 0;
    margin: 0;
  }
`;

const mapStateToProps = ({ breadcrumbs }) => ({ items: breadcrumbs });

export { Breadcrumb };
