import React, { ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { FieldPrototype } from "./FieldPrototype";
import { ISelectOption } from "./types";

interface IProps {
  label: string;
  name: string;
  options: string[] | ISelectOption[];
  id: string;
  isRequired?: boolean;
  emptyOption?: boolean;
}

const SelectField = ({
  name,
  label,
  options,
  id,
  isRequired = true,
  emptyOption = true
}: IProps) => {
  const { setValue } = useFormContext();

  let preparedOptions: ISelectOption[] = [];
  options.forEach((option: string | ISelectOption) => {
    if (typeof option === "object") {
      preparedOptions = [...preparedOptions, option];
      return;
    }
    preparedOptions = [...preparedOptions, { label: option, value: option }];
  });

  const getValue = (value: string | number[]) => {
    if (!value) {
      return "";
    }
    if (Array.isArray(value)) {
      return preparedOptions[value[0]].value.toString();
    }
    return preparedOptions.find(option => {
      return option.value.toString() === value.toString();
    })?.value;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value, { shouldValidate: true });
  };

  return (
    <FieldPrototype isRequired={isRequired} label={label} name={name}>
      {({ hasError }) => (
        <Controller
          name={name}
          render={props => {
            const { value, ...restProps } = props;
            return (
              <Select
                id={id}
                name={name}
                value={getValue(value)}
                hasError={hasError}
                {...restProps}
                onChange={handleChange}
              >
                {emptyOption && <option />}
                {preparedOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            );
          }}
        />
      )}
    </FieldPrototype>
  );
};

const Select = styled.select`
  display: block;
  width: 100%;
  padding: 0.375rem;
  border-radius: 3px;
  background: transparent;
  color: ${({ theme }) => theme.text.primaryColor};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
  background: ${({ theme }) => theme.defaultBackground};
  font-size: 1em;

  option {
    font-size: 1.125em;
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

export { SelectField };
