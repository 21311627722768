import React from "react";

import { withTranslate } from "../../../translate";

import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {}

export const SpanWithTranslation = withTranslate(({ children }: IProps) => {
  return <span>{children}</span>;
});
