import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

const RedStar = () => {
  const { isContrast } = useMappedState(mapStateToProps);
  return <StyledStar isContrast={isContrast}>*</StyledStar>;
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const StyledStar = styled.span`
  color: ${({ isContrast }) => (isContrast ? "white" : "red")};
`;

export { RedStar };
