import React from "react";
import styled from "styled-components";

import { Button } from "../buttons";
import { TextField } from "../HookForm";

interface IProps {
  name: string;
}

const SearchField = ({ name }: IProps) => {
  return (
    <StyledSearchField>
      <StyledTextField
        name={name}
        aria-label="Wyszukiwarka kontekstowa"
        placeholder="Szukaj w dziale"
        id="context-search-field"
      />
      <StyledSearchButton
        id="context-search-btn"
        type="submit"
        data-color="primary"
        aria-label="Szukaj"
        title="Szukaj"
      >
        <span aria-hidden={true} className="fa fa-search" />
      </StyledSearchButton>
    </StyledSearchField>
  );
};

const StyledSearchField = styled.div`
  display: flex;
  div {
    width: 100%;
  }
`;

const StyledSearchButton = styled(Button)`
  height: 34px;
`;

const StyledTextField = styled(TextField)`
  display: block;
  color: ${({ theme }) => theme.text.primaryColor};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  background: ${({ theme }) => theme.defaultBackground};
  font-size: 1em;
  padding: 0.25rem 0.5rem;

  &::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    cursor: pointer;
    padding: 0.125em;
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

export { SearchField };
