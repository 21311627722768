import React, { useState } from "react";
import styled from "styled-components";
import { Thumbnail } from "./components/Thumbnail";
import { getFileThumbnailUrl } from "../../../api/files";
import Carousel, { Modal, ModalGateway } from "react-images";

import { getFileUrl } from "modules/articles/api/getFileUrl";

const Gallery = ({ files }) => {
  const images = files.map(file => ({
    id: file.id,
    src: getFileUrl(file.id),
    thumbnailSrc: getFileThumbnailUrl(file.id),
    caption: file.name,
    alt: file.name,
    isModal: true
  }));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleModal = () => {
    setIsModalOpen(isModalOpen => !isModalOpen);
  };

  return (
    <>
      <ModalGateway>
        {isModalOpen ? (
          <Modal onClose={toggleModal}>
            <Carousel
              frameProps={{ accessibility: true }}
              currentIndex={currentIndex}
              views={images}
              formatters={{
                getAltText: props => {
                  //Błąd biblioteki, tak naprawdę nie dostajemy typu CommonProps jako props
                  return props?.data?.alt;
                },
                getCloseLabel: () => "Zamknij (Esc)",
                getFullscreenLabel: () => "Cały ekran",
                getNextTitle: () => "Kolejny obraz (Prawa strzałka)",
                getNextLabel: () => null,
                getPrevTitle: () => "Poprzedni obraz (Lewa strzałka)",
                getPrevLabel: () => null
              }}
              components={{
                Footer: ({ currentIndex, views, currentView }) => {
                  return (
                    <StyledFooter>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%"
                        }}
                      >
                        <span style={{ color: "white" }}>
                          {currentView.caption}
                        </span>
                        <span style={{ color: "white" }}>{`${currentIndex +
                          1} z ${views.length}`}</span>
                      </div>
                    </StyledFooter>
                  );
                }
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <ThumbnailsContainer>
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            image={image}
            onClick={() => {
              setCurrentIndex(index);
              toggleModal();
            }}
          />
        ))}
      </ThumbnailsContainer>
    </>
  );
};

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin: 6px;
  }

  img:focus {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

const StyledFooter = styled.div`
  background-color: #141414;
  bottom: 0;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex: 0 0 auto;
  font-size: 13px;
  justify-content: space-between;
  left: 0;
  padding: 30px 20px 20px;
  position: absolute;
  right: 0;
  transform: translateY(10px);
  transition: opacity 300ms, -webkit-transform 300ms, transform 300ms;
  z-index: 1;
  box-sizing: border-box;
`;

export default Gallery;
