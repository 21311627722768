const isContrast = () => isTrueInLocalStorage("hc");

const isTrueInLocalStorage = itemKey => {
  const item = localStorage.getItem(itemKey);
  return item && item === "true";
};

export {
  isContrast,
};
