import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import PropTypes from "prop-types";

import { changeAdditionalVideoPlayerAttributes } from "./changeAdditionalVideoPlayerAttributes";

const VideoPlayer = ({ url }) => {
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [autoPlaying, setAutoPlaying] = useState(false);
  const innerRef = useRef(null);
  const ref = useRef(null);
  const playerContainer = ref && ref.current;

  useEffect(() => {
    if (innerRef && innerRef.current && innerRef.current.offsetHeight > 0) {
      setAutoPlaying(true);
    }
  }, [innerRef]);

  useEffect(() => {
    changeAdditionalVideoPlayerAttributes(playerContainer);
  }, [playerContainer, isVideoReady]);

  return (
    <PlayerWrapper innerRef={innerRef} ref={ref}>
      <StyledReactPlayer
        onReady={() => setIsVideoReady(true)}
        id="VideoPlayer"
        className="react-player"
        url={url}
        controls={true}
        width=""
        height=""
        playing={autoPlaying}
      />
    </PlayerWrapper>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string
};

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export { VideoPlayer };
