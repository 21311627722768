import React, { memo } from "react";

import { IArticleAttachment } from "modules/articles/types/IArticleAttachment";

import { DownloadAll } from "./components/DownloadAll";
import { AttachmentsList } from "./components/AttachmentsList";

interface IProps {
  versionId: number;
  attachments: IArticleAttachment[];
  isHistorical: boolean;
  showAttachmentIcon: boolean;
  isPreview: boolean;
}

const Attachments = memo(
  ({
    versionId,
    attachments = [],
    isHistorical,
    showAttachmentIcon,
    isPreview
  }: IProps) => {
    const isSomeAttachmentsAreNotDeleted = attachments.some(
      attachment => !attachment.deleted
    );

    return (
      <>
        {isSomeAttachmentsAreNotDeleted && (
          <DownloadAll
            versionId={versionId}
            isHistorical={isHistorical}
            disabled={isPreview}
          />
        )}
        <AttachmentsList
          attachments={attachments}
          showAttachmentIcon={showAttachmentIcon}
        />
      </>
    );
  },
  (prevState, nextState) => {
    return (
      prevState.attachments.length === nextState.attachments.length &&
      prevState.versionId === nextState.versionId
    );
  }
);

export { Attachments };
