import React from "react";

import { ContentColumn } from "./ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";
import { Row } from "../../TableComponents/MetricGrid";
import {HeadColumn} from "./HeadColumn";
import {Translate} from "../../../translate";

interface IProps {
  expireDate: string;
}

const ExpirationDate = ({ expireDate }: IProps) => {
  return (
    <Row>
      <HeadColumn>
        <Translate>Date of archiving</Translate>
      </HeadColumn>
      <ContentColumn>
        <SpanWithTranslation>{expireDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { ExpirationDate };
