import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { MenuItem } from "./MenuItem";

const SubMenu = ({ items }) => (
  <List>
    {items.map((menuItem, index) => (
      <MenuItem key={index} item={menuItem} />
    ))}
  </List>
);

SubMenu.propTypes = {
  items: PropTypes.array
};

SubMenu.defaultProps = {
  items: []
};

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
  list-style-type: none;
`;

export { SubMenu };
