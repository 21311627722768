/**
 * Created by fkurzawa on 15.03.18.
 */
import { amber, white, grey, red, blueGrey } from "material-colors";

const contrastTheme = {
  primaryColor: amber["500"],
  darkPrimaryColor: grey["900"],
  lightPrimaryColor: grey["900"],
  secondaryColor: white,
  dividerColor: amber["500"],
  defaultBackground: grey["900"],
  errorColor: white,
  highlightBackground: white,
  focusOutline: "white solid 2px",

  text: {
    primaryColor: amber["500"],
    secondaryColor: amber["500"],
    lightColor: amber["500"]
  },

  buttons: {
    primary: {
      color: grey["900"],
      background: amber["500"],
      hoverBackground: amber["700"]
    },

    secondary: {
      color: grey["900"],
      background: amber["500"],
      hoverBackground: amber["700"]
    },

    default: {
      color: grey["900"],
      background: amber["500"],
      hoverBackground: amber["700"]
    },

    strongPrimary: {
      color: grey["900"],
      background: red["500"],
      hoverBackground: red["700"]
    },

    strongSecondary: {
      color: grey["900"],
      background: blueGrey["500"],
      hoverBackground: blueGrey["700"]
    }
  }
};

export default contrastTheme;
