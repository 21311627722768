export const additionalAllowedTags = [
  "img",
  "aside",
  "video",
  "audio",
  "iframe",
  "source",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "ul",
  "ol",
  "li",
  "blockquote",
  "p",
  "u",
  "em",
  "strong",
  "br",
  "a",
  "form",
  "input",
  "noscript",
  "span",
  "button",
  "video",
  "div",
  "table",
  "tbody",
  "tr",
  "td",
  "th"
];
