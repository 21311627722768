import { useEffect } from "react";

import useLocalStorage from "./useLocalStorage";

export const useAccessibilityClass = (
  localStorageKey: string,
  className: string
) => {
  const [
    isAccessibilityClassActive,
    setIsAccessibilityClassActive
  ] = useLocalStorage(localStorageKey, false);

  const toggleAccessibilityClass = () => {
    setIsAccessibilityClassActive(
      isAccessibilityClassActive => !isAccessibilityClassActive
    );
  };

  useEffect(() => {
    if (isAccessibilityClassActive) {
      document?.querySelector("#root")?.classList.add(className);
    } else {
      document?.querySelector("#root")?.classList.remove(className);
    }
  }, [isAccessibilityClassActive, className]);


  return {
    isAccessibilityClassActive,
    toggleAccessibilityClass
  };
};
