import styled from "styled-components";

export const SkipLink = styled.a`
  font-size: 0.875em;
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  color: ${({ theme }) => theme.text.primaryColor};
  position: absolute;
  left: -999em;

  &:focus {
    left: 0;
    z-index: 999;
  }

  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: none;
  }
`;
