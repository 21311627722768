import React, { useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useReactRouter from "use-react-router";
import { Button } from "components/buttons";
import { getRssUrl, getXmlUrl } from "../../../../api/menus";

const TitleBar = ({ title }) => {
  const { match } = useReactRouter();
  const openRss = useCallback(
    () => window.open(getRssUrl(match.params.id), "_blank"),
    [match.params.id]
  );
  const openXml = useCallback(
    () => window.open(getXmlUrl(match.params.id), "_blank"),
    [match.params.id]
  );

  return (
    <div>
      <TitleText>{title}</TitleText>
      <ButtonsContainer>
        <Button
          id="rss-reader-button"
          aria-label="Czytnik RSS"
          size="small"
          onClick={openRss}
          title="Czytnik RSS"
        >
          <span aria-hidden="true" className="fa fa-rss" />
        </Button>
        <Button
          title="XML"
          aria-label="XML"
          size="small"
          onClick={openXml}
          id="xml-button"
        >
          <span aria-hidden="true" className="fa fa-code" />
        </Button>
      </ButtonsContainer>
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string
};

TitleBar.defaultProps = {
  title: "Lista artykułów"
};

const TitleText = styled.span`
  font-size: 1.1em;
  font-weight: 700;
  margin-right: 9px;
`;

const ButtonsContainer = styled.div`
  display: inline-flex;
  & > *:not(:last-child) {
    min-width: 30px;
    margin-right: 0.5rem;
  }
`;

export default TitleBar;
