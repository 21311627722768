import { useMappedState } from "redux-react-hook";

export const useIsAuthorized = () => {
  const { isAuthenticated } = useMappedState(mapStateToProps);

  const isAuthorized = (loginRequired: boolean = false) => {
    return !loginRequired || (loginRequired && isAuthenticated);
  };

  return isAuthorized;
};

const mapStateToProps = ({ authentication }: any) => ({
  isAuthenticated: authentication.isAuthenticated
});
