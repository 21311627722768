import React from "react";
import PropTypes from "prop-types";

import Table from "../../../../components/table";
import ArticlesTableHeader from "./ArticlesTableHeader";
import ArticlesTableBody from "./ArticlesTableBody";

const ArticlesTable = ({ articles, columns, linkMoreEnabled }) => {
  return (
    <Table>
      <caption className="sr-only">Lista artykułów</caption>
      <ArticlesTableHeader columns={columns} />
      <ArticlesTableBody
        columns={columns}
        articles={articles}
        linkMoreEnabled={linkMoreEnabled}
      />
    </Table>
  );
};

ArticlesTable.propTypes = {
  articles: PropTypes.array,
  columns: PropTypes.array,
  linkMoreEnabled: PropTypes.bool
};

ArticlesTable.defaultProps = {
  articles: [],
  columns: []
};

export default ArticlesTable;
