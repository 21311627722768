import React from "react";
import { Icon, IIcon } from "../Icon";
import {
  mdiFile,
  mdiFileDocument,
  mdiFileExcel,
  mdiFileImage,
  mdiFileMusic,
  mdiFileOutline,
  mdiFilePdfOutline,
  mdiFilePowerpoint,
  mdiFileVideo,
  mdiFileWord,
  mdiFileXml
} from "@mdi/js";

interface IProps extends Omit<IIcon, "path"> {
  type: string;
}

const FileIcon = ({ type, ...restProps }: IProps) => {
  switch (type) {
    case "pdf":
      return <Icon path={mdiFilePdfOutline} {...restProps} />;
    case "doc":
    case "docx":
      return <Icon path={mdiFileWord} {...restProps} />;
    case "xls":
    case "xlsx":
      return <Icon path={mdiFileExcel} {...restProps} />;
    case "xml":
      return <Icon path={mdiFileXml} {...restProps} />;
    case "zip":
      return <Icon path={mdiFileOutline} {...restProps} />;
    case "odt":
    case "txt":
      return <Icon path={mdiFileDocument} {...restProps} />;
    case "jpeg":
    case "jpg":
    case "png":
    case "svg":
      return <Icon path={mdiFileImage} {...restProps} />;
    case "ppt":
    case "pptx":
      return <Icon path={mdiFilePowerpoint} {...restProps} />;
    case "mp3":
      return <Icon path={mdiFileMusic} {...restProps} />;
    case "mp4":
      return <Icon path={mdiFileVideo} {...restProps} />;
    default:
      return <Icon path={mdiFile} {...restProps} />;
  }
};

export { FileIcon };
