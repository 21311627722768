import React from "react";

import { useAccessibilityClass } from "utils/index";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const LinesSpacingButton = () => {
  const {
    isAccessibilityClassActive,
    toggleAccessibilityClass
  } = useAccessibilityClass("linesSpacing", "lines-spacing");

  const label = "Odstęp między wierszami";

  return (
    <Tooltip label={label} id="line-spacing-button">
      <DesktopAccessibilityButton
        aria-pressed={isAccessibilityClassActive}
        id="line-spacing-button"
        aria-label={label}
        onClick={toggleAccessibilityClass}
      >
        <span aria-hidden={true} className="fa fa-arrows-v" />{" "}
        <span aria-hidden={true} className="fa fa-align-left" />
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

export { LinesSpacingButton };
