/**
 * Created by fkurzawa on 26.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Tags = ({ tags }) => {
  return (
    <TagsList>
      {tags.map((tag, index) => (
        <TagsListItem key={index}>
          <TagLink to={`/${tag.link}`}>{tag.name}</TagLink>
        </TagsListItem>
      ))}
    </TagsList>
  );
};

Tags.propTypes = {
  tags: PropTypes.array.isRequired
};

const TagsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const TagsListItem = styled.li`
  margin-bottom: 12px;
  &:not(:last-child) {
    margin-right: 9px;
  }
`;

const TagLink = styled(Link)`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 1px;
  padding: 0.375rem 0.5rem;
  background: ${({ theme }) => theme.lightPrimaryColor};
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

export default Tags;
