import React from "react";
import { useMappedState } from "redux-react-hook";

import { BipLogo } from "./BipLogo";
import { CustomizedLogo } from "./CustomizedLogo";

const CustomizedLogoContainer = () => {
  const { logoMainHref, logoMainFileId, logoMainDescription } = useMappedState(
    mapStateToProps
  );

  return (
    <>
      {logoMainFileId ? (
        <CustomizedLogo
          logoMainFileId={logoMainFileId}
          logoMainHref={logoMainHref}
          title={logoMainDescription}
        />
      ) : (
        <BipLogo logoMainHref={logoMainHref} title={logoMainDescription} />
      )}
    </>
  );
};

const mapStateToProps = ({ context }: any) => ({
  logoMainHref: context.logoMainHref,
  logoMainFileId: context.logoMainFileId,
  logoMainDescription: context.logoMainDescription
});

export { CustomizedLogoContainer };
