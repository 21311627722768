/**
 * Created by fkurzawa on 20.03.18.
 */
import React from "react";
import PropTypes from "prop-types";

import { User } from "../../../../TableComponents/User";
import { Grid, Row } from "../../../../TableComponents/MetricGrid";
import { ContentColumn, HeadColumn } from "../../../../metrics/components";

const AttachmentMetric = ({ attachment }) => {
  return (
    <Grid>
      <Row>
        <HeadColumn id="attachment-metric-unit-name-row">
          Podmiot udostępniający informację
        </HeadColumn>
        <ContentColumn id="attachment-metric-unit-name">
          {attachment.unitName}
        </ContentColumn>
      </Row>
      <Row>
        <HeadColumn id="attachment-metric-create-user-row">
          Osoba wprowadzająca informację
        </HeadColumn>
        <ContentColumn>
          <User
            user={attachment.createUser}
            id="attachment-metric-create-user"
          />
        </ContentColumn>
      </Row>
      <Row>
        <HeadColumn id="attachment-metric-response-user-row">
          Osoba odpowiedzialna za informację
        </HeadColumn>
        <ContentColumn>
          <User
            user={attachment.responseUser}
            id="attachment-metric-response-user"
          />
        </ContentColumn>
      </Row>
      <Row>
        <HeadColumn id="attachment-metric-create-date-row">
            Czas wygenerowania
        </HeadColumn>
        <ContentColumn id="attachment-metric-create-date">
          {attachment.createDate}
        </ContentColumn>
      </Row>
      <Row>
        <HeadColumn id="attachment-metric-publish-date-row">
          Czas publikacji
        </HeadColumn>
        <ContentColumn id="attachment-metric-publish-date">
          {attachment.publishDate}
        </ContentColumn>
      </Row>
    </Grid>
  );
};

AttachmentMetric.propTypes = {
  attachment: PropTypes.object.isRequired
};

export { AttachmentMetric };
