import React from "react";

import { ContentColumn } from "./ContentColumn";
import { Row } from "../../TableComponents/MetricGrid";
import { Translate } from "../../../translate";
import { HeadColumn } from "./HeadColumn";

interface IProps {
  unitName: string;
}

const UnitName = ({ unitName }: IProps) => {
  return (
    <Row>
      <HeadColumn>
        <Translate>Organization providing the information</Translate>
      </HeadColumn>
      <ContentColumn>{unitName}</ContentColumn>
    </Row>
  );
};

export { UnitName };
