import React from "react";
import styled from "styled-components";

const UserTipRow = ({ label, value }) => (
  <Container>
    <Label>{label}:</Label>
    <Value>{value}</Value>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Label = styled.span`
  flex-grow: 0;
  min-width: 60px;
  flex-basis: 60px;
`;

const Value = styled.span`
  flex-grow: 1;
`;

export { UserTipRow };
