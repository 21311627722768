/**
 * Created by fkurzawa on 22.03.18.
 */
import styled from "styled-components";

const Row = styled.div.attrs({ className: "row no-gutters" })`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  }
  padding: 9px 0;
  display: flex;
`;

export { Row };
