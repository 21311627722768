// @ts-ignore
const getFontSize = ({ size = "medium" }) => sizes[size].fontSize;

// @ts-ignore
const getPadding = ({ size = "medium" }) => sizes[size].padding;

//@todo: Ogólnie to gruntowny refactoring tego rozwiązania jest potrzebny
const getBoxShadow = ({
  ...props
}: {
  "data-flat": string;
  "data-contrast": string;
}) => {
  const flat = props["data-flat"] || false;
  const contrast = props["data-contrast"] || false;

  return flat === "true" && contrast === "false"
    ? "none"
    : "0 1px 4px rgba(0, 0, 0, .6)";
};

const getColor = ({
  theme,
  ...restProps
}: {
  theme: any;
  "data-color": string;
  "data-flat": string;
  "data-contrast": string;
}) => {
  const color = restProps["data-color"] || "default";
  const flat = restProps["data-flat"] || false;
  const contrast = restProps["data-contrast"] || false;

  if (contrast === "true") {
    return flat === "true"
      ? theme.buttons["default"].background
      : theme.buttons["default"].color;
  }

  return contrast === "false" && flat === "true" && color !== "default"
    ? theme.buttons[color].background
    : theme.buttons[color].color;
};

const getBackground = ({
  theme,
  ...restProps
}: {
  theme: any;
  "data-color"?: string;
  "data-flat"?: string;
  "data-contrast"?: string;
}) => {
  const color = restProps["data-color"] || "default";
  const flat = restProps["data-flat"] || false;
  const contrast = restProps["data-contrast"] || false;

  if (contrast === "true") {
    return flat === "true"
      ? "transparent"
      : theme.buttons["default"].background;
  }
  return flat === "true" ? "transparent" : theme.buttons[color].background;
};

const getHoverBackground = ({
  theme,
  ...restProps
}: {
  theme: any;
  "data-color": string;
  "data-flat": string;
  "data-contrast": string;
}) => {
  const color = restProps["data-color"] || "default";
  const flat = restProps["data-flat"] || false;
  const contrast = restProps["data-contrast"] || false;

  if (contrast === "true") {
    return flat === "true"
      ? "transparent"
      : theme.buttons["default"].hoverBackground;
  }
  return contrast === "false" && flat === "true"
    ? "transparent"
    : theme.buttons[color].hoverBackground;
};

const getHoverBorderColor = ({
  theme,
  ...restProps
}: {
  theme: any;
  "data-color": string;
  "data-flat": string;
  "data-contrast": string;
}) => {
  const color = restProps["data-color"] || "default";
  const flat = restProps["data-flat"] || false;
  const contrast = restProps["data-contrast"] || false;

  if (contrast === "true") {
    return flat === "true"
      ? color === "default"
        ? theme.buttons["default"].color
        : theme.buttons["default"].background
      : "transparent";
  }
  return contrast === "false" && flat === "true"
    ? color === "default"
      ? theme.buttons[color].color
      : theme.buttons[color].background
    : "transparent";
};

const sizes = {
  small: {
    padding: ".25rem .5rem",
    fontSize: ".75em"
  },
  medium: {
    padding: ".375rem .75rem",
    fontSize: ".875em"
  },
  large: {
    padding: ".5rem 1rem",
    fontSize: "1em"
  }
};

export {
  getFontSize,
  getPadding,
  getBoxShadow,
  getColor,
  getBackground,
  getHoverBackground,
  getHoverBorderColor
};
