import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

// @ts-ignore
import fileSize from "filesize";

import { IArticleAttachment } from "modules/articles/types/IArticleAttachment";

import { FileIcon } from "../../../../../FileIcon";
import { AttachmentMetricPrintPreview } from "./AttachmentMetricPrintPreview";

interface IProps {
  attachment: IArticleAttachment;
  showAttachmentIcon: boolean;
}

const ItemInformation = ({ attachment, showAttachmentIcon }: IProps) => {
  const { printAttachmentMetric } = useMappedState(mapStateToProps);

  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        {showAttachmentIcon && (
          <div style={{ marginLeft: "5px", marginRight: "5px" }}>
            <FileIcon type={attachment.extension} />
          </div>
        )}
        <PrimaryText>{attachment.name}</PrimaryText>
      </div>
      <SecondaryContainer>
        <SecondaryText>Data: {attachment.publishDate}</SecondaryText>
        <SecondaryText>Rozmiar: {fileSize(attachment.size)}</SecondaryText>
        <SecondaryText>Format: .{attachment.extension}</SecondaryText>
        {printAttachmentMetric && (
          <AttachmentMetricPrintPreview attachment={attachment} />
        )}
        <SecondaryText>Pobrano: {attachment.downloadCounter}</SecondaryText>
      </SecondaryContainer>
    </Container>
  );
};

const mapStateToProps = ({ context }: any) => ({
  printAttachmentMetric: context.printAttachmentMetric
});

const Container = styled.div`
  padding-bottom: 6px;
  @media screen and (min-width: 992px) {
    padding-bottom: 0;
  }
`;

const PrimaryText = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.text.primaryColor};
  word-break: break-word;
  margin: 0;
  font-size: 0.875em;
`;

const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: 2.25rem;
    }
  }
`;

const SecondaryText = styled.span`
  color: ${({ theme }) => theme.text.secondaryColor};
  margin: 0;
  font-size: 0.75em;
`;

export { ItemInformation };
