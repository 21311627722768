/**
 * Created by fkurzawa on 15.03.18.
 */
import contrast from "./contrastTheme";
import grey from "./greyTheme";
import primary from "./primaryTheme";
import przedborz from "./przedborzTheme";
import pks from "./pksTheme";
import szamotuly from "./szamotulyTheme";
import whiteGrey from "./whiteGreyTheme";
import wiazowna from "./wiazownaTheme";

const themes = {
  contrast,
  gray: grey,
  primary,
  przedborz,
  pks,
  szamotuly,
  whiteGrey,
  wiazowna
};

export default themes;
