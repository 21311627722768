import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { Aside } from "../ArticleComponents/Aside";
import { Attachments } from "../ArticleComponents/Attachments";
import { Comments } from "../ArticleComponents/Comments";
import Faq from "../ArticleComponents/faq/Faq";
import Gallery from "../ArticleComponents/gallery/Gallery";
import Metrics from "../ArticleComponents/metrics/Metrics";
import Relations from "../ArticleComponents/relations/Relations";
import Tags from "../ArticleComponents/tags/Tags";
import HistoricalLink from "../ArticleComponents/historical-link/HistoricalLink";
import { Stamp } from "../ArticleComponents/TableComponents/Stamp";
import { Registries } from "../ArticleComponents/registries/Registries";
import { Versions } from "../ArticleComponents/versions/Versions";
import { Content } from "../ArticleComponents/Content/Content";
import { IArticle } from "../../types/IArticle";
import { Panel } from "../Panel";

interface IProps {
  article: IArticle;
  isPreview?: boolean;
}

const ArticleContent = ({ article, isPreview = false }: IProps) => {
  const { printMetric } = useMappedState(mapStateToProps);
  const { attachments = [] } = article;
  const listAttachments = attachments.filter(attachment => attachment.isList);

  const galleryAttachments = attachments.filter(
    attachment => attachment.isGallery && !attachment.deleted
  );

  const hasAside = article.placeholders && article.placeholders.length > 0;
  return (
    <ArticleContainer data-testid="test-ArticleContent">
      <MainSection>
        {article.isHistorical && <HistoricalLink link={article.actualLink} />}
        {article.additionalStamp && <Stamp>{article.additionalStamp}</Stamp>}
        <Content
          articleId={article.id}
          content={article.content}
          isHistorical={article.isHistorical}
          isPreview={isPreview}
        />
        {article.faq && (
          <Panel
            isInitiallyOpen={true}
            withCollapse={false}
            id="faq"
            title={article.faq.name}
          >
            <Faq faq={article.faq} />
          </Panel>
        )}
        {listAttachments && listAttachments.length > 0 && (
          <Panel
            isInitiallyOpen={true}
            withCollapse={false}
            id="attachments"
            printable
            title="Attachments"
          >
            <Attachments
              versionId={article.id}
              attachments={listAttachments}
              isHistorical={article.isHistorical}
              showAttachmentIcon={article.showAttachmentIcon}
              isPreview={isPreview}
            />
          </Panel>
        )}
        {galleryAttachments && galleryAttachments.length > 0 && (
          <Panel
            withCollapse={false}
            isInitiallyOpen={true}
            id="gallery"
            title="Gallery"
            printable={true}
          >
            <Gallery files={galleryAttachments} />
          </Panel>
        )}
        {article.relations && article.relations.length > 0 && (
          <Panel
            id="relations"
            title="Related articles"
            withCollapse={false}
            isInitiallyOpen={true}
          >
            <Relations relations={article.relations} />
          </Panel>
        )}
        {article.commentsEnabled && !isPreview && (
          <Panel id="comments" title="Comments" withCollapse>
            <Comments
              articleId={article.articleId}
              comments={article.comments}
            />
          </Panel>
        )}
        {!isPreview && (
          <Panel
            id="metric"
            printable={printMetric}
            title="Metrics"
            withCollapse
          >
            <Metrics
              views={article.views}
              unitName={article.basicData.unitName}
              createUser={article.basicData.createUser}
              responseUser={article.basicData.responseUser}
              createDate={article.basicData.createDate || "No data"}
              publicationDate={article.basicData.active || "No data"}
              expireDate={article.basicData.expire || "No data"}
              createInformationDate={
                article.basicData.createInformationDate || "No data"
              }
            />
          </Panel>
        )}
        {!isPreview && <Registries articleId={article.articleId} />}
        {!isPreview && <Versions articleId={article.articleId} />}
        {article.tags && article.tags.length > 0 && (
          <Panel id="keywords" title="Keywords" withCollapse>
            <Tags tags={article.tags} />
          </Panel>
        )}
      </MainSection>
      {hasAside && <Aside sections={article.placeholders} />}
    </ArticleContainer>
  );
};

const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const MainSection = styled.section`
  flex-grow: 1;
`;

const mapStateToProps = ({ context }: any) => ({
  printMetric: context.printMetric
});

export { ArticleContent };
