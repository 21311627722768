import axios from "axios";

import { IMenuArticlesList } from "modules/articles/types/IMenuArticlesList";

import ApiConfig from "../../../api/api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}menu`;

export const getMenuArticles = (
  menuId: number,
  limit: number = 10,
  offset: number = 0,
  sort: string[] | string | null,
  sort_dir: string[] | string | null,
  archived: number,
  tab: string[] | string | null,
  searchParams?: any
): Promise<IMenuArticlesList> =>
  axios
    .get(`${apiUrl}/${menuId}/articles`, {
      params: {
        limit,
        offset,
        sort,
        sort_dir,
        archived,
        tab,
        ...searchParams
      }
    })
    .then(response => response.data);
