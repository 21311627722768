import styled from "styled-components";
import React from "react";

export const processIFrameWithTraseoMap = {
  shouldProcessNode: node =>
    node.name &&
    node.name === "iframe" &&
    node.attribs.src &&
    node.attribs.src.includes("www.traseo.pl"),
  processNode: (node, children, index) => {
    const { frameborder, style, ...restAttribs } = node.attribs;
    return (
      <div>
        <StyledIFrameLink>
          <span>Zobacz mapę</span> <a href={node.attribs.src}>Tutaj</a>
        </StyledIFrameLink>
        <StyledIFrame data-frameBorder={frameborder} {...restAttribs} />
      </div>
    );
  }
};

const StyledIFrame = styled.iframe`
  border-width: ${props => props["data-frameBorder"]}px;
  @media print {
    display: none;
  }
`;

const StyledIFrameLink = styled.div`
  text-decoration: underline;
  display: none;
  @media print {
    display: block;
  }
`;
