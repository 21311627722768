import styled from "styled-components";

const TableRow = styled.tr`
  padding: 0.375rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  }
`;

export default TableRow;
