import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { get } from "lodash";
import { useMappedState } from "redux-react-hook";

import TableRow from "./TableRow";
import ColumnField from "./ColumnField";
import { mdiArrowRightBoldCircleOutline } from "@mdi/js";
import { Icon } from "../../../../../components/Icon";
import {Td} from "./Td";

const RowWithMore = ({ article, columns }) => {
  const { isContrast } = useMappedState(mapStateToProps);
  const articleTitle = get(article, "aliasFields[0].value");
  return (
    <TableRow>
      {columns.map((column, index) => (
        <Td key={column.id} $width={column.width}>
          {columns.length - 1 === index ? (
            <div
              style={{
                display: "flex",
                gap: "5px",
                lineHeight: "1.2",
                justifyContent: "space-between"
              }}
            >
              <ColumnField
                columnFields={column.columnFields}
                articleFields={article.columnFields}
              />
              <Link
                isContrast={isContrast}
                to={`/${article.link}`}
                aria-label={`Przejdź do artykułu: ${articleTitle}`}
                id={`show-more-btn-${article.id}`}
              >
                <StyledIcon
                  path={mdiArrowRightBoldCircleOutline}
                  title="Więcej"
                  color="default"
                  hoverAllowed={true}
                  size="25px"
                />
              </Link>
            </div>
          ) : (
            <ColumnField
              columnFields={column.columnFields}
              articleFields={article.columnFields}
            />
          )}
        </Td>
      ))}
    </TableRow>
  );
};

const mapStateToProps = ({ accessibility }) => ({
  isContrast: accessibility.isContrast
});

const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.text.secondaryColor};
  display: inline-block;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }
  float: right;
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
  &:-moz-focusring {
    outline: 2px solid ${({ isContrast }) => (isContrast ? "white" : "black")};
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: -17px;

  float: right;
`;

export { RowWithMore };
