import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Grid } from "../TableComponents/MetricGrid";

import {
  VisitorNumber,
  UnitName,
  ProducerOfInformation,
  ResponsibleUser,
  CreationDate,
  TimeOfPublication,
  ExpirationDate,
  CreateInformationDate
} from "./components";

const Metrics = ({
  views,
  unitName,
  createUser,
  responseUser,
  createDate,
  publicationDate,
  expireDate,
  createInformationDate,
  showCreateInformationDate
}) => {
  return (
    <Grid>
      <VisitorNumber views={views} />
      <UnitName unitName={unitName} />
      <ProducerOfInformation createUser={createUser} />
      <ResponsibleUser user={responseUser} />
      <CreationDate createDate={createDate} />
      <TimeOfPublication publicationDate={publicationDate} />
      <ExpirationDate expireDate={expireDate} />
      {showCreateInformationDate && (
        <CreateInformationDate createInformationDate={createInformationDate} />
      )}
    </Grid>
  );
};

Metrics.propTypes = {
  views: PropTypes.number,
  unitName: PropTypes.string,
  createUser: PropTypes.object,
  responseUser: PropTypes.object,
  createDate: PropTypes.string,
  publicationDate: PropTypes.string,
  expireDate: PropTypes.string,
  createInformationDate: PropTypes.string,
  showCreateInformationDate: PropTypes.bool
};

const mapStateToProps = ({ context }) => ({
  showCreateInformationDate:
    context.showCreateInformationDate.toString() === "true"
});

export default connect(mapStateToProps)(Metrics);
