export const changeRecaptchaCurrentAdditionalAttributes = (
  recaptchaCurrent: any | undefined
) => {
  const iframes = recaptchaCurrent?.captcha?.getElementsByTagName("iframe");
  const iframesLength = iframes?.length || 0;

  for (let i = 0; i < iframesLength; i++) {
    iframes[i].removeAttribute("frameborder");
    iframes[i].removeAttribute("width");
    iframes[i].removeAttribute("scrolling");
    iframes[i].removeAttribute("height");
    iframes[i].setAttribute(
      "style",
      "width: 316px; height: 90px; border: solid 1px #fff"
    );
  }
};
