/**
 * Created by fkurzawa on 26.03.18.
 */
import React from "react";
import styled from "styled-components";

import { AnswersList } from "./AnswersList";

import { IArticleComment } from "../../../../types/IArticle";

interface IProps {
  comments: IArticleComment[];
}

const CommentsList = ({ comments }: IProps) => {
  return (
    <List>
      {comments.map(comment => (
        <ListItem key={comment.id}>
          <PublishDate>Czas publikacji: {comment.publishedDate}</PublishDate>
          <Content>{comment.content}</Content>

          {comment.answers && comment.answers.length > 0 && (
            <AnswersList answers={comment.answers} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  font-size: 1em;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;

  &:not(:last-child) {
    padding-bottom: 0.6rem;
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  }
`;

const Content = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.3rem;
  }
  align-self: flex-start;
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};
`;

const PublishDate = styled.div`
  align-self: flex-end;
  font-size: 0.75em;
  color: ${({ theme }) => theme.text.secondaryColor};
`;

export { CommentsList };
