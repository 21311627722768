import React from "react";
import { useDispatch, useMappedState } from "redux-react-hook";

import { toggleContrast } from "redux/modules/accessibility";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const ContrastButton = () => {
  const { isContrast, showBlindControl } = useMappedState(mapState);

  const dispatch = useDispatch();
  const label = isContrast ? "Wersja standardowa" : "Wersja kontrastowa";

  //@todo: tu tak naprawdę mogłyby być (a nawet powinny) po prostu dwa oddzielne buttony

  if (!showBlindControl) {
    return null;
  }

  return (
    <Tooltip
      label={label}
      id={
        isContrast ? "contrast-version-button" : "non-contrast-version-button"
      }
    >
      <DesktopAccessibilityButton
        data-color="secondary"
        onClick={() => dispatch(toggleContrast())}
        aria-label={label}
        id={
          isContrast ? "contrast-version-button" : "non-contrast-version-button"
        }
      >
        {isContrast ? (
          <>
            {/*eslint-disable-next-line*/}
            <span
              className="fa fa-eye-slash"
              accessKey={isContrast ? "K" : ""}
              aria-hidden={true}
            />
          </>
        ) : (
          <>
            {/*eslint-disable-next-line*/}
            <span className="fa fa-eye" accessKey={isContrast ? "" : "K"} aria-hidden={true}/>
          </>
        )}
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

const mapState = ({ accessibility, context }: any) => ({
  isContrast: accessibility.isContrast,
  showBlindControl: context.showBlindControl,
});

export { ContrastButton };
