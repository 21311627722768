import React from "react";

//usuwanie obrazka gwiazdki ze spanow dla kotwic w artykule
export const processAnchor = {
  shouldProcessNode: node =>
    node.name &&
    node.name === "span" &&
    node.attribs &&
    node.attribs["class"] === "mkanchor",
  processNode: (node, children, index) => (
    <span id={node.attribs.id} title={node.attribs.title}>
      {React.Children.map(children, (child, i) => {
        if (child.type === "img") return;
        return child;
      })}
    </span>
  )
};
