import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";
import { Image } from "./Image";

import localLogoUrl from "../../../../assets/img/madkom_logo_new.png";

import { getImageSource } from "../../utils/getImageSource";

import { Link } from "./Link";

const MadkomLogo = () => {
  const { logoMainHref, logoMainFileId, isContrast } = useMappedState(
    mapStateToProps
  );
  const logoUrl = logoMainFileId
    ? getImageSource(logoMainFileId)
    : localLogoUrl;
  const companyTitle = "Madkom";
  const bifTitle = "Biuletyn informacji firmowej";
  return (
    <MadkomLogoContainer>
      {!isContrast ? (
        <>
          <StyledBifTitle>BIF</StyledBifTitle>
          <Link
            href={logoMainHref}
            id="logo"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex" }}
          >
            <StyledImage alt={companyTitle} src={logoUrl} />
          </Link>
        </>
      ) : (
        <>
          <span style={{ marginRight: "5px" }}>{bifTitle}</span>
          <Link
            href={logoMainHref}
            id="logo"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`logo ${companyTitle}`}
          </Link>
        </>
      )}
    </MadkomLogoContainer>
  );
};

const MadkomLogoContainer = styled.div`
  display: flex;
`;

const StyledBifTitle = styled.span`
  font-size: 3em;
  background-color: #ab0a11;
  color: #fff;
  padding: 0 10px;
  font-family: sans-serif;
  font-weight: 100;
  align-self: flex-end;
`;

const StyledImage = styled(Image)`
  margin-left: 10px;
  max-height: 50px;
  align-self: center;
`;

const mapStateToProps = ({ context, accessibility }: any) => ({
  isContrast: accessibility.isContrast,
  logoMainHref: context.logoMainHref,
  logoMainFileId: context.logoMainFileId
});

export { MadkomLogo };
