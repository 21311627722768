import React from "react";
import { FormProvider, useForm, UseFormMethods } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { Lazy, ObjectSchema } from "yup";

import { ChildrenAsFunction } from "types/ChildrenAsFunction";

interface IProps<T> {
  onSubmit: (model: any) => void;
  children: ChildrenAsFunction<UseFormMethods<T>>;
  defaultValues: any;
  validationSchema?: ObjectSchema | Lazy;
  resetOnSubmit?: boolean;
}

const Form = <T extends {}>({
  onSubmit,
  validationSchema,
  defaultValues,
  children,
  resetOnSubmit = true
}: IProps<T>) => {
  const methods = useForm<T>({
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
    mode: "all",
    defaultValues: defaultValues
  });

  const handleSubmit = async (model: any) => {
    await onSubmit(model);
    resetOnSubmit && methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        {children(methods)}
      </form>
    </FormProvider>
  );
};

export { Form };
