import React from "react";
import styled from "styled-components";

import { IArticleAttachment } from "modules/articles/types/IArticleAttachment";

import { ItemInformation } from "./ItemInformation";
import { ItemActions } from "./ItemActions";

interface IProps {
  attachment: IArticleAttachment;
  showAttachmentIcon: boolean;
}

const AttachmentListItem = ({
  attachment,
  showAttachmentIcon,
}: IProps) => {
  return (
    <ListItem>
      <InformationContainer>
        <ItemInformation
          attachment={attachment}
          showAttachmentIcon={showAttachmentIcon}
        />
      </InformationContainer>
      <ActionsContainer>
        <ItemActions attachment={attachment} />
      </ActionsContainer>
    </ListItem>
  );
};

const ListItem = styled.li`
  padding: 12px 0;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }

  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.dividerColor};
  }
`;

const InformationContainer = styled.div`
  flex-grow: 1;
`;

const ActionsContainer = styled.div`
  flex-shrink: 0;
`;

export { AttachmentListItem };
