import HtmlToReact from "html-to-react";

import { sanitizeHtml } from "components/SanitizeHtml";

import { getProcessingInstructions } from "./getProcessingInstructions";

export const createArticlePages = (
  content,
  articleId,
  isHistorical,
  isPreview
) => {
  const htmlToReactParser = new HtmlToReact.Parser();
  const pagedContent = content.split(paginationSeparator);
  const isValidNode = () => true;

  return pagedContent.map(contentPage =>
    htmlToReactParser.parseWithInstructions(
      sanitizeHtml(contentPage),
      isValidNode,
      getProcessingInstructions(articleId, isHistorical, isPreview)
    )
  );
};

const paginationSeparator =
  '<div class="pagination">{podzia&#322; strony}</div>';
