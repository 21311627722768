import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {
  name: string;
  showChildren: boolean;
}

const ConditionalField = ({ showChildren, children, name }: IProps) => {
  const { register, unregister } = useForm();

  useEffect(() => {
    if (showChildren) {
      register(name);
    } else {
      unregister(name);
    }
  }, [showChildren, name, unregister, register]);

  return <>{showChildren && children}</>;
};

export { ConditionalField };
