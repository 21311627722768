import React from "react";
import MK_MODULES from "./mk-modules.const";
import { OrganizationalUnits } from "../components/OrganizationalUnits";

export const processOrganizationUnits = {
  shouldProcessNode: node =>
    node.attribs &&
    node.attribs["data-module"] === MK_MODULES.ORGANIZATIONAL_UNITS,
  processNode: (node, children, index) => <OrganizationalUnits key={index} />
};
