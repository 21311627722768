import React from "react";

import { useAccessibilityClass } from "utils/index";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const LettersSpacingButton = () => {
  const {
    isAccessibilityClassActive,
    toggleAccessibilityClass
  } = useAccessibilityClass("lettersSpacing", "letters-spacing");
  const label = "Odstęp między literami";

  return (
    <Tooltip label={label} id="letter-spacing-button">
      <DesktopAccessibilityButton
        aria-pressed={isAccessibilityClassActive}
        id="letter-spacing-button"
        aria-label={label}
        onClick={toggleAccessibilityClass}
      >
        <span aria-hidden={true} className="fa fa-arrows-h" /> A
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

export { LettersSpacingButton };
