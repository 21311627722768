import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InfoWindow as GoogleInfoWindow } from "react-google-maps";

const InfoWindow = ({ marker, onCloseClick }) => {
  const { name, address, description } = marker;
  return (
    <GoogleInfoWindow onCloseClick={onCloseClick}>
      <React.Fragment>
        {name && <Name>{name}</Name>}
        {address && <Address>{address}</Address>}
        {description && <Description>{description}</Description>}
      </React.Fragment>
    </GoogleInfoWindow>
  );
};

InfoWindow.propTypes = {
  marker: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired
};

const InfoWindowElement = styled.div`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.text.primaryColor};
  text-align: center;
`;

const Name = styled(InfoWindowElement.withComponent("h5"))`
  font-size: 1.125em;
  text-transform: uppercase;
`;

const Address = InfoWindowElement.withComponent("address");

const Description = styled(InfoWindowElement.withComponent("p"))`
  margin-top: 1.125rem;
  border-top: 1px solid ${({ theme }) => theme.dividerColor};
  padding-top: 1rem;
`;

export default InfoWindow;
