//podmiana atrybutów aby validator w3c nie wyrzucał błędów ze względu na przeadwnione atrybuty
export const changeAdditionalVideoPlayerAttributes = (
  playerRefCurrent: any
) => {
  const playerIframe =
    playerRefCurrent && playerRefCurrent.getElementsByTagName("iframe")[0];

  if (playerIframe) {
    playerIframe.removeAttribute("frameborder");
    playerIframe.removeAttribute("width");
    playerIframe.removeAttribute("height");
    playerIframe.setAttribute("allowfullscreen", "");
    playerIframe.setAttribute("style", "width: 100%; height: 100%");
  }
};
