import React from "react";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withState } from "recompose";
import styled from "styled-components";

import { getArticle } from "../../api/articlePreviews";

import { ErrorPanel } from "components/ErrorPanel";

import { ArticleContent } from "../../components/ArticleContent";
import { withLoader } from "../../components/loader";

const Preview = ({ article, notFound }) => (
  <PreviewContainer>
    {article && <ArticleContent article={article} isPreview={true} />}
    {notFound && <ErrorPanel header="404" content="Nie odnaleziono artykułu" />}
  </PreviewContainer>
);

const PreviewContainer = styled.div`
  margin: 1rem;
`;

const enhance = compose(
  withRouter,
  withLoader,
  withState("article", "setArticle"),
  withState("notFound", "setNotFound", false),
  lifecycle({
    async componentDidMount() {
      this.props.setNotFound(false);
      this.props.startLoader();
      try {
        const { previewHash } = this.props.match.params;
        const article = await getArticle(previewHash);
        this.props.setArticle(article);
      } catch ({ response }) {
        this.props.setNotFound(response.status === 404);
      } finally {
        this.props.stopLoader();
      }
    }
  })
);

export { Preview, enhance };
export default enhance(Preview);
