import { Preview } from "../../../containers";
import { SurveyPreview } from "../../../containers/Surveys";

export const previewRoutes = [
  {
    path: "/(.*)?/p,:previewHash.html",
    component: Preview
  },
  {
    path: "/(.*,)?sp,:id,(.+).html",
    component: SurveyPreview
  }
];
