import { IContext } from "../../types/IContext";

export const shouldCreateAdditionalMenuItem = (context: IContext) => {
  const {
    contentRegistryEnabled,
    calculatorsEnabled,
    caseStatusesEnabled,
    ezdRegistryEnabled,
    editorsPageEnabled,
    accessibilityPageEnabled
  } = context;

  return (
    contentRegistryEnabled ||
    calculatorsEnabled ||
    caseStatusesEnabled ||
    ezdRegistryEnabled ||
    editorsPageEnabled ||
    accessibilityPageEnabled
  );
};
