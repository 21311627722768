/**
 * Created by fkurzawa on 06.06.18.
 */

import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { localStorageSaver } from "./modules/accessibility";
import { mainMenuSeparatorInjector } from "./modules/menu";

const enhancer = composeWithDevTools(
  applyMiddleware(thunk, localStorageSaver, mainMenuSeparatorInjector)
);

export default enhancer;
