import React from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "../../buttons";

interface IProps {
    id: string;
}

const ResetButton = ({id}: IProps) => {
  const { reset } = useFormContext();

  return (
    <Button type="button" id={id} onClick={() => reset()} data-color="secondary">
      Wyczyść
    </Button>
  );
};

export { ResetButton };
