import React, { Fragment, useEffect, useState } from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";
import { getGoogleMapConfiguration } from "../../../../../api/contexts";
import { GoogleMap, LeafletMap, Markers } from "./components";

const Maps = () => {
  const { friendlyUrl, mapProviderData } = useMappedState(({ context }) => ({
    friendlyUrl: context.contextFriendlyUrl,
    mapProviderData: context.mapProviderData
  }));

  const [mapConfiguration, setMapConfiguration] = useState();
  const [openedMarkerId, setOpenedMarkerId] = useState(null);

  useEffect(() => {
    (async () => {
      const mapConfiguration = await getGoogleMapConfiguration(friendlyUrl);
      setMapConfiguration({
        ...mapConfiguration,
        centerLat: Number(mapConfiguration.centerLat),
        centerLng: Number(mapConfiguration.centerLng),
        markers: mapConfiguration.markers.map(marker => ({
          ...marker,
          lat: Number(marker.lat),
          lng: Number(marker.lng)
        }))
      });
    })();
  }, [friendlyUrl]);

  return (
    <Container>
      {mapConfiguration && (
        <Fragment>
          <MapContainer>
            {(() => {
              switch (mapProviderData.provider) {
                case "google":
                  return (
                    <GoogleMap
                      mapConfiguration={mapConfiguration}
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapProviderData.apiKey}&v=3.exp&libraries=geometry,drawing,places`}
                      onMarkerClick={setOpenedMarkerId}
                      onMarkerCloseClick={() => setOpenedMarkerId(null)}
                      openedMarkerId={openedMarkerId}
                    />
                  );
                default:
                  return (
                    <LeafletMap
                      mapConfiguration={mapConfiguration}
                      mapProviderData={mapProviderData}
                      onMarkerClick={setOpenedMarkerId}
                      onMarkerCloseClick={() => setOpenedMarkerId(null)}
                      openedMarkerId={openedMarkerId}
                    />
                  );
              }
            })()}
          </MapContainer>
          <MarkersContainer>
            <Markers
              markers={mapConfiguration.markers}
              openedMarkerId={openedMarkerId}
              onMarkerClick={setOpenedMarkerId}
            />
          </MarkersContainer>
        </Fragment>
      )}
    </Container>
  );
};

const MapContainer = styled.div`
  flex-grow: 1;
  height: 350px;

  @media screen and (min-width: 576px) {
    height: 400px;
  }

  @media screen and (min-width: 992px) {
    height: 450px;
  }

  @media screen and (min-width: 1200px) {
    height: 500px;
  }
`;

const MarkersContainer = styled.div`
  min-width: 210px;
`;

const Container = styled.div`
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 992px) {
    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export default Maps;
