/**
 * Created by fkurzawa on 27.03.18.
 */
import styled from "styled-components";

const StyledErrorMessage = styled.span`
  display: block;
  color: ${({ theme }) => theme.errorColor};
  font-size: 0.875em;
`;

export { StyledErrorMessage };
