// @ts-ignore
import HtmlToReact from "html-to-react";
import React from "react";

import { getFileUrl } from "modules/articles/api/getFileUrl";
import { processTable } from "../../../Content/utils/processTables";
import { processImageSource } from "../../../Content/utils/processImageSource";
import { processIFrameWithTraseoMap } from "../../../Content/utils/processIFrameWithTraseoMap";

export const prepareContent = (content: any) => {
  const htmlToReactParser = new HtmlToReact.Parser();
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  //@todo: Czy aside zachowauje się tak jak artykuł, czy wszystkie funkcje preprocesowujące artykuł powinny znaleźć się też tutaj?
  const isValidNode = () => true;
  const processingInstructions = [
    {
      shouldProcessNode: (node: any) =>
        node.type === "tag" && (node.name === "source" || node.name === "img"),
      processNode: (node: any, children: any, index: any) => {
        const { src } = node.attribs;
        const [, id, query] = src.match(/id(?:=|,)(\w+)(?:.json)(\?.*)?$/);
        const updatedNode = {
          ...node,
          attribs: {
            ...node.attribs,
            src: getFileUrl(id) + (query ?? '')
          }
        };
        return processNodeDefinitions.processDefaultNode(
          updatedNode,
          children,
          index
        );
      }
    },
    processTable,
    processImageSource(processNodeDefinitions),
    processIFrameWithTraseoMap,
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ];

  return htmlToReactParser.parseWithInstructions(
    content,
    isValidNode,
    processingInstructions
  );
};
