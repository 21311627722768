/**
 * Created by fkurzawa on 10.05.18.
 */
import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { SortableElement } from "react-sortable-hoc";

class SortableItem extends React.Component {
  render() {
    const { item } = this.props;

    return <ListItem>{item.name}</ListItem>;
  }
}

const ListItem = styled.li`
  list-style: none;
  background: ${({ theme }) => theme.defaultBackground};
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};
  cursor: grab;
  padding: 0.5rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.dividerColor};

  &:hover {
    background: ${({ theme }) => theme.lightPrimaryColor};
  }

  &:not(:first-child) {
    margin-top: -1px;
  }
`;

const enhance = compose(SortableElement);

export default enhance(SortableItem);
