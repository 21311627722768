export const processTableRows = processNodeDefinitions => {
  return {
    shouldProcessNode: node =>
      (node.type === "tag" && node.name === "td") || node.name === "th",
    processNode: (node, children, index) => {
      const { ...restAttributes } = node.attribs;
      const updatedNode = {
        ...node,
        attribs: {
          ...restAttributes
        }
      };
      return processNodeDefinitions.processDefaultNode(
        updatedNode,
        children,
        index
      );
    }
  };
};
