import React from "react";
import styled from "styled-components";

import { IArticleAttachment } from "modules/articles/types/IArticleAttachment";

import { AttachmentListItem } from "./components/AttachmentListItem";

interface IProps {
  attachments: IArticleAttachment[];
  showAttachmentIcon: boolean;
}

const AttachmentsList = ({ attachments, showAttachmentIcon }: IProps) => {
  return (
    <List>
      {attachments.map(attachment => (
        <AttachmentListItem
          key={attachment.id}
          attachment={attachment}
          showAttachmentIcon={showAttachmentIcon}
        />
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export { AttachmentsList };
