import React from "react";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";

import { TableBodyCell } from "components/table";

interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  name: string;
  options: string[];
  handleChange: (name: string, value: string) => void;
}

const MatrixRadioGroupField = ({
  options,
  name,
  handleChange,
  ...inputProps
}: IProps) => {
  const {
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Controller
      name={name}
      render={props => {
        return (
          <>
            {options.map((option, i) => (
              <TableBodyCell key={i}>
                <Input
                  name={name}
                  type="radio"
                  disabled={isSubmitting}
                  onChange={() => handleChange(name, option)}
                  onBlur={props.onBlur}
                  value={props.value}
                  checked={option?.toString() === props.value?.toString()}
                  {...inputProps}
                />
              </TableBodyCell>
            ))}
          </>
        );
      }}
    />
  );
};

const Input = styled.input`
  display: block;
  width: 100%;
  cursor: pointer;
`;

export { MatrixRadioGroupField };
