/**
 * Created by fkurzawa on 21.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Overlay } from "react-overlays";

import { Grid, Row, Column } from "../../TableComponents/MetricGrid";

const OverlayContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 2em;
  left: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    left: auto;
    width: 420px;
  }

  @media screen and (min-width: 992px) {
    width: 500px;
  }
`;

const OverlayContent = styled.div`
  padding: 6px;
  background: ${({ theme }) => theme.lightPrimaryColor};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 1px;
`;

class RelationOverlay extends React.Component {
  render() {
    const { data, show, toggle } = this.props;

    return (
      <OverlayContainer>
        <Overlay show={show} onHide={toggle} container={this} rootClose>
          <OverlayContent>
            <Grid>
              {data.map((dataRow, index) => (
                <Row key={index}>
                  <Column head>{dataRow.fieldName}</Column>
                  <Column dangerouslySetInnerHTML={{ __html: dataRow.value }} />
                </Row>
              ))}
            </Grid>
          </OverlayContent>
        </Overlay>
      </OverlayContainer>
    );
  }
}

RelationOverlay.propTypes = {
  data: PropTypes.array,
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

RelationOverlay.defaultProps = {
  data: []
};

export { RelationOverlay };
