import React from "react";
import styled from "styled-components";

import { Loader } from "components/NewLoader";

const AppLoader = () => {
  return (
    <Overlay>
      <Loader />
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  z-index: 1100;
  overflow: hidden;
  align-items: center;
`;

export { AppLoader };
