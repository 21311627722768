import {IBlob} from "./types/IBlob";

export const initDownloadToLocal = (data: IBlob, filename: string) => {
    const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/zip" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
}