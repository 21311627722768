export const createBreadcrumbItem = (title = "", link = "/") => ({
  title,
  link: link[0] === "/" ? link : `/${link}`
});

export const withRedirect = (item = {}) => ({
  ...item,
  redirect: true
});

export const withAccessKey = (item = {}, accessKey) => {
  if (typeof accessKey === "string" && accessKey.length === 1) {
    return {
      ...item,
      accessKey
    };
  }
  return item;
};
