import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Footer } from "../Footer";
import { AppHeader } from "../Header";
import SkipLinks from "./../skip-links";
import { Toolbar } from "../toolbar";
import { DefaultStyleWrapper } from "./DefaultStyleWrapper";
import { Content } from "./Content";
import { Main } from "./Main";

const LayoutWithoutMenu = ({ children, declarationOfAvailabilityUrl }) => (
  <DefaultStyleWrapper>
    <SkipLinks declarationOfAvailabilityUrl={declarationOfAvailabilityUrl} />
    <Toolbar />
    <AppHeader />
    <Content>
      <StyledMain id="skip-link-main">{children}</StyledMain>
    </Content>

    <Footer />
  </DefaultStyleWrapper>
);

LayoutWithoutMenu.propTypes = {
  children: PropTypes.node,
  declarationOfAvailabilityUrl: PropTypes.string
};

const StyledMain = styled(Main)`
  margin: auto;
`;

export { LayoutWithoutMenu };
