import React, { useEffect, useRef } from "react";
import { ComboboxProps } from "react-widgets/Combobox";
import { Combobox as ReactWidgetsCombobox } from "react-widgets/esm";
import "react-widgets/styles.css";

export interface IComboboxProps extends ComboboxProps<string> {}

const Combobox = <T extends {}>({ ...props }: IComboboxProps) => {
  const ref = useRef(null);
  const suggestionContainer = ref?.current;

  //Dodane ze względu na walidator w3c który pluje się do aria-owns jeśli kontener z podpowiedziami jest niewidoczny
  //inicjalnie jest on zamknięty, można to ustawić propsami, ale z kolei wychodzi kolejny błąd
  //związany z tym, że aria odwołuje się do elementu w tym kontenerze z podpowiedziami, który jest na początku pusty i też błąd
  useEffect(() => {
    // @ts-ignore
    const suggestionInput = suggestionContainer?.getElementsByClassName(
      "rw-combobox-input"
    )[0];
    suggestionInput?.removeAttribute("aria-owns");
  }, [suggestionContainer]);

  return (
    <div ref={ref}>
      <ReactWidgetsCombobox {...props} />
    </div>
  );
};

export { Combobox };
