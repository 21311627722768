import { useMappedState } from "redux-react-hook";
import { createRedirectUrl } from "./createRedirectUrl";

const useGetUrlManager = location => {
  const {
    friendlyUrl,
    langCode,
    startArticleId,
    startUrl,
    urlType,
    startMenuId
  } = useMappedState(mapStateToProps);

  const separator = urlType === "full" ? "/" : ",";
  const isNotDefaultContext = friendlyUrl !== "default";
  const context = `${friendlyUrl === "default" ? "" : friendlyUrl}`;
  const lang = /pl_\w+/.test(langCode)
    ? ""
    : `${isNotDefaultContext ? separator : ""}${
        langCode ? langCode.split("_")[0] : "pl"
      }`;
  const extensionMatch = location.pathname.match(/\.\w+$/);
  const extension = extensionMatch !== null ? extensionMatch[0] : "";
  const hasSeparator = isNotDefaultContext && startUrl !== "";
  const homePath = `/${context}${lang}${extension}`;

  const fromPath = location.pathname === "/" ? location.pathname : homePath;

  return {
    homePath,
    fromPath,
    redirectUrl: createRedirectUrl(
      context,
      hasSeparator,
      separator,
      startArticleId,
      startMenuId,
      startUrl
    )
  };
};

const mapStateToProps = ({ context, language }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  langCode: language.langCode,
  startUrl: context.startUrl,
  urlType: context.urlType,
  startArticleId: context.startArticleId,
  startMenuId: context.startMenuId
});

export { useGetUrlManager };
