/**
 * Created by fkurzawa on 12.02.18.
 */
import React from "react";
import { useMappedState } from "redux-react-hook";
import PropTypes from "prop-types";
import styled from "styled-components";

import { withTranslate } from "../../../../translate";

const Separator = ({ text, onClick }) => {
  const { isBifTheme } = useMappedState(mapStateToProps);
  return (
    <SeparatorListItem isBifTheme={isBifTheme} onClick={onClick}>
      <Text>{text}</Text>
    </SeparatorListItem>
  );
};

Separator.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isBifTheme: PropTypes.bool
};

const mapStateToProps = ({ context }) => ({
  isBifTheme: context.siteTheme === "bif"
});

const SeparatorListItem = styled.li`
  background: ${({ theme }) =>
    theme.menuSeparatorBackground || theme.defaultBackground};
  position: relative;
  z-index: 2;
  padding: 15px;
  min-height: 60px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
  ${({ isBifTheme }) =>
    isBifTheme &&
    `box-shadow: none;
    border: solid 1px #ccc;
    margin-left: -100%;
    padding-left: 100%;`};
  cursor: ${({ onClick }) =>
    typeof onClick === "function" ? "pointer" : "default"};
`;

const Text = withTranslate(styled.span`
  color: ${({ theme }) => theme.menuSeparatorColor || theme.text.primaryColor};
  word-wrap: break-word;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
`);

export { Separator };
