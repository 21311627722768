import React from "react";
import { Collapse } from "react-collapse";
import styled from "styled-components";

import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {
  isOpen: boolean;
  id: string;
}

const CollapseContent = ({ id, isOpen, children }: IProps) => {
  return (
    <CollapseContainer>
      <Collapse aria-hidden={!isOpen} id={`${id}-content`} isOpened={isOpen}>
        {children}
      </Collapse>
    </CollapseContainer>
  );
};

const CollapseContainer = styled.div`
  .ReactCollapse--collapse {
    @media print {
      display: block !important;
      height: auto !important;
    }
  }
`;

export { CollapseContent };
