import React from "react";
import PropTypes from "prop-types";
import { useMappedState } from "redux-react-hook";

import { isSeparator } from "utils/menu";

import { Separator } from "./Separator";
import { MenuItem } from "./MenuItem";
import { SeparatorH2 } from "./SeparatorH2";

const MainMenuItems = props => {
  const { separatorName } = useMappedState(mapStateToProps);

  return props.items.map((item, index) => {
    if (isSeparator(item) && index === 0) {
      return <SeparatorH2 key={index} text={item.name} />;
    }

    if (isSeparator(item) && item.name === "Inne") {
      return (
        <Separator
          key={index}
          text={separatorName ? separatorName : item.name}
        />
      );
    }

    if (isSeparator(item)) {
      return <Separator key={index} text={item.name} />;
    }

    return <MenuItem key={index} item={item} />;
  });
};

MainMenuItems.propTypes = {
  items: PropTypes.array
};

MainMenuItems.defaultProps = {
  items: []
};

const mapStateToProps = ({ context }) => ({
  separatorName: context.separatorName
});

export { MainMenuItems };
