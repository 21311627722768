import React, { ReactElement, ReactNode } from "react";
import RCTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { TooltipProps } from "rc-tooltip/es/Tooltip";

export interface ITooltipProps extends Omit<TooltipProps, "overlay"> {
  children: ReactElement;
  id: string;
  label?: ReactNode;
}

const Tooltip = ({ children, label, id, ...restProps }: ITooltipProps) => {
  if (!label) {
    return children;
  }

  return (
    <RCTooltip
      placement="bottom"
      trigger={["hover"]}
      overlay={label}
      {...restProps}
    >
      {children}
    </RCTooltip>
  );
};

export { Tooltip };
