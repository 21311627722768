import axios from "axios";
import ApiConfig from "../../../api/api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}contexts`;

export const getVisitorsCounter = (
  friendlyUrl: string
): Promise<number> => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/visit-counter`)
    .then(response => response.data);
};


export const getVisitorsCounterKey = (friendlyUrl: string) => {
  return [`visitors-counter-${friendlyUrl}`]
}