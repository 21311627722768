import useRouter from "use-react-router";
import { useMappedState } from "redux-react-hook";

export const useGetMenuId = () => {
  const { startMenuId } = useMappedState(mapStateToProps);
  const { match } = useRouter<{
    method: string;
    id: string;
  }>();

  return match.params.id || startMenuId;
};

const mapStateToProps = ({ context }: any) => ({
  startMenuId: context.startMenuId
});
