/**
 * Created by fkurzawa on 25.01.18.
 */
import axios from "axios";
import ApiConfig from "./api.config";
import { IMenuElement } from "../types/IMenuElement";

const apiUrl = `${ApiConfig.getEntryPoint()}menu`;

export const getMenu = (menuId: number): Promise<IMenuElement> =>
  axios.get(`${apiUrl}/${menuId}`).then(response => response.data);

export const getSubMenu = (
  menuId: number,
  targetId: number
): Promise<IMenuElement> =>
  axios
    .get(`${apiUrl}/${menuId}/submenu${targetId ? `/${targetId}` : ""}`)
    .then(response => response.data);

export const getRssUrl = (friendlyUrl = "default") =>
  `${apiUrl}/${friendlyUrl}/rss`;

export const getXmlUrl = (friendlyUrl = "default") =>
  `${apiUrl}/${friendlyUrl}/xml`;
