import React from "react";

import { useFontSize } from "utils/useFontSize";
import { FONT_SIZES } from "constants/font-sizes";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const MediumLettersButton = () => {
  const { fontSize, setFontSize } = useFontSize();
  const label = "Duża wielkość liter";

  return (
    <Tooltip label={label} id="medium-letters-button">
      <DesktopAccessibilityButton
        aria-pressed={fontSize === FONT_SIZES.MEDIUM}
        id="medium-letters-button"
        aria-label={label}
        onClick={() => setFontSize(FONT_SIZES.MEDIUM)}
      >
        A+
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

export { MediumLettersButton };
