import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import Container from "../container";
import { Emblem } from "./components/Emblem";
import { HeaderEditable } from "./components/HeaderEditable";
import { MainLogo } from "./components/MainLogo";

const AppHeader = () => {
  const { isBifTheme, reverseOrder, showEmblem } = useMappedState(
    mapStateToProps
  );
  return (
    <HeaderContainer isBifTheme={isBifTheme}>
      <Content id="header" reverseOrder={reverseOrder} isBifTheme={isBifTheme}>
        <MainLogoContainer>
          <div style={{ width: "250px" }}>
            <MainLogo />
          </div>
        </MainLogoContainer>
        <HeaderEditableContainer id="slogan" isBifTheme={isBifTheme}>
          <HeaderEditable />
        </HeaderEditableContainer>
        {showEmblem && !isBifTheme && <Emblem />}
      </Content>
    </HeaderContainer>
  );
};

const MainLogoContainer = styled.div`
  display: flex;
  text-align: start;
  @media (max-width: 967px) {
    text-align: center;
  }
`;

const Content = styled(Container.withComponent("header"))`
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ reverseOrder }) =>
    reverseOrder ? "column-reverse" : "column"};
  justify-content: space-between;
  background: ${({ theme }) => theme.headerBackground};
  padding: 0.75rem 1rem 0 1rem;
  ${({ isBifTheme, theme }) =>
    !isBifTheme &&
    `
    padding: 0.75rem 1rem;
    border-bottom: 3px solid ${
      theme.layoutDividerColor ? theme.layoutDividerColor : theme.secondaryColor
    };
    margin-bottom: 1px;
`};

  @media print {
    display: none;
  }

  @media (max-width: 967px) {
    align-items: center;
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 968px) {
    flex-direction: ${({ reverseOrder }) =>
      reverseOrder ? "row-reverse" : "row"};
  }
`;

const HeaderContainer = styled.div`
  ${({ isBifTheme, theme }) =>
    isBifTheme &&
    `
    border-bottom: 3px solid ${
      theme.layoutDividerColor ? theme.layoutDividerColor : theme.secondaryColor
    };
    margin-bottom: 1px;
`};
`;

const HeaderEditableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

const mapStateToProps = ({ context }: any) => ({
  reverseOrder: context.siteTheme === "szamotuly",
  showEmblem: context.logoFileId !== null,
  isBifTheme: context.siteTheme === "bif"
});

export { AppHeader };
