import axios from "axios";

export const getFile = (fileUrl: string) => {
  return axios.get<any>(fileUrl, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
