import React from "react";
import styled from "styled-components";

import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {}

const FormColumn = ({ children }: IProps) => {
  return <StyledFormColumn>{children}</StyledFormColumn>;
};

const StyledFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export { FormColumn };
