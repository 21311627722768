import styled from "styled-components";
import Container from "../container";

export const Content = styled(Container)`
  background: ${({ theme }) => theme.contentBackground};
  display: flex;
  flex-direction: column;

  @media print {
    max-width: 100%;
    margin-left: 30pt;
    margin-right: 30pt;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
