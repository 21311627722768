import * as yup from "yup";

import { checkboxRequiredValidationSchema } from "utils/yup/checkboxRequiredValidationSchema";
import { useRecaptchaValidationSchema } from "utils/yup/useRecaptchaValidationSchema";
import { validationMessages } from "utils/yup/messages";

import { ICommentForm } from "../types/ICommentForm";

export const useCommentFormValidationSchema = () => {
  return yup.object<ICommentForm>().shape({
    comment: yup.string().required(validationMessages.requiredField),
    agreement: checkboxRequiredValidationSchema,
    "recaptcha-response": useRecaptchaValidationSchema()
  });
}
