import * as yup from "yup";
import { array } from "yup";
import moment from "moment";

import { IDynamicFieldSchema } from "utils/yup/IDynamicFieldSchema";
import { validationMessages } from "utils/yup/messages";
import { nullableFloatSchema } from "utils/yup/nullableFloatSchema";

import { FormFieldType, IFormField } from "components/HookForm";

export const advancedSearchFormSchemaStrategy = (
  formField: IFormField
): IDynamicFieldSchema => {
  switch (formField.type) {
    case FormFieldType.TEXT_FIELD:
    case FormFieldType.TEXT_AREA:
      const textFieldName = formField.name;
      return {
        [textFieldName]: yup
          .string()
          .test(textFieldName, validationMessages.minThreeSymbols, function(
            textField
          ) {
            if (textField === "") {
              return true;
            }
            const textLength = textField.replace(/\s/g, "").length;
            return textLength > 2;
          })
          .max(200, validationMessages.maxTwoHundredSymbols)
          .nullable()
      };
    case FormFieldType.CHECKBOX_GROUP:
      return { [formField.name]: array(yup.number()).nullable() };
    case FormFieldType.DATE_RANGE:
      const dateFromName = `${formField.name}-from`;
      const dateToName = `${formField.name}-to`;
      return {
        [dateFromName]: yup
          .string()
          .test(
            dateFromName,
            'Data powinna być wcześniejsza od daty "Do"',
            function(dateFrom) {
              const dateTo = this.parent[dateToName];
              if (!dateTo || !dateFrom || dateTo === dateFrom) {
                return true;
              }
              return moment(dateFrom).isBefore(dateTo);
            }
          ),
        [dateToName]: yup
          .string()
          .test(
            dateToName,
            'Data powinna być późniejsza od daty "Od"',
            function(dateTo) {
              const dateFrom = this.parent[dateFromName];
              if (!dateFrom || !dateTo || dateTo === dateFrom) {
                return true;
              }
              return moment(dateTo).isAfter(dateFrom);
            }
          )
      };
    case FormFieldType.NUMBER:
      return { [formField.name]: nullableFloatSchema };
    case FormFieldType.NUMBER_RANGE:
      const numberFromName = `${formField.name}-from`;
      const numberToName = `${formField.name}-to`;
      return {
        [numberFromName]: nullableFloatSchema.test(
          numberFromName,
          'Wartość powinna być mniejsza od wartości "Do"',
          function(numberFrom) {
            const numberTo = this.parent[numberToName];
            if (!numberTo || !numberFrom || numberTo === numberFrom) {
              return true;
            }
            return Number(numberFrom) < Number(numberTo);
          }
        ),
        [numberToName]: nullableFloatSchema.test(
          numberToName,
          'Wartość powinna być większa od wartości "Od"',
          function(numberTo) {
            const numberFrom = this.parent[numberFromName];
            if (!numberFrom || !numberTo || numberTo === numberFrom) {
              return true;
            }
            return Number(numberTo) > Number(numberFrom);
          }
        )
      };
    default:
      return { [formField.name]: yup.string().nullable() };
  }
};
