import { connect } from "react-redux";

const Translate = ({ children, translations }) => {
  const key = typeof children === "string" ? children.toLowerCase() : "";
  return translations[key] || children;
};

const mapStateToProps = ({ language }) => ({
  translations: language.translations
});

export default connect(mapStateToProps)(Translate);
