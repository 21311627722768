import axios from "axios";
import ApiConfig from "./api.config";
import { ISuggestion } from "../types/ISuggestion";

export const getSearchSuggestion = (query: string): Promise<ISuggestion> =>
  axios
    .get(`${ApiConfig.getEntryPoint()}search-suggestion`, {
      params: {
        query
      }
    })
    .then(response => response.data);
