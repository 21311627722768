import React, { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useMappedState } from "redux-react-hook";

import { FieldPrototype } from "../FieldPrototype";

import { changeRecaptchaCurrentAdditionalAttributes } from "./changeRecaptchaCurrentAdditionalAttributes";

interface IProps {
  name: string;
  id: string;
}

const RecaptchaField = ({ name, id }: IProps) => {
  const { isContrast } = useMappedState(mapStateToProps);

  const { setValue, getValues } = useFormContext();
  const { siteKey } = useMappedState(mapStateToProps);
  const recaptchaValue = getValues(name);

  const recaptchaRef = useRef();
  const recaptchaCurrent = recaptchaRef?.current;

  const handleChange = (token: string | null) => {
    setValue(name, token, { shouldValidate: true });
  };

  useEffect(() => {
    if (!getValues(name)) {
      // @ts-ignore
      recaptchaRef?.current?.reset();
    }
  }, [getValues, name, recaptchaValue]);

  //poprawka pozwalająca spełnić wymagania walidatora w3c
  useEffect(() => {
    changeRecaptchaCurrentAdditionalAttributes(recaptchaCurrent);
  }, [recaptchaCurrent]);

  return (
    <FieldPrototype name={name}>
      {() => (
        <Controller
          name={name}
          render={props => (
            <ReCAPTCHA
              theme={isContrast ? "dark" : "light"}
              id={id}
              // @ts-ignore
              ref={recaptchaRef}
              // sitekey={"6LdxLNQZAAAAAKWdQH5ColDKvHutAtt0iBjlRayH"}
              sitekey={siteKey}
              onChange={handleChange}
            />
          )}
        />
      )}
    </FieldPrototype>
  );
};

const mapStateToProps = ({ context, accessibility }: any) => ({
  siteKey: context.googleRecaptchaId,
  isContrast: accessibility.isContrast
});

export { RecaptchaField };
