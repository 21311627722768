import React from "react";
import styled from "styled-components";

import { getContactForm } from "api/contactForms";

import { SpinnerButton } from "components/HookForm";

import { FormGroup } from "components/HookForm";

import Modal from "./../../../../../modal/Modal";
import {Button} from "../../../../../buttons";
import { ContactForm } from "../../../../../../containers/contact-form/components/ContactForm";
import { RemoteDataSuspense } from "../../../../../RemoteData/RemoteDataSuspense";
import { IContactForm } from "../../../../../../types/IContactForm";
import { contentKeys } from "../../../keys";
import { Loader } from "../../../../../NewLoader";

interface IProps {
  show: boolean;
  onClose: () => void;
  contactFormId: number;
}

const ContactFormModal = ({ show, onClose, contactFormId }: IProps) => {
  return (
    <Modal height={"auto"} onHide={onClose} show={show} width={"60%"}>
      <Content>
        <RemoteDataSuspense<IContactForm>
          fetcher={() => getContactForm(contactFormId)}
          queryKey={[contentKeys.contactFormModal, contactFormId]}
          fallback={
            <FallBackContainer>
              <Loader size={40} />
            </FallBackContainer>
          }
        >
          {contactForm => (
            <ContactForm contactForm={contactForm} formId={contactFormId}>
              <ButtonsFormGroup>
                <SpinnerButton label="Wyślij" id="contact-form-submit-button" />
                <Button
                  type={"button"}
                  data-color={"secondary"}
                  onClick={onClose}
                  id="close-contact-form-button"
                >
                  Zamknij
                </Button>
              </ButtonsFormGroup>
            </ContactForm>
          )}
        </RemoteDataSuspense>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  min-height: 300px;
  padding: 1rem;
  @media screen and (min-width: 768px) {
    min-width: 600px;
  }
`;

const FallBackContainer = styled(Content)`
  text-align: center;
  display: flex;
`;

const ButtonsFormGroup = styled(FormGroup)`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export { ContactFormModal };
