/**
 * Created by fkurzawa on 22.03.18.
 */
import React, { useState } from "react";
import styled from "styled-components";
import { Overlay } from "react-overlays";

import { Button } from "components/buttons";

interface IProps {
  tip: string;
}

const TipButton = ({ tip }: IProps) => {
  const [showTip, toggleTip] = useState(false);

  return (
    <TipContainer>
      <Button
        id="article-question-circle-button"
        type="button"
        title={tip}
        aria-label={tip}
        size="small"
        data-flat="true"
        onClick={() => toggleTip(!showTip)}
      >
        <span className="fa fa-question-circle" aria-hidden="true" />
      </Button>
      {/*// @ts-ignore*/}
      <Overlay
        show={showTip}
        onHide={() => toggleTip(!showTip)}
        // @ts-ignore
        container={this}
        rootClose
      >
        {/*@ts-ignore*/}
        <OverlayContent>
          <Tip>{tip}</Tip>
        </OverlayContent>
      </Overlay>
    </TipContainer>
  );
};

const TipContainer = styled.div`
  position: relative;
`;

const OverlayContent = styled.div`
  z-index: 1;
  position: absolute;
  top: auto !important;
  left: auto !important;
  margin-top: 3px;
  right: 0;

  background: ${({ theme }) => theme.lightPrimaryColor};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 1px;

  width: calc(100vw - 30px);

  @media screen and (min-width: 768px) {
    width: 300px;
  }
`;

const Tip = styled.div`
  color: ${({ theme }) => theme.text.secondaryColor};
  font-size: 0.75em;
  padding: 0.75rem;
`;

export { TipButton };
