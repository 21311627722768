import React from "react";

import { useAccessibilityClass } from "utils";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const ParagraphSpacingButton = () => {
  const {
    isAccessibilityClassActive,
    toggleAccessibilityClass
  } = useAccessibilityClass("paragraphsSpacing", "paragraphs-spacing");
  const label = "Odstęp między akapitami";

  return (
    <Tooltip label={label} id="paragraph-spacing-button">
      <DesktopAccessibilityButton
        aria-pressed={isAccessibilityClassActive}
        id="paragraph-spacing-button"
        aria-label={label}
        onClick={toggleAccessibilityClass}
      >
        <span aria-hidden={true} className="fa fa-arrows-v" /> <span className="fa fa-paragraph" />
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

export { ParagraphSpacingButton };
