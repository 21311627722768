import { uniq } from "lodash";
import { getCompletedSurveys } from "./getCompletedSurveys";

export const addCompletedSurveyIdToLocalStorage = (surveyId: number) => {
  const completedSurveys = getCompletedSurveys();
  const updatedCompletedSurveys = uniq([...completedSurveys, surveyId]);
  window.localStorage.setItem(
    "completedSurveys",
    JSON.stringify(updatedCompletedSurveys)
  );
};
