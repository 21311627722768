import React from "react";

import { TextField } from "../TextField";
import { SelectField } from "../SelectField";
import { CheckboxGroupField } from "../CheckboxGroupField";
import { DateRangeField } from "../DateRangeField";
import { FormFieldType, IFormField, TextFieldType } from "../types";
import { TextAreaField } from "../TextAreaField";
import { NumberRangeField } from "../NumberRangeField";

interface IProps {
  field: IFormField;
}

const FormFieldsGenerator = ({ field }: IProps) => {
  switch (field.type) {
    case FormFieldType.TEXT_FIELD:
      return (
        <TextField
          name={field.name}
          id={field.name}
          label={field.label}
          isRequired={false}
        />
      );
    case FormFieldType.MAIL_FIELD:
      return (
        <TextField
          type={TextFieldType.EMAIL}
          name={field.name}
          id={field.name}
          label={field.name}
          isRequired={false}
        />
      );
    case FormFieldType.TEXT_AREA:
      return (
        <TextAreaField
          name={field.name}
          id={field.name}
          label={field.label}
          isRequired={false}
        />
      );
    case FormFieldType.SELECT:
      return (
        <SelectField
          name={field.name}
          id={field.name}
          options={field.options || []}
          label={field.label}
          isRequired={false}
        />
      );
    case FormFieldType.CHECKBOX_GROUP:
      return (
        <CheckboxGroupField
          name={field.name}
          id={field.name}
          options={field.options || []}
          label={field.label}
          isRequired={false}
        />
      );
    case FormFieldType.DATE_RANGE:
      return (
        <DateRangeField
          name={field.name}
          label={field.label}
          isRequired={false}
        />
      );
    case FormFieldType.NUMBER_RANGE:
      return (
        <NumberRangeField
          name={field.name}
          label={field.label}
          isRequired={false}
        />
      );
    case FormFieldType.NUMBER:
      return (
        <TextField
          name={field.name}
          id={field.name}
          label={field.label}
          type={TextFieldType.NUMBER}
          isRequired={false}
        />
      );
    default:
      throw new Error("Unknown form field type");
  }
};

export { FormFieldsGenerator };
