/**
 * Created by fkurzawa on 05.07.18.
 */
import { createActions, handleActions } from "redux-actions";
import uuid from "uuid/v4";
import { addLoader, removeLoader } from "./../../redux/modules/loader";

const { signInSuccess, signOutSuccess, updateUserSuccess } = createActions(
  "SIGN_IN_SUCCESS",
  "SIGN_OUT_SUCCESS",
  "UPDATE_USER_SUCCESS"
);

const reducer = handleActions(
  {
    [signInSuccess]: (state, { payload: user }) => ({
      ...state,
      isAuthenticated: true,
      user
    }),
    [signOutSuccess]: state => ({
      ...state,
      isAuthenticated: false,
      user: null
    }),
    [updateUserSuccess]: (state, { payload: user }) => ({
      ...state,
      user: {
        ...state.user,
        ...user
      }
    })
  },
  {
    isAuthenticated: false,
    user: null
  }
);

const deleteAccount = user => async dispatch => {
  const loaderId = uuid();
  dispatch(addLoader(loaderId));
  //await deleteAccount();
  dispatch(signOutSuccess());
  dispatch(removeLoader(loaderId));
};

// const signIn = (login, password) => async dispatch => {
//   const loaderId = uuid();
//   dispatch(addLoader(loaderId));
//   //const formData = new FormData();
//   //const user = await signIn(formData);
//   const user = { ...mockUser };
//   dispatch(signInSuccess(user));
//   dispatch(removeLoader(loaderId));
// };

const signOut = () => async dispatch => {
  const loaderId = uuid();
  dispatch(addLoader(loaderId));
  //await signOut();
  dispatch(signOutSuccess());
  dispatch(removeLoader(loaderId));
};

const updateUser = user => async dispatch => {
  const loaderId = uuid();
  dispatch(addLoader(loaderId));
  //const formData = new FormData();
  //await updateUser(formData);
  dispatch(updateUserSuccess(user));
  dispatch(removeLoader(loaderId));
};

export {
  deleteAccount,
  signInSuccess,
  signOut,
  signOutSuccess,
  updateUser,
  updateUserSuccess
};
export default reducer;
