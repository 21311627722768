import * as yup from "yup";
import { validationMessages } from "./messages";
import { useMappedState } from "redux-react-hook";

export const useRecaptchaValidationSchema = () => {
  const { isRecaptchaDisabled } = useMappedState(mapStateToProps);

  if (isRecaptchaDisabled) {
    return yup.string().nullable();
  }

  return yup
    .string()
    .required(validationMessages.requiredRecaptchaField)
    .nullable();
};

const mapStateToProps = ({ context }: any) => ({
  isRecaptchaDisabled: context.isRecaptchaDisabled
});
