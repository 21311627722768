import React from "react";
import sanitizeHtml from "sanitize-html";

interface IProps {
  html: string;
  as?: "div" | "span";
}

const DangerousInnerHtml = ({ html, as = "div" }: IProps) => {
  return React.createElement(as, {
    dangerouslySetInnerHTML: { __html: sanitizeHtml(html) }
  });
};

export { DangerousInnerHtml };
