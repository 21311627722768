/**
 * Created by fkurzawa on 05.07.18.
 */
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 0.75rem 1rem;
`;

export default ModalContent;
