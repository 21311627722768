/**
 * Created by fkurzawa on 21.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import { compose, withStateHandlers } from "recompose";

import { RelationOverlay } from "./RelationOverlay";

const NonArticleRelation = ({ relation, showOverlay, toggleOverlay }) => {
  return (
    <div>
      <button
        type="button"
        onClick={toggleOverlay}
        id="non-article-relation-button"
      >
        {relation.title}
      </button>
      <RelationOverlay
        data={relation.additionalData}
        show={showOverlay}
        toggle={toggleOverlay}
      />
    </div>
  );
};

NonArticleRelation.propTypes = {
  relation: PropTypes.object.isRequired
};

const composed = compose(
  withStateHandlers(
    { showOverlay: false },
    {
      toggleOverlay: ({ showOverlay }) => () => ({ showOverlay: !showOverlay })
    }
  )
);

export default composed(NonArticleRelation);
