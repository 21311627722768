export const allowedAttributes = {
  "*": [
    "id",
    "class",
    "style",
    "data-module",
    "data-url",
    "width",
    "height",
    "src",
    "frameborder",
    "name",
    "title",
    "border",
    "data-contact-form-id",
    "data-forward",
    "data-pdf",
    "data-xml",
    "data-print",
    "data-contact-form-id",
    "align",
    "bgcolor"
  ],
  a: ["href", "name", "target"],
  ol: ["reversed", "start", "type"],
  li: ["value"],
  body: ["onload"],
  form: ["method", "action"],
  input: ["type", "name", "value"],
  doctype: ["html"],
  button: ["style", "class", "type"],
  source: ["src", "type"],
  video: ["width", "height", "controls", "src"],
  div: ["class", "style", "data-module", "data-url"],
  iframe: ["width", "height", "style", "src", "frameborder", "webkitallowfullscreen", "mozallowfullscreen","allowfullscreen", "scrolling"],
  table: ["width", "height", "style", "bgcolor", "align", "border", "cellspacing", "cellpadding"],
  td: ["width", "valign", "colspan", "rowspan"],
  th: ["width", "valign", "colspan", "rowspan"],
  img: ["alt"],
  audio: ["controls", "preload", "loop", "autoplay", "muted", "src"]
};
