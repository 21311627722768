import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, pure, withHandlers, withStateHandlers } from "recompose";

import { Button } from "components/buttons";

import { ForwardModal, ContactFormModal } from "./components";
import {
  getArticlePdfUrl,
  getArticleXmlUrl
} from "../../../../../api/articles";
import {
  getArticleVersionPdfUrl,
  getArticleVersionXmlUrl
} from "../../../../../api/articleVersions";

const ActionButtons = ({
  isHistorical,
  articleId,
  contactFormId,
  contactFormOpened,
  closeContactForm,
  closeForward,
  downloadPdf,
  downloadXml,
  forwardOpened,
  isPreview,
  showContactForm,
  showForward,
  showDownloadPdf,
  showDownloadXml,
  showPrint,
  openContactForm,
  openForward,
  print
}) => {
  return (
    <>
      {showForward && !isPreview && (
        <ForwardModal
          articleId={articleId}
          show={forwardOpened}
          onClose={closeForward}
          isHistoricalArticle={isHistorical}
        />
      )}
      {showContactForm && !isPreview && (
        <ContactFormModal
          contactFormId={contactFormId}
          show={contactFormOpened}
          onClose={closeContactForm}
        />
      )}
      <Container>
        {showContactForm && (
          <li>
            <Button
              id="article-contact-form-btn"
              aria-label={"Formularz kontaktowy"}
              disabled={isPreview}
              type={"button"}
              title={"Formularz kontaktowy"}
              size={"small"}
              onClick={openContactForm}
            >
              <span aria-hidden={true} className={"fa fa-envelope-o"} />
            </Button>
          </li>
        )}
        {showForward && (
          <li>
            <Button
              id="article-send-forward-btn"
              aria-label="Prześlij dalej"
              title="Prześlij dalej"
              disabled={isPreview}
              type={"button"}
              size={"small"}
              onClick={openForward}
            >
              <span aria-hidden={true} className={"fa fa-share"} />
            </Button>
          </li>
        )}
        {showDownloadPdf && (
          <li>
            <Button
              id="article-pdf-download-btn"
              aria-label="Wersja PDF"
              title="Wersja PDF"
              disabled={isPreview}
              type={"button"}
              size={"small"}
              onClick={downloadPdf}
            >
              <span aria-hidden={true} className={"fa fa-file-pdf-o"} />
            </Button>
          </li>
        )}
        {showDownloadXml && (
          <li>
            <Button
              id="article-xml-download-btn"
              aria-label={"Wersja XML"}
              disabled={isPreview}
              type={"button"}
              title={"Wersja XML"}
              size={"small"}
              onClick={downloadXml}
            >
              <span aria-hidden={true} className={"fa fa-code"} />
            </Button>
          </li>
        )}
        {showPrint && (
          <li>
            <Button
              id="article-print-btn"
              aria-label={"Drukuj"}
              disabled={isPreview}
              type={"button"}
              title={"Drukuj"}
              size={"small"}
              onClick={print}
            >
              <span aria-hidden={true} className={"fa fa-print"} />
            </Button>
          </li>
        )}
      </Container>
    </>
  );
};

ActionButtons.propTypes = {
  articleId: PropTypes.string.isRequired,
  closeContactForm: PropTypes.func.isRequired,
  closeForward: PropTypes.func.isRequired,
  contactFormId: PropTypes.number,
  contactFormOpened: PropTypes.bool,
  downloadPdf: PropTypes.func.isRequired,
  downloadXml: PropTypes.func.isRequired,
  forwardOpened: PropTypes.bool,
  isPreview: PropTypes.bool,
  openContactForm: PropTypes.func.isRequired,
  openForward: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  showContactForm: PropTypes.bool,
  showForward: PropTypes.bool,
  showDownloadPdf: PropTypes.bool,
  showDownloadXml: PropTypes.bool,
  showPrint: PropTypes.bool
};

const Container = styled.ul`
  margin: 5px 0 0 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  @media print {
    display: none;
  }
`;

const enhance = compose(
  pure,
  withHandlers({
    downloadPdf: ({ articleId, isHistorical }) => () => {
      window.location.href = isHistorical
        ? getArticleVersionPdfUrl(articleId)
        : getArticlePdfUrl(articleId);
    },
    downloadXml: ({ articleId, isHistorical }) => () => {
      window.location.href = isHistorical
        ? getArticleVersionXmlUrl(articleId)
        : getArticleXmlUrl(articleId);
    },
    print: () => () => {
      window.print();
    }
  }),
  withStateHandlers(
    { forwardOpened: false },
    {
      openForward: () => () => ({ forwardOpened: true }),
      closeForward: () => () => ({ forwardOpened: false })
    }
  ),
  withStateHandlers(
    { contactFormOpened: false },
    {
      openContactForm: () => () => ({ contactFormOpened: true }),
      closeContactForm: () => () => ({ contactFormOpened: false })
    }
  )
);

export { ActionButtons, enhance };
export default enhance(ActionButtons);
