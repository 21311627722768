/**
 * Created by fkurzawa on 06.06.18.
 */
import { createActions, handleActions } from "redux-actions";

const { setDocumentTitle } = createActions("SET_DOCUMENT_TITLE");

const reducer = handleActions(
  {
    [setDocumentTitle]: (state, { payload }) => payload
  },
  null
);

export { setDocumentTitle };
export default reducer;
