/**
 * Created by fkurzawa on 21.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RelationsNode from "./components/RelationsNode";
import { compose, withProps } from "recompose";
import { groupBy } from "lodash";

const Relations = ({ relations }) => {
  return (
    <React.Fragment>
      {relations.map(relation => (
        <RelationsNodeContainer key={relation.name}>
          <RelationsNode name={relation.name} relations={relation.relations} />
        </RelationsNodeContainer>
      ))}
    </React.Fragment>
  );
};

Relations.propTypes = {
  relations: PropTypes.array
};

Relations.defaultProps = {
  relations: []
};

const RelationsNodeContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const composed = compose(
  withProps(({ relations }) => ({
    relations: (() => {
      const groupedRelations = groupBy(relations, "relationType");
      return Object.keys(groupedRelations).map(key => ({
        name: key,
        relations: groupedRelations[key]
      }));
    })()
  }))
);

export default composed(Relations);
