import { ISurveyStatisticsQuestionAnswer } from "../types/ISurveyStatisticsQuestionAnswer";
import {ISurveyQuestion} from "../types/ISurveyQuestion";

const getMatrixDataTitlesFromAnswer = (
  answersCounter: ISurveyStatisticsQuestionAnswer[]
) => {
  const firstAnswer = answersCounter[0] || [];
  return Array.isArray(firstAnswer.value)
    ? firstAnswer.value.map(record => record.answer)
    : [];
};

export const getMatrixDataValuesFromAnswer = (
  answer: ISurveyQuestion
) => {
  const { answersCounter = [] } = answer;
  const titles = getMatrixDataTitlesFromAnswer(answersCounter);
  return titles.map((title: string, index: number) => [
    title,
    ...answersCounter.map(({ value }: any) => value[index].value)
  ]);
};
