/**
 * Created by fkurzawa on 26.01.18.
 */
import React from "react";
import Media from "react-media";

interface IProps {
  footerHtml: string;
  footerHtmlMobile: string;
}

const FooterEditable = ({ footerHtml, footerHtmlMobile }: IProps) => (
  <Media query="(min-width: 992px)">
    {matches =>
      matches ? (
        <div dangerouslySetInnerHTML={{ __html: footerHtml }} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: footerHtmlMobile }} />
      )
    }
  </Media>
);

export { FooterEditable };
