import React, { useState, useEffect } from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";

import { getLatestArticles } from "api/articles";

import { Separator } from "./Separator";
import { ListItem } from "./ListItem";
import { Redirection } from "./Redirection";

const LatestArticles = () => {
  const { isBifTheme, friendlyUrl } = useMappedState(mapStateToProps);
  const [latestArticles, setLatestArticles] = useState([]);

  useEffect(() => {
    getLatestArticles(friendlyUrl).then(result => {
      setLatestArticles(result);
    });
  }, [friendlyUrl]);

  return (
    <>
      <Separator text="Aktualności" />
      {isBifTheme
        ? latestArticles.map((article, index) => (
            <BifListItem key={index}>
              <Redirection item={{ ...article, name: article.title }} />
            </BifListItem>
          ))
        : latestArticles.map((article, index) => (
            <ListItem key={index}>
              <Redirection item={{ ...article, name: article.title }} />
            </ListItem>
          ))}
    </>
  );
};

const mapStateToProps = ({ context }) => ({
  isBifTheme: context.siteTheme === "bif",
  friendlyUrl: context.contextFriendlyUrl
});

const BifListItem = styled(ListItem)`
  margin-left: -100%;
  padding-left: 100%;
  border-bottom: none;
  a {
    border-bottom: none;
    border-left: 6px solid transparent;
    &.active {
      border-left-color: ${({ theme }) => theme.secondaryColor};
      border-right-color: transparent;
    }
  }
`;

export { LatestArticles };
