import React from "react";

import { FIELD_TYPES } from "modules/survey/types/FieldTypes";
import { ISurveyQuestion } from "modules/survey/types/ISurveyQuestion";

import {
  DateField,
  TextAreaField,
  TextField,
  TextFieldType
} from "components/HookForm";

import { RankingField } from "./fields/RankingField";
import { SingleChoiceField } from "./fields/SingleChoiceField";
import { MultipleChoiceField } from "./fields/MultipleChoiceField";
import { MatrixField } from "./fields/MatrixField";

interface IProps {
  questionField: ISurveyQuestion;
}

const SurveyQuestionField = ({ questionField }: IProps) => {
  const fieldName = `form_${questionField.id.toString()}`;

  switch (questionField.type) {
    case FIELD_TYPES.OPEN:
      return (
        <TextAreaField
          name={fieldName}
          id={fieldName}
          label={questionField.name}
          rows={4}
          isRequired={questionField.isRequired === 1}
        />
      );
    case FIELD_TYPES.DATE:
      return (
        <DateField
          name={fieldName}
          id={fieldName}
          label={questionField.name}
          isRequired={questionField.isRequired === 1}
        />
      );
    case FIELD_TYPES.NUMBER:
      return (
        <TextField
          type={TextFieldType.NUMBER}
          name={fieldName}
          id={fieldName}
          label={questionField.name}
          isRequired={questionField.isRequired === 1}
        />
      );
    case FIELD_TYPES.LIST_ONE_CLOSED:
    case FIELD_TYPES.LIST_ONE_OPEN:
    case FIELD_TYPES.LIST_ONE_COMMENT:
      return (
        <SingleChoiceField
          fieldName={fieldName}
          questionField={questionField}
        />
      );
    case FIELD_TYPES.LIST_MANY_CLOSED:
    case FIELD_TYPES.LIST_MANY_OPEN:
    case FIELD_TYPES.LIST_MANY_COMMENT:
      return (
        <MultipleChoiceField name={fieldName} questionField={questionField} />
      );

    case FIELD_TYPES.LIST_RANKING:
      return <RankingField name={fieldName} questionField={questionField} />;
    case FIELD_TYPES.LIST_MATRIX:
      return <MatrixField name={fieldName} questionField={questionField} />;
    default:
      return null;
  }
};

export { SurveyQuestionField };
