import { find } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, withProps, withHandlers, pure } from "recompose";

const Tabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <NavContainer>
      <TabsList>
        {tabs.map((tab, index) => (
          <TabsListItem key={index}>
            <TabButton
              aria-current={tab === activeTab}
              value={tab}
              onClick={onTabClick}
            >
              {tab}
            </TabButton>
          </TabsListItem>
        ))}
      </TabsList>
    </NavContainer>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func
};

Tabs.defaultProps = {
  tabs: [],
  onTabClick: () => {}
};

const NavContainer = styled.nav`
  margin: 0.5rem 0;
`;

const TabsList = styled.ul`
  background: ${({ theme }) => theme.defaultBackground};
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const TabsListItem = styled.li`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25rem;

  &:not(:first-child) {
    margin-left: -1px;
  }
`;

const TabButton = styled.button`
  width: 100%;
  height: 100%;
  font-size: 0.875em;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0.5rem 0.75rem;
  color: ${({ theme }) => theme.text.secondaryColor};

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;

  &[aria-current="true"] {
    cursor: default;
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ theme }) => theme.secondaryColor};
    border-color: ${({ theme }) => theme.dividerColor};
  }

  &:not([aria-current="true"]) {
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  }

  &:hover:not([aria-current="true"]) {
    opacity: 0.75;
    border-color: ${({ theme }) => theme.dividerColor};
  }
`;

const enhance = compose(
  pure,
  withProps(({ tabs }) => {
    const activeTab = find(tabs, tab => tab.active);
    return {
      tabs: tabs.map(tab => tab.name),
      activeTab: activeTab !== undefined ? activeTab.name : null
    };
  }),
  withHandlers({
    onTabClick: ({ activeTab, onTabClick }) => event => {
      const newTab = event.target.value;
      if (activeTab !== newTab) {
        onTabClick(newTab);
      }
    }
  })
);

export default enhance(Tabs);
