import * as yup from "yup";
import { validationMessages } from "./messages";

export const checkboxRequiredValidationSchema = yup
  .number()
  .test("requiredCheckbox", validationMessages.requiredField, function(
    checkboxValue
  ) {
    return checkboxValue !== 0;
  });
