import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { DangerousInnerHtml } from "components/DangerousInnerHtml";

import { ResponsiveTable } from "../../TableComponents/ResponsiveTable";

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.text.primaryColor};

  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: none;
  }
`;

const Table = ({ items }) => {
  return (
    <ResponsiveTable>
      <caption className="sr-only">Starsze wersje artykułu</caption>
      <thead>
        <tr>
          <th>Lp.</th>
          <th>Tytuł</th>
          <th>Czas</th>
          <th>Wersja</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>
              <StyledLink to={`/${item.link}`}>
                <DangerousInnerHtml html={item.title} />
              </StyledLink>
            </td>
            <td>{item.date}</td>
            <td>{item.version}</td>
          </tr>
        ))}
      </tbody>
    </ResponsiveTable>
  );
};

Table.propTypes = {
  items: PropTypes.array.isRequired
};

Table.defaultProps = {
  items: []
};

export default Table;
