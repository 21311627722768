import React from "react";

import { ContentColumn } from "./ContentColumn";
import { Row } from "../../TableComponents/MetricGrid";
import { Translate } from "../../../translate";

interface IProps {
  views: number;
}

const VisitorNumber = ({ views }: IProps) => {
  return (
    <Row>
      <dt
        className="col-12 col-md-6"
        style={{ fontWeight: "bold", margin: "0" }}
      >
        <Translate>Number of visits</Translate>
      </dt>
      <ContentColumn>{views}</ContentColumn>
    </Row>
  );
};

export { VisitorNumber };
