import React, { Suspense } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Aside } from "../Aside";
import Container from "./../container";
import { Footer } from "../Footer";
import { AppHeader } from "../Header";
import SkipLinks from "./../skip-links";
import { Toolbar } from "../toolbar";
import { AppLoader } from "../../App/AppLoader";

const Layout = ({ children }) => {
  return (
    <DefaultStyleWrapper>
      <SkipLinks />
      <Toolbar />
      <AppHeader />
      <Content>
        <Aside />
        <Suspense fallback={<AppLoader />}>
          <Main id="skip-link-main">{children}</Main>
        </Suspense>
      </Content>

      <Footer />
    </DefaultStyleWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

const DefaultStyleWrapper = styled.div`
  background: ${({ theme }) =>
    theme.layoutBackground || theme.defaultBackground};
  color: ${({ theme }) => theme.text.primaryColor};
`;

const Content = styled(Container)`
  background: ${({ theme }) => theme.contentBackground};
  display: flex;
  flex-direction: column;

  @media print {
    max-width: 100%;
    margin-left: 30pt;
    margin-right: 30pt;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const Main = styled.div`
  width: 100%;

  @media screen and (min-width: 992px) {
    flex-grow: 1;
    max-width: calc(100% - 250px);
    padding-left: 1rem;
  }
`;

export default Layout;
