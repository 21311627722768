import useRouter from "use-react-router";

export const useGetIsOutdated = () => {
  const { match } = useRouter<{
    method: string;
    id: string;
  }>();

  return match.params.method === "o" || match.params.method === "getOutdated"
    ? 1
    : 0;
};
