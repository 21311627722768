export const createRedirectUrl = (
  unit: string,
  hasSeparator: boolean,
  separator: string,
  startArticleId: number,
  startMenuId: number,
  startUrl: string
) => {
  if (startArticleId) {
    return `/${unit}${hasSeparator ? separator : ""}${startUrl}`;
  }
  if (startMenuId) {
    return `/${startUrl}`;
  }
  return `/${unit}${hasSeparator ? separator : ""}${startUrl}`;
};
