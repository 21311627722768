/**
 * Created by fkurzawa on 19.03.18.
 */
import React from "react";
import styled from "styled-components";

import { getHistoricalArticleZipAttachmentsUrl } from "modules/articles/api/getHistoricalArticleZipAttachmentsUrl";
import { getArticleZipAttachmentsUrl } from "modules/articles/api/getArticleZipAttachmentsUrl";

import { useDownloadFile } from "utils/downloadFile/useDownloadFile";

import { Button } from "../../../buttons";
import Translate from "../../../translate";
import { Loader } from "../../../NewLoader";
import {
  INotificationType,
  toastNotification
} from "../../../ToastNotification/toastNotification";

interface IProps {
  versionId: number;
  isHistorical: boolean;
  disabled: boolean;
}

const DownloadAll = ({ versionId, isHistorical, disabled }: IProps) => {
  const { downloadFile, isDownloading } = useDownloadFile();

  const newDownload = async () => {
    const fileUrl = isHistorical
      ? getHistoricalArticleZipAttachmentsUrl(versionId)
      : getArticleZipAttachmentsUrl(versionId);
    try {
      await downloadFile(fileUrl);
    } catch (e) {
      toastNotification(
        INotificationType.ERROR,
        "Pobieranie załączników",
        "Wystąpił problem z pobraniem załączników."
      );
    }
  };

  return (
    <Container>
      <Button
        type="button"
        data-flat="true"
        data-color="strongPrimary"
        onClick={newDownload}
        disabled={disabled || isDownloading}
        id="download-all-attachments-button"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span
          aria-hidden={true}
          className="fa fa-download"
          style={{ marginRight: "5px" }}
        />
        <Translate>Download all attachments</Translate>
        {isDownloading && (
          <span style={{ marginLeft: "5px" }}>
            <Loader size={20} />
          </span>
        )}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`;

export { DownloadAll };
