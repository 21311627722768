import styled from "styled-components";

export const Input = styled.input`
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
  border-radius: 3px;
  display: block;
  font-size: 1em;
  padding: 0.25rem 0.5rem;
  width: 100%;

  &:not(:disabled) {
    background: ${({ theme }) => theme.defaultBackground};
    color: ${({ theme }) => theme.text.primaryColor};
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;
