import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import {
  getVisitorsCounter,
  getVisitorsCounterKey
} from "../api/getVisitorsCounter";

import { RemoteData } from "components/RemoteData/RemoteData";

const VisitorsCounter = () => {
  const { friendlyUrl } = useMappedState(mapStateToProps);
  return (
    <RemoteData<number>
      queryKey={getVisitorsCounterKey(friendlyUrl)}
      fetcher={() => getVisitorsCounter(friendlyUrl)}
      data-testid="test-SiteMap"
      errorFallback={() => <></>}
    >
      {visitorsCounter => {
        return (
          <Container id="statistics_main">
            Liczba odwiedzin: {visitorsCounter || 0}
          </Container>
        );
      }}
    </RemoteData>
  );
};

const mapStateToProps = ({ context }: any) => ({
  friendlyUrl: context.contextFriendlyUrl
});

const Container = styled.section`
  color: ${({ theme }) => theme.asideMenuItemColor || theme.text.primaryColor};
  font-size: 0.75em;
  padding: 1rem;
`;

export { VisitorsCounter };
