import axios from "axios";
import { join } from "lodash";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import Modal from "../modal";
import ApiConfig from "../../api/api.config";
import { Button } from "../buttons";

const apiUrl = `${ApiConfig.getEntryPoint()}`;
export const getAnnouncements = () => {
  return axios.get(`${apiUrl}announcements/currents`).then(response => {
    return response.data;
  });
};

const AnnouncementsModal = () => {
  const [cookies, setCookie] = useCookies();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [announcementsToDisplay, setAnnouncementsToDisplay] = React.useState(
    []
  );
  const handleClose = () => {
    setIsOpen(null);
  };

  const displayAnnouncements = async () => {
    const cookieAnnouncementIds = (cookies["announcements"] || "").split(",");
    let announcements = [];
    try {
      announcements = await getAnnouncements();
    } catch (err) {
      console.log(err);
    }
    const announcementsToDisplay = [];
    const announcementIds = announcements.map(a => {
      if (!cookieAnnouncementIds.includes(a.id?.toString())) {
        announcementsToDisplay.push(a);
      }
      return a.id;
    });

    if (announcementsToDisplay.length === 0) return;
    setAnnouncementsToDisplay(announcementsToDisplay);
    setIsOpen(true);
    setCookie("announcements", join(announcementIds, ","), {
      maxAge: 24 * 60 * 60
    });
  };

  useEffect(() => {
    displayAnnouncements();
  }, []);

  return (
    <Modal show={modalIsOpen} width={"90%"} height={"90%"}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div />
        <Button onClick={handleClose}>Zamknij</Button>
      </div>
      <div style={{ margin: "20px" }}>
        {announcementsToDisplay.map(a => (
          <div
            style={{
              border: "1px solid #dddddd",
              padding: "20px",
              marginBottom: "20px"
            }}
            dangerouslySetInnerHTML={{
              __html: a.body
            }}
          />
        ))}
      </div>
    </Modal>
  );
};
export default AnnouncementsModal;
