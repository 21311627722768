/**
 * Created by fkurzawa on 13.06.18.
 */
import { createActions, handleActions } from "redux-actions";

import { createTranslationsDictionary } from "../utils/createTranslationsDictionary";

const { setTranslations, setLanguage } = createActions(
  "SET_TRANSLATIONS",
  "SET_LANGUAGE"
);

const reducer = handleActions(
  {
    [setTranslations]: (state, { payload }) => ({
      ...state,
      translations: createTranslationsDictionary(payload)
    }),
    [setLanguage]: (state, { payload }) => ({
      ...state,
      langCode: payload
    })
  },
  {
    translations: {}
  }
);

export { setTranslations, setLanguage };
export default reducer;
