import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import Translate from "./../../translate";

const HistoricalLink = ({ link }) => {
  return (
    <Link to={`/${link}`}>
      <Translate>
        Click here to go to the most recent version of the article.
      </Translate>
    </Link>
  );
};

HistoricalLink.propTypes = {
  link: PropTypes.string.isRequired
};

const Link = styled(RouterLink)`
  padding: 1rem 0;
  color: ${({ theme }) => theme.text.secondaryColor};
  font-size: 1.125em;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
  }

  @media screen and (max-width: 767px) {
    margin-top: 1rem;
  }
`;

export default HistoricalLink;
