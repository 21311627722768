/**
 * Created by fkurzawa on 16.03.18.
 */
import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "components/Tooltip";

import { UserTipRow } from "./UserTipRow";

const UserTip = ({ children, id, contact, description, email, phone }) => (
  <Tooltip
    id={id}
    label={
      <>
        {!contact && !description && !email && !phone && "Brak informacji"}
        {!!contact && <UserTipRow label="Kontakt" value={contact} />}
        {!!description && <UserTipRow label="Opis" value={description} />}
        {!!email && <UserTipRow label="Email" value={email} />}
        {!!phone && <UserTipRow label="Telefon" value={phone} />}
      </>
    }
  >
    {children}
  </Tooltip>
);

UserTip.propTypes = {
  id: PropTypes.string.isRequired,
  contact: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
};

export { UserTip };
