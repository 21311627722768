import { createBreadcrumbItem } from "utils/breadcrumbs";

import { IMenuArticlesList } from "modules/articles/types/IMenuArticlesList";

export const createArticleBreadCrumbs = (menuArticles: IMenuArticlesList) => {
  return (
    menuArticles &&
    menuArticles.menuPath
      .filter((menuElement: any) => !!menuElement.parentId)
      .map((menuElement: any) =>
        createBreadcrumbItem(menuElement.name, menuElement.url || "#")
      )
      .reverse()
  );
};
