import * as yup from "yup";

import { IDynamicFieldSchema } from "utils/yup/IDynamicFieldSchema";
import { validationMessages } from "utils/yup/messages";

import { ISurveyQuestion } from "modules/survey/types/ISurveyQuestion";
import { FIELD_TYPES } from "modules/survey/types/FieldTypes";

const checkRequirement = (isRequired: boolean) => {
  return isRequired && validationMessages.requiredField;
};

export const surveyFormValidationSchema = (
  surveyQuestion: ISurveyQuestion
): IDynamicFieldSchema => {
  const fieldName = `form_${surveyQuestion.id}`;
  switch (surveyQuestion.type) {
    case FIELD_TYPES.OPEN:
      return {
        [fieldName]:
          surveyQuestion.isRequired === 1
            ? yup.string().required(validationMessages.requiredField)
            : yup.string()
      };

    case FIELD_TYPES.DATE:
      return {
        [fieldName]:
          surveyQuestion.isRequired === 1
            ? yup.string().required(validationMessages.requiredField)
            : yup.string()
      };
    case FIELD_TYPES.NUMBER:
      return {
        [fieldName]: yup.number().required(() => {
          return checkRequirement(surveyQuestion.isRequired === 1);
        })
      };
    case FIELD_TYPES.LIST_ONE_CLOSED:
      return {
        [`${fieldName}`]:
          surveyQuestion.isRequired === 1
            ? yup.string().required(validationMessages.requiredField)
            : yup.string()
      };
    case FIELD_TYPES.LIST_ONE_OPEN:
      return {
        [`${fieldName}_listOneOpen`]:
          surveyQuestion.isRequired === 1
            ? yup.string().required(validationMessages.requiredField)
            : yup.string()
      };
    case FIELD_TYPES.LIST_ONE_COMMENT:
      return {
        [`${fieldName}_listOneComment`]:
          surveyQuestion.isRequired === 1
            ? yup.string().required(validationMessages.requiredField)
            : yup.string()
      };
    case FIELD_TYPES.LIST_MANY_CLOSED:
      return {
        [fieldName]:
          surveyQuestion.isRequired === 1
            ? yup
                .string()
                .test(fieldName, validationMessages.requiredField, function(
                  field
                ) {
                  const parent = this.parent;
                  const surveyQuestionFieldsLength =
                    surveyQuestion.fields?.length || 0;
                  for (let i = 0; i < surveyQuestionFieldsLength; i++) {}
                  const fieldValues = surveyQuestion.fields?.map(
                    (field, index) => {
                      return parent[`${fieldName}_${index}`];
                    }
                  );
                  return fieldValues?.some(
                    fieldValue => fieldValue !== undefined
                  ) || true;
                })
            : yup.object().shape({ listManyClosed: yup.object() })
      };
    case FIELD_TYPES.LIST_MANY_OPEN:
      return {
        [fieldName]:
          surveyQuestion.isRequired === 1
            ? yup
                .object()
                .shape({ listManyOpen: yup.object() })
                .test(fieldName, validationMessages.requiredField, function(
                  field
                ) {
                  return Object.values(field.listManyOpen).some(
                    fieldValue => fieldValue !== undefined
                  );
                })
            : yup.object().shape({ listManyOpen: yup.object() })
      };
    case FIELD_TYPES.LIST_MANY_COMMENT:
      return {
        [fieldName]:
          surveyQuestion.isRequired === 1
            ? yup
                .object()
                .shape({ listManyComment: yup.object() })
                .test(fieldName, validationMessages.requiredField, function(
                  field
                ) {
                  return Object.values(field.listManyComment).some(
                    fieldValue => fieldValue !== undefined
                  );
                })
            : yup.object().shape({ listManyComment: yup.object() })
      };
    // case FIELD_TYPES.LIST_RANKING:
    case FIELD_TYPES.LIST_MATRIX:
      return {
        [fieldName]:
          surveyQuestion.isRequired === 1
            ? yup
                .string()
                .test(fieldName, validationMessages.requiredField, function(
                  field
                ) {
                  const parent = this.parent;
                  return Object.keys(parent).filter(key => {
                    return key.includes(fieldName);
                  }).every(filteredKey => {
                    return parent[filteredKey]
                  });
                })
            : yup.object().shape({ listManyComment: yup.object() })
      };
    default:
      return { [surveyQuestion.name]: yup.string().nullable() };
  }
};
