import styled from "styled-components";

export const Main = styled.div`
  width: 100%;

  @media screen and (min-width: 992px) {
    flex-grow: 1;
    max-width: calc(100% - 250px);
    padding-left: 1rem;
  }
`;
