import axios from "axios";
import ApiConfig from "./api.config";
import { IContext } from "../types/IContext";
import { IUnitTypeWithUnits } from "../types/IUnitTypeWithUnits";
import { IMap } from "../types/IMap";

const apiUrl = `${ApiConfig.getEntryPoint()}contexts`;

export const getContext = (
  friendlyUrl: string = "default"
): Promise<IContext> => {
  return axios.get(`${apiUrl}/${friendlyUrl}`).then(response => response.data);
};

export const openRss = (friendlyUrl: string) => {
  window.open(getRssUrl(friendlyUrl), "_blank");
};

export const getRssUrl = (friendlyUrl: string) => {
  return `${apiUrl}/${friendlyUrl}/rss`;
};

export const getOrganizationalUnits = (
  friendlyUrl: string
): Promise<IUnitTypeWithUnits[]> => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/organizational-units`)
    .then(response => response.data);
};

export const getGoogleMapConfiguration = (
  friendlyUrl: string
): Promise<IMap> => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/google-map`)
    .then(response => response.data);
};

