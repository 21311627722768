import axios from "axios";

import { ISurvey } from "../types/ISurvey";

import ApiConfig from "../../../api/api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}contexts`;

export const getSurvey = (
  friendlyUrl: string,
  surveyId: number
): Promise<ISurvey> => {
  return axios
    .get(`${apiUrl}/${friendlyUrl}/surveys/${surveyId}`)
    .then(response => response.data);
};
