/**
 * Created by fkurzawa on 29.01.18.
 */
import { createActions, handleActions } from "redux-actions";
import uuid from "uuid/v4";
import { addLoader, removeLoader } from "./../../redux/modules/loader";
import { getMenu } from "../../api/menus";
import { createSeparator, isSeparator } from "./../../utils/menu";

const {
  clearSelectedMenuItem,
  fetchMenuSucceeded,
  fetchAdditionalMenuSucceeded,
  selectMenuItem
} = createActions(
  "CLEAR_SELECTED_MENU_ITEM",
  "FETCH_MENU_SUCCEEDED",
  "FETCH_ADDITIONAL_MENU_SUCCEEDED",
  "SELECT_MENU_ITEM"
);

const reducer = handleActions(
  {
    [clearSelectedMenuItem]: (state, { payload: selectedId }) => ({
      ...state,
      selectedId: null
    }),
    [fetchAdditionalMenuSucceeded]: (state, { payload: additionalItems }) => ({
      ...state,
      additionalItems
    }),
    [fetchMenuSucceeded]: (state, { payload: items }) => ({
      ...state,
      items
    }),
    [selectMenuItem]: (state, { payload: selectedId }) => ({
      ...state,
      selectedId
    })
  },
  {
    selectedId: null
  }
);

const fetchMenu = menuId => async dispatch => {
  const loaderId = uuid();
  dispatch(addLoader(loaderId));
  try {
    const menuItems = await getMenu(menuId);
    dispatch(fetchMenuSucceeded(menuItems));
    dispatch(removeLoader(loaderId));
  } catch (e) {
    dispatch(removeLoader(loaderId));
  }
};

const mainMenuSeparatorInjector = store => next => action => {
  const mainSeparatorName = store.getState().context.mainSeparatorName || ""
  if (action.type === fetchMenuSucceeded.toString()) {
    const { payload: menuItems } = action;
    const shouldAddMenuSeparator =
      menuItems.length > 0 && isSeparator(menuItems[0]) === false;
    const menuItemsWithMainSeparator = shouldAddMenuSeparator
      ? [createSeparator(mainSeparatorName), ...menuItems]
      : menuItems;
    next({
      ...action,
      payload: menuItemsWithMainSeparator
    });
  } else {
    next(action);
  }
};

export {
  clearSelectedMenuItem,
  fetchAdditionalMenuSucceeded,
  fetchMenu,
  fetchMenuSucceeded,
  mainMenuSeparatorInjector,
  selectMenuItem
};
export default reducer;
