/**
 * Created by fkurzawa on 22.03.18.
 */
import PropTypes from "prop-types";
import styled from "styled-components";

const Column = styled.div.attrs({ className: "col-12 col-md-6" })`
  font-weight: ${({ head }) => (head ? "bold" : "normal")};
`;

Column.propTypes = {
  head: PropTypes.bool
};

export { Column };
