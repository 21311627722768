import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose, mapProps } from "recompose";
import { omit } from "lodash";

import { StyledButton } from "./Button";

const ExternalLinkButton = ({ children, color = "default", ...restProps }) => {
  return (
    <StyledLink data-color={color} as="a" {...restProps}>
      {children}
    </StyledLink>
  );
};

const mapStateToProps = ({ accessibility }) => ({
  "data-contrast": accessibility.isContrast === true ? "true" : "false"
});

const enhance = compose(
  connect(mapStateToProps),
  mapProps(props => omit(props, "dispatch"))
);

const StyledLink = styled(StyledButton)`
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
`;

export default enhance(ExternalLinkButton);
