/**
 * Created by fkurzawa on 27.03.18.
 */
import styled from "styled-components";

const Label = styled.label`
  display: block;
  width: 100%;
  color: ${({ hasError, theme }: any) =>
    hasError ? theme.errorColor : theme.text.primaryColor};
  font-size: 0.875em;
  margin: 0;
`;

export default Label;
