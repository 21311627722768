/**
 * Created by fkurzawa on 26.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { compose, withStateHandlers } from "recompose";

const Element = ({ element, isOpen, toggleIsOpen }) => {
  return (
    <section>
      <Header onClick={toggleIsOpen}>
        <Question>{element.question}</Question>
      </Header>
      <Collapse isOpened={isOpen}>
        <Answer>{element.answer}</Answer>
      </Collapse>
    </section>
  );
};

Element.propTypes = {
  element: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleIsOpen: PropTypes.func
};

Element.defaultProps = {
  toggleIsOpen: () => {}
};

const Header = styled.div`
  cursor: pointer;
`;

const Question = styled.h2`
  margin: 0;
  font-size: 0.875em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 0;
  border-bottom: 2px solid ${({ theme }) => theme.dividerColor};
  color: ${({ theme }) => theme.text.primaryColor};
`;

const Answer = styled.p`
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.secondaryColor};
`;

const composed = compose(
  withStateHandlers(
    { isOpen: false },
    {
      toggleIsOpen: ({ isOpen }) => () => ({ isOpen: !isOpen })
    }
  )
);

export default composed(Element);
