import React from "react";

import { IChildrenProp } from "types/IChildrenProp";

import { Button } from "../../../../../buttons";

interface IProps extends IChildrenProp {
  title: string;
  id: string;
}

const DropdownButton = ({ title, children, id }: IProps) => {
  return (
    <Button data-color="secondary" id={id} title={title}>
      <span style={{ display: "none" }}>Dropdown</span>
      {children}
    </Button>
  );
};

export { DropdownButton };
