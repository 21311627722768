import React from "react";
import { SkipLink } from "./SkipLink";
import { useShowDeclarationOfAvailabilitySkipLink } from "./utils/useShowDeclarationOfAvailabilitySkipLink";
import { useGetDeclarationOfAvailabilityUrl } from "../../utils/useGetDeclarationOfAvailabilityUrl";

const SkipLinks = () => {
  const showDeclarationOfAvailability = useShowDeclarationOfAvailabilitySkipLink();
  const declarationOfAvailabilityUrl = useGetDeclarationOfAvailabilityUrl();

  return (
    <ul style={{ listStyleType: "none", marginTop: "0", marginBottom: "0" }}>
      <li>
        <SkipLink href="#skip-link-aside">Przejdź do nawigacji</SkipLink>
      </li>
      <li>
        <SkipLink href="#skip-link-main">Przejdź do treści</SkipLink>
      </li>
      <li>
        <SkipLink
          as="button"
          onClick={() => {
            document
              .getElementById("search-suggestion-input-container_input")
              ?.focus();
          }}
        >
          Przejdź do wyszukiwarki
        </SkipLink>
      </li>
      {showDeclarationOfAvailability && (
        <li>
          <SkipLink href={declarationOfAvailabilityUrl}>
            Przejdź do deklaracji dostępności
          </SkipLink>
        </li>
      )}
    </ul>
  );
};

export default SkipLinks;
