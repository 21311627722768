import React from "react";
import styled from "styled-components";
import { isContrast } from "../../utils";
// @ts-ignore
import { amber } from "material-colors";

interface IProps {
  size?: number;
}

const Loader = ({ size }: IProps) => {
  const loaderColor = isContrast() ? amber[500] : "#c62828";
  return (
    <LoaderContainer>
      <StyledLoader loaderColor={loaderColor} size={size} />
    </LoaderContainer>
  );
};

const StyledLoader = styled.div`
  border: ${({ size }) => (size ? size / 8 + "px" : "6px")} solid #d7dbdd;
  border-radius: 50%;
  border-top: ${({ size }) => (size ? size / 8 + "px" : "6px")} solid
    ${({ loaderColor }) => loaderColor};
  width: ${({ size }) => (size ? size + "px" : "80px")};
  height: ${({ size }) => (size ? size + "px" : "80px")};
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 2.25em;
`;

export { Loader };
