/**
 * Created by fkurzawa on 28.06.18.
 */
import { amber, white, grey, blueGrey, red } from "material-colors";

const primaryTheme = {
  primaryColor: grey["500"],
  darkPrimaryColor: grey["700"],
  lightPrimaryColor: grey["200"],
  secondaryColor: red["800"],
  dividerColor: grey["400"],
  errorColor: red["600"],
  highlightBackground: amber["100"],

  defaultBackground: white,
  contentBackground: grey["300"],
  footerBackground: grey["400"],
  footerGoUpColor: blueGrey["600"],
  headerBackground: grey["400"],
  focusOutline: "black solid 2px",
  text: {
    primaryColor: blueGrey["800"],
    secondaryColor: blueGrey["600"],
    lightColor: blueGrey["200"]
  },

  buttons: {
    primary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    },

    secondary: {
      color: grey["50"],
      background: blueGrey["500"],
      hoverBackground: blueGrey["900"]
    },

    default: {
      color: grey["800"],
      background: grey["400"],
      hoverBackground: grey["600"]
    },

    strongPrimary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    },

    strongSecondary: {
      color: grey["50"],
      background: red["500"],
      hoverBackground: red["900"]
    }
  }
};

export default primaryTheme;
