/**
 * Created by fkurzawa on 21.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal as OverlaysModal } from "react-overlays";
import { black } from "material-colors";

const Modal = ({ children, show, onHide, width, height }) => (
  <OverlaysModal
    style={modalStyle}
    backdropStyle={backdropStyle}
    show={show}
    onHide={onHide}
  >
    <ModalContent width={width} height={height}>
      {children}
    </ModalContent>
  </OverlaysModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string
};

const modalStyle = {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "100"
};

const backdropStyle = {
  position: "absolute",
  zIndex: "auto",
  width: "100%",
  height: "100%",
  backgroundColor: black,
  opacity: ".6",
  top: "0px",
  left: "0px"
};

const ModalContent = styled.div`
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 2px;
  height: ${({ height }) => height};
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
  width: ${({ width }) => width};
  z-index: 1;

  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
  }
`;

export default Modal;
