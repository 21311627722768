import { map as createMap, marker as createMarker, tileLayer } from "leaflet";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled, { withTheme } from "styled-components";
import uuid from "uuid/v4";

const LeafletMap = ({
  mapConfiguration,
  mapProviderData,
  onMarkerClick,
  onMarkerCloseClick,
  openedMarkerId,
  theme
}) => {
  const ref = useRef(null);
  const leafletMapContainer = ref?.current;

  const [mapId] = useState(`map-${uuid()}`);
  const [markers, setMarkers] = useState({});

  let map = null;
  useEffect(() => {
    // eslint-disable-next-line
    map = createMap(mapId).setView(
      [mapConfiguration.centerLat, mapConfiguration.centerLng],
      mapConfiguration.zoom
    );
  }, [mapId]);

  useEffect(() => {
    //Po to, żeby pozbyć się atrybutu role, który w tym kontekście nie jest akceptowany przez walidator w3c bo imgages są puste
    //konkretnie chodzi o kafelki z kawałkami mapy
    //@todo: Warto sprawdzić jak to działa w nowszych wersjach biblioteki leaflet, na ten moment brak czasu
    const images =
      leafletMapContainer && leafletMapContainer.getElementsByTagName("img");
    if (images) {
      for (let i = 0; i < images.length; i++) {
        images[i].removeAttribute("role");
      }
    }
  }, [leafletMapContainer]);

  useEffect(() => {
    map &&
      tileLayer(mapProviderData.mapUrl, {
        attribution: `&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="http://cartodb.com/attributions">CartoDB</a>, Maps &copy; <a href="${mapProviderData.providerUrl}">${mapProviderData.providerName}</a>`,
        maxZoom: 18,
        id: "mapbox.streets",
        apiKey: mapProviderData.apiKey
      }).addTo(map);

    let tempMarkers = {};
    map &&
      mapConfiguration.markers.forEach(marker => {
        const tempMarker = createMarker([marker.lat, marker.lng])
          .addTo(map)
          .bindPopup(renderPopup(marker, theme))
          .on("popupopen", () => onMarkerClick(marker.id))
          .on("popupclose", () => onMarkerCloseClick());
        tempMarkers[marker.id] = tempMarker;
      });
    map && setMarkers(tempMarkers);
  }, [
    map,
    mapConfiguration,
    mapId,
    mapProviderData.apiKey,
    mapProviderData.mapUrl,
    mapProviderData.providerName,
    mapProviderData.providerUrl,
    onMarkerClick,
    onMarkerCloseClick,
    theme
  ]);

  useEffect(() => {
    const marker = markers[openedMarkerId];
    if (marker) {
      marker.openPopup();
    }
  }, [markers, openedMarkerId]);

  return (
    <div id={`leaflet-map-${mapProviderData.providerName}`} ref={ref}>
      <Container id={mapId} />
    </div>
  );
};

const renderPopup = (
  { name, address, description },
  theme
) => `<div style="text-align: center; color: ${theme.text.primaryColor};">
${
  name
    ? `<h5 style="font-size: 1.125em; text-transform: uppercase">${name}</h5>`
    : ""
}
${address ? `<address style="margin: 0; padding: 0;">${address}</address>` : ""}
${
  description
    ? `<p style="margin-top: 1.125rem; border-top: 1px solid ${theme.dividerColor}; padding-top: 1rem;">${description}</p>`
    : ""
}
</div>`;

const Container = styled.div`
  height: 400px;
  z-index: 0;
`;

LeafletMap.propTypes = {
  mapConfiguration: PropTypes.shape({
    centerLat: PropTypes.number.isRequired,
    centerLng: PropTypes.number.isRequired,
    markers: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        lat: PropTypes.number,
        lng: PropTypes.number,
        name: PropTypes.string,
        position: PropTypes.number
      })
    ),
    zoom: PropTypes.number.isRequired
  }),
  mapProviderData: PropTypes.shape({
    provider: PropTypes.string,
    providerName: PropTypes.string,
    providerUrl: PropTypes.string,
    apiKey: PropTypes.string,
    mapUrl: PropTypes.string
  }),
  openedMarkerId: PropTypes.number,
  onMarkerClick: PropTypes.func.isRequired,
  onMarkerCloseClick: PropTypes.func.isRequired
};

export default withTheme(LeafletMap);
