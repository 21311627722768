export const processAudio = processNodeDefinitions => {
  return {
    shouldProcessNode: node =>
        node.name && node.name === "audio",
    processNode: (node, children, index) => {
      const updatedNode = {
        ...node,
        attribs: {
          ...node.attribs,
          controls: true
        }
      };
      return processNodeDefinitions.processDefaultNode(
          updatedNode,
          children,
          index
      );
    }
  };
};
