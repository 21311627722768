import React from "react";

interface IProps extends React.HTMLAttributes<HTMLElement> {}

const HeadColumn = ({ children, ...restProps }: IProps) => {
  return (
    <dt
      className="col-12 col-md-6"
      style={{ fontWeight: "bold", margin: "0" }}
      {...restProps}
    >
      {children}
    </dt>
  );
};

export { HeadColumn };
