/**
 * Created by fkurzawa on 29.03.18.
 */
import styled from "styled-components";
import PropTypes from "prop-types";

const TableCell = styled.div`
  padding: 0.375rem 0.5rem;
  width: ${({ $width }) => `${$width ? `${$width}%` : "auto"}`};
`;

TableCell.propTypes = {
  width: PropTypes.number
};

export default TableCell;
