import { useRef } from "react";

export const useRemoveFocus = () => {
  const htmlElRef = useRef(null);
  const removeFocus = () => {
    // @ts-ignore
    htmlElRef && htmlElRef.current && htmlElRef.current.blur();
  };

  return [htmlElRef, removeFocus];
};
