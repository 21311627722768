import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const DesktopAccessibilityButton = ({ ...props }: IProps) => {
  const { isContrast } = useMappedState(mapStateToProps);

  return <StyledButton isContrast={isContrast} {...props} />;
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const StyledButton = styled.button`
  font-weight: bold;
  color: ${({ theme }) => theme.text.primaryColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  min-width: 40px;
  font-size: 0.875em;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  border: solid 1px transparent;
  background-color: transparent;

  &:hover {
    border: solid 1px ${({ theme }) => theme.text.primaryColor};
  }

  &:-moz-focusring {
    outline: 2px solid ${({ isContrast }) => (isContrast ? "white" : "black")};
  }
`;

export { DesktopAccessibilityButton };
