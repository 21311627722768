import React from "react";
import { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";

import { Controller, useFormContext } from "react-hook-form";
import { FieldPrototype } from "../FieldPrototype";
import { DatePickerInput } from "./DatePickerInput";

interface IProps extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  id: string;
  onChange?: () => void;
  lang?: string;
  label?: string;
  className?: string;
  isRequired?: boolean;
}

const DateField = ({
  isRequired = true,
  name,
  lang,
  label,
  className,
  onChange,
  ...datePickerProps
}: IProps) => {
  const { setValue } = useFormContext();

  const handleChange = (date: Date) => {
    setValue(
      name,
      date
        ? moment(date)
            .format("YYYY-MM-DD")
            .toString()
        : "",
      { shouldValidate: true }
    );
    onChange && onChange();
  };

  return (
    <FieldPrototype isRequired={isRequired} name={name} label={label}>
      {({ hasError }) => (
        <Controller
          name={name}
          render={props => {
            return (
              <DatePickerInput
                handleChange={handleChange}
                hasError={hasError}
                name={name}
                value={props.value}
                {...datePickerProps}
              />
            );
          }}
        />
      )}
    </FieldPrototype>
  );
};

export { DateField };
