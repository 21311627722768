import styled from "styled-components";

const Text = styled.p`
  color: ${({ theme }) => theme.text.primaryColor};
  font-size: 0.875em;
  margin: 0.125rem 0;
  padding: 0;
`;

export default Text;
