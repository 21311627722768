import React from "react";
import { PageTitle } from "../../../typography";

export const processTitle = {
  shouldProcessNode: node =>
    node.type === "tag" && node.name === "h1" && node.parent === null,
  processNode: (node, children, index) => (
    <PageTitle key={index}>{children}</PageTitle>
  )
};
