import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Overlay } from "react-overlays";
import { compose, withHandlers, withState } from "recompose";

import { UnderlineSpan } from "../../../../../../components/UnderlineSpan";
import { AdvancedSearchForm } from "./components/AdvancedSearchForm";

class AdvancedSearch extends React.Component {
  render() {
    const {
      showOverlay,
      onOpenOverlay,
      closeAdvanceSearch,
      fields,
      onSubmit
    } = this.props;

    return (
      <Container>
        <ToggleButton
          type="button"
          onClick={onOpenOverlay}
          id="advanced-search-btn"
        >
          <UnderlineSpan>Wyszukiwanie zaawansowane</UnderlineSpan>
        </ToggleButton>
        <Overlay
          show={showOverlay}
          onHide={closeAdvanceSearch}
          container={this}
          rootClose
        >
          <React.Fragment>
            <FormContainer>
              <AdvancedSearchForm fields={fields} onSubmit={onSubmit} />
            </FormContainer>
          </React.Fragment>
        </Overlay>
      </Container>
    );
  }
}

AdvancedSearch.propTypes = {
  fields: PropTypes.array,
  onSubmit: PropTypes.func.isRequired
};

AdvancedSearch.defaultProps = {
  fields: []
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ToggleButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.secondaryColor};

  &:hover {
    color: ${({ theme }) => theme.secondaryColor};
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

const FormContainer = styled.div`
  position: absolute;
  top: 69px;
  z-index: 1;
  background: ${({ theme }) => theme.defaultBackground};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 0.125rem;
  padding: 1rem;
  width: 300px;
`;

const enhance = compose(
  withState("showOverlay", "setShowOverlay", false),
  withHandlers({
    onOpenOverlay: ({ setShowOverlay }) => () => {
      setShowOverlay(true);
    },
    closeAdvanceSearch: ({ setShowOverlay }) => () => {
      setShowOverlay(false);
    }
  })
);

export default enhance(AdvancedSearch);
