import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { DangerousInnerHtml } from "components/DangerousInnerHtml";
import { useTranslation } from "components/translate";

const BreadcrumbItem = ({ itemAccessKey, current, redirect, title, to }) => {
  const { translate } = useTranslation();

  return (
    <>
      {current ? (
        <ActiveListItem className="breadcrumb-item" aria-current="page">
          <DangerousInnerHtml as="span" html={translate(title)} />
        </ActiveListItem>
      ) : (
        <ListItem className="breadcrumb-item">
          {!redirect && (
            // eslint-disable-next-line
            <Link to={to} accessKey={itemAccessKey ? itemAccessKey : undefined}>
              <DangerousInnerHtml as="span" html={translate(title)} />
            </Link>
          )}
          {redirect && (
            // eslint-disable-next-line
            <a href={to} accessKey={itemAccessKey ? itemAccessKey : undefined}>
              <DangerousInnerHtml as="span" html={translate(title)} />
            </a>
          )}
        </ListItem>
      )}
    </>
  );
};

BreadcrumbItem.propTypes = {
  itemAccessKey: PropTypes.string,
  current: PropTypes.bool,
  redirect: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.string
};

const ListItem = styled.li`
  font-size: 0.875em;
  cursor: pointer;

  a {
    color: ${({ theme }) => theme.text.primaryColor};
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
    }

    &:focus:not(:active) {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }
`;

const ActiveListItem = styled(ListItem)`
  color: ${({ theme }) => theme.text.secondaryColor};
  cursor: default;
`;

export { BreadcrumbItem };
