import React from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "components/buttons";
import { FormFieldType } from "../types";

interface IProps<T> {
  fields: T[];
  id: string;
}

const ResetDynamicFormButton = <
  T extends { name: string; type: FormFieldType }
>({
  fields,
  id
}: IProps<T>) => {
  const { setValue } = useFormContext();

  const handleReset = () => {
    fields.forEach(field => {
      switch (field.type) {
        case FormFieldType.NUMBER_RANGE:
        case FormFieldType.DATE_RANGE:
          setValue(`${field.name}-to`, "");
          setValue(`${field.name}-from`, "");
          return;
        default:
          setValue(field.name, "");
      }
    });
  };

  return (
    <Button type="button" id={id} onClick={handleReset} data-color="secondary">
      Wyczyść
    </Button>
  );
};

export { ResetDynamicFormButton };
