import React, { useState } from "react";
import styled from "styled-components";

import { Heading } from "./components/Heading";

import { IChildrenProp } from "types/IChildrenProp";
import { CollapseContent } from "./components/CollapseContent";

interface IProps extends IChildrenProp {
  id: string;
  title: string;
  printable?: boolean;
  isInitiallyOpen?: boolean;
  withCollapse?: boolean;
}

const Panel = ({
  children,
  id,
  title,
  printable = false,
  isInitiallyOpen = false,
  withCollapse = true
}: IProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const handleToggleIsOpen = () => {
    if (!withCollapse) {
      return;
    }
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return (
    <Container id={`${id}-container`} printable={printable}>
      <Heading
        id={id}
        isOpen={isOpen}
        onClick={handleToggleIsOpen}
        title={title}
        withCollapse={withCollapse}
      />
      {withCollapse ? (
        <CollapseContent id={id} isOpen={isOpen}>
          <Content>{children}</Content>
        </CollapseContent>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-size: 1em;

  .ReactCollapse--collapse {
    transition: height 500ms;
  }

  @media print {
    display: ${({ printable }) => (printable ? "block" : "none")};
  }
  margin-bottom: 30px;
`;

const Content = styled.div`
  padding: 25px 0 0 0;
`;

export { Panel };
