import { getFileUrl } from "modules/articles/api/getFileUrl";

export const processImageSource = processNodeDefinitions => {
  return {
    shouldProcessNode: node =>
      node.type === "tag" && (node.name === "source" || node.name === "img"),
    processNode: (node, children, index) => {
      const { src } = node.attribs;
      if (!src) {
        return;
      }
      const matchResult = src.match(/id(?:=|,)(\w+)(?:.json)(\?.*)?$/);
      if (matchResult !== null) {
        const [, id, query] = matchResult;
        console.log(id, query, matchResult);
        const updatedNode = {
          ...node,
          attribs: {
            ...node.attribs,
            style: node.attribs.style + ";max-width: 100%; height: auto;",
            src: getFileUrl(id) + (query ?? '')
          }
        };
        return processNodeDefinitions.processDefaultNode(
          updatedNode,
          children,
          index
        );
      } else {
        return processNodeDefinitions.processDefaultNode(node, children, index);
      }
    }
  };
};
