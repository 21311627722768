import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { ISurveyQuestion } from "modules/survey/types/ISurveyQuestion";

import { validationMessages } from "utils/yup/messages";

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell
} from "components/table";
import { FieldPrototype } from "components/HookForm";

import { MatrixRadioGroupField } from "./MatrixRadioGroupField";

interface IProps {
  name: string;
  questionField: ISurveyQuestion;
}

const MatrixField = ({ name, questionField }: IProps) => {
  const { watch, setValue, setError, clearErrors } = useFormContext();
  const isRequired = questionField.isRequired === 1;
  const label = questionField.name;

  const areAllFieldsFilled = useCallback(() => {
    const mappedNames = questionField.fields?.map(
      field => `${name}_${field.id}`
    );
    const someFieldNotFilled = mappedNames?.find(fieldName => {
      return !watch(fieldName);
    });
    return !someFieldNotFilled;
  }, [name, questionField.fields, watch]);

  const checkValidation = () => {
    if (areAllFieldsFilled()) {
      clearErrors(name);
    } else {
      setError(name, errorMessage);
    }
  };

  const handleFieldChange = (name: string, value: string) => {
    setValue(name, value, { shouldValidate: true });
    isRequired && checkValidation();
  };

  return (
    <FieldPrototype isRequired={isRequired} name={name} label={label}>
      {() => {
        return (
          <Table id={`id_${name}`}>
            <TableHead>
              <TableRow>
                <TableHeadCell>Wartość / Nazwa</TableHeadCell>
                {questionField.headers?.map(header => (
                  <TableHeadCell key={header.id}>{header.name}</TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questionField.fields?.map(field => (
                <TableRow key={field.id}>
                  <TableHeadCell>{field.name}</TableHeadCell>
                  <MatrixRadioGroupField
                    handleChange={handleFieldChange}
                    name={`${name}_${field.id}`}
                    options={
                      questionField.headers
                        ? questionField.headers.map(header =>
                            header.id.toString()
                          )
                        : []
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        );
      }}
    </FieldPrototype>
  );
};

const errorMessage = {
  type: "manual",
  message: validationMessages.requiredField
};

export { MatrixField };
