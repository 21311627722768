import React, { useState } from "react";
import styled from "styled-components";

import { LatestArticles } from "./components/LatestArticles";
import { MainMenuItems } from "./components/MainMenuItems";
import { Separator } from "./components/Separator";

import { useMappedState } from "redux-react-hook";

const Menu = () => {
  const {
    lastArticlesEnabled,
    items = [],
    additionalItems = []
  } = useMappedState(mapStateToProps);

  const menuItems = [...items, ...additionalItems];
  const [collapsedMobile, setCollapsedMobile] = useState(false);

  const toggleCollapsedMobile = () => {
    setCollapsedMobile(!collapsedMobile);
  };

  return (
    <MenuContainer>
      <DefaultList>
        <MainMenuItems items={menuItems} />
        {lastArticlesEnabled && <LatestArticles />}
      </DefaultList>

      <MobileList>
        {items.length > 0 && (
          <Separator
            text={{ ...items[0] }.name}
            onClick={toggleCollapsedMobile}
          />
        )}
        {!collapsedMobile && (
          <>
            <MainMenuItems items={menuItems.slice(1)} />
            {lastArticlesEnabled && <LatestArticles />}
          </>
        )}
      </MobileList>
    </MenuContainer>
  );
};

const MenuContainer = styled.nav`
  font-size: 0.875em;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const DefaultList = styled(List)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`;

const MobileList = styled(List)`
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const mapStateToProps = ({ context, menu }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  lastArticlesEnabled: context.lastArticlesEnabled,
  items: menu.items,
  additionalItems: menu.additionalItems
});

export { Menu };
