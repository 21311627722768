import React from "react";

import { TextAreaField, TextField, TextFieldType } from "components/HookForm";

import { ContactFormFieldType, IContactFormField } from "types/IContactForm";

interface IProps {
  field: IContactFormField;
}

const ContactFormFieldsSwitch = ({ field }: IProps) => {
  switch (field.type) {
    case ContactFormFieldType.TEXT_FIELD:
      return <TextField name={field.name} id={field.name} label={field.name} />;
    case ContactFormFieldType.MAIL_FIELD:
      return (
        <TextField
          type={TextFieldType.EMAIL}
          name={field.name}
          id={field.name}
          label={field.name}
        />
      );
    case ContactFormFieldType.TEXT_AREA:
      return (
        <TextAreaField name={field.name} id={field.name} label={field.name} />
      );
    default:
      throw new Error(`Field type ${field.type} not found`);
  }
};

export { ContactFormFieldsSwitch };
