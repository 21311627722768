/**
 * Created by fkurzawa on 27.02.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LinkRow from "./components/LinkRow";
import { RowWithMore } from "./components/RowWithMore";
import Media from "react-media";

const ArticlesTableBody = ({ articles, columns, linkMoreEnabled }) => (
  <TBody>
    {articles.map(article => (
      <React.Fragment key={article.id}>
        <Media query={{ maxWidth: 991 }}>
          {matches =>
            matches ? (
              <LinkRow article={article} columns={columns} />
            ) : (
              <React.Fragment>
                {linkMoreEnabled && (
                  <RowWithMore article={article} columns={columns} />
                )}
                {!linkMoreEnabled && (
                  <LinkRow article={article} columns={columns} />
                )}
              </React.Fragment>
            )
          }
        </Media>
      </React.Fragment>
    ))}
  </TBody>
);

ArticlesTableBody.propTypes = {
  articles: PropTypes.array,
  columns: PropTypes.array,
  linkMoreEnabled: PropTypes.bool
};

ArticlesTableBody.defaultProps = {
  articles: [],
  columns: []
};

const TBody = styled.tbody`
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};
`;

export default ArticlesTableBody;
