/**
 * Created by fkurzawa on 16.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ResponsiveTable = ({ children }) => (
  <div className="table-responsive">
    <Table>{children}</Table>
  </div>
);

ResponsiveTable.propTypes = {
  children: PropTypes.node.isRequired
};

const Table = styled.table.attrs({ className: "table" })`
  && {
    font-size: 0.75em;
    color: ${({ theme }) => theme.text.secondaryColor};

    th,
    td {
      border-color: ${({ theme }) => theme.dividerColor};
      padding-top: 9px;
      padding-bottom: 9px;
      word-break: keep-all;
    }

    th {
      border-top: none;
    }
  }
`;

export { ResponsiveTable };
