import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Loadable from "react-loadable";
import { Switch, Route, Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Loader, PrivateRoute, PublicRoute } from "./components";
import { useMappedState } from "redux-react-hook";
import { useGetUrlManager } from "./utils/useGetUrlManager";
import ArticleListContainer from "../../containers/articles-list/ArticleListContainer";
import Article from "../../containers/article/Article";

const FaqPage = Loadable({
  loader: () => import("../../containers/Faq"),
  loading: Loader
});

const AlcoholSellFeeCalculator = Loadable({
  loader: () => import("../../containers/Calculators/AlcoholSellFeeCalculator"),
  loading: Loader
});

const CalculatorsList = Loadable({
  loader: () => import("../../containers/Calculators/CalculatorsList"),
  loading: Loader
});
const ContactForm = Loadable({
  loader: () => import("./../../containers/contact-form"),
  loading: Loader
});
const ContentRegistry = Loadable({
  loader: () => import("../../containers/ContentRegistry"),
  loading: Loader
});
const Editors = Loadable({
  loader: () => import("../../containers/editors"),
  loading: Loader
});
const Declaration = Loadable({
  loader: () => import("../../containers/Declarations/Declaration"),
  loading: Loader
});
const EzdCaseStatus = Loadable({
  loader: () => import("../../containers/EzdCaseStatus/EzdCaseStatus"),
  loading: Loader
});
const EzdRegistriesList = Loadable({
  loader: () => import("../../containers/EzdRegistriesList/EzdRegistriesList"),
  loading: Loader
});
const EzdRegistryContainer = Loadable({
  loader: () => import("../../containers/EzdRegistry/EzdRegistryContainer"),
  loading: Loader
});
const GreenAreaAndTreeRemovalCalculator = Loadable({
  loader: () =>
    import("../../containers/Calculators/GreenAreaAndTreeRemovalCalculator"),
  loading: Loader
});
const Newsletter = Loadable({
  loader: () => import("../../containers/Newsletters/Newsletter"),
  loading: Loader
});
const NoContentPage = Loadable({
  loader: () => import("./../../containers/no-content-page/NoContentPage"),
  loading: Loader
});
const NoStartArticlePage = Loadable({
  loader: () => import("../../containers/article/NoStartArticlePage"),
  loading: Loader
});
const OccupationOfRoadwayCalculator = Loadable({
  loader: () =>
    import("../../containers/Calculators/OccupationOfRoadwayCalculator"),
  loading: Loader
});
const RemindPassword = Loadable({
  loader: () => import("./../../containers/remind-password"),
  loading: Loader
});
const SalaryCalculator = Loadable({
  loader: () => import("../../containers/Calculators/SalaryCalculator"),
  loading: Loader
});
const Search = Loadable({
  loader: () => import("../../containers/search/Search"),
  loading: Loader
});
const SignIn = Loadable({
  loader: () => import("../../containers/SignIn"),
  loading: Loader
});
const SignUp = Loadable({
  loader: () => import("../../containers/SignUp"),
  loading: Loader
});
const SiteMap = Loadable({
  loader: () => import("../../containers/SiteMap"),
  loading: Loader
});
const Survey = Loadable({
  loader: () => import("../../containers/Surveys/Survey"),
  loading: Loader
});
const Surveys = Loadable({
  loader: () => import("../../containers/Surveys/Surveys"),
  loading: Loader
});
const UserPanel = Loadable({
  loader: () => import("./../../containers/user-panel"),
  loading: Loader
});

const FileNotFound = Loadable({
  loader: () => import("../../containers/fileNotFound"),
  loading: Loader
});

const Routes = ({ location }) => {
  const {
    loader,
    startArticleId,
    userLogInEnabled,
    startMenuId,
    contextFriendlyUrl
  } = useMappedState(mapStateToProps);

  const { fromPath, homePath, redirectUrl } = useGetUrlManager(location);
  const showNoContentRoute =
    get(location, ["state", "preventNoContentPage"]) && loader.length === 0; //no content route when appropriate state and there are no loaders
  return (
    <Switch data-testid="Routes">
      {startArticleId ? (
        <Route exact path="/" component={Article} />
      ) : (
        <Route exact path="/" component={ArticleListContainer} />
      )}

      {redirectUrl && homePath !== redirectUrl && (
        //redirect po to gdy wpisujemy nazwę samej jednostki
        <Redirect exact from={fromPath} to={redirectUrl} />
      )}
      {!startArticleId && !startMenuId && (
        <Route exact path={redirectUrl} component={NoStartArticlePage} />
      )}

      <Route
        path="/(.*)?/Article/:method(get|getVersion)/id,:id.html"
        component={Article}
      />
      <Route path="/(.*,)?:method(a|v),:id,(.*)" component={Article} />
      <Route
        path="/(.*)?/Article/:method(index|getOutdated)?/id,:id.html"
        component={ArticleListContainer}
      />
      <Route
        path="/(.*,)?:method(m|o),:id,(.*)"
        component={ArticleListContainer}
      />
      <Route path="/(.*)?/Search" component={Search} />
      <Route path="/(.*,)?e,search.html" component={Search} />
      <Route path="/(.*)?/Editors" component={Editors} />
      <Route path="/(.*,)?e,editors.html" component={Editors} />
      <Route path="/(.*)?/Declarations" component={Declaration} />
      <Route path="/(.*)?/AccessibilityDeclaration" component={Declaration} />
      <Route path="/(.*,)?e,deklaracja.html" component={Declaration} />
      <Route path="/(.*)?/ContentRegistry" component={ContentRegistry} />
      <Route
        path="/(.*,)?e,contentRegistry,getList.html"
        component={ContentRegistry}
      />
      <Route path="/(.*)?/EzdCaseStatus" component={EzdCaseStatus} />
      <Route path="/(.*,)?e,ezdCaseStatus.html" component={EzdCaseStatus} />
      <Route
        path="/(.*)?/Newsletter/index(/hash,)?:hash(.*)?.html"
        component={Newsletter}
      />
      <Route path="/(.*,)?e,newsletter.html" component={Newsletter} />
      <Route path="/(.*,)?e,faqQuestion.html" component={FaqPage} />
      <Route
        path={`/(.*,)?${contextFriendlyUrl}/FaqQuestion/index.html`}
        component={FaqPage}
      />
      <Route path="/(.*)?/SiteMap" component={SiteMap} />
      <Route path="/(.*,)?e,mapa-strony.html" component={SiteMap} />
      <Route
        path="/(.*)?/Registry/(get|show)/id,:id.html"
        component={EzdRegistryContainer}
      />
      <Route
        path="/(.*,)?e,registry,(get|show).html"
        component={EzdRegistryContainer}
      />
      <Route path="/(.*)?/Registry" component={EzdRegistriesList} />
      <Route path="/(.*)?e,registry.html" component={EzdRegistriesList} />
      <Route
        path="/(.*)?/ContactForm/(get|show)/id,:id.html"
        component={ContactForm}
      />
      <Route path="/(.*,)?e,contactForm,show.html" component={ContactForm} />
      <Route path="/(.*)?/Survey/(get|show)/id,:id.html" component={Survey} />
      <Route path="/(.*,)?s,:id,(.+).html" component={Survey} />
      <Route path="/(.*)?/Survey" component={Surveys} />
      <Route
        path="/(.*)?/Calculator/alcoholSellFeeCalculator.html"
        component={AlcoholSellFeeCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,alcoholSellFeeCalculator(.*)"
        component={AlcoholSellFeeCalculator}
      />
      <Route
        path="/(.*)?/Calculator/greenAreaAndTreeRemovalCalculator.html"
        component={GreenAreaAndTreeRemovalCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,greenAreaAndTreeRemovalCalculator(.*)"
        component={GreenAreaAndTreeRemovalCalculator}
      />
      <Route
        path="/(.*)?/Calculator/occupationOfRoadwayCalculator.html"
        component={OccupationOfRoadwayCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,occupationOfRoadwayCalculator(.*)"
        component={OccupationOfRoadwayCalculator}
      />
      <Route
        path="/(.*)?/Calculator/salaryCalculator.preview"
        component={SalaryCalculator}
      />
      <Route
        path="/(.*,)?e,kalkulator,salaryCalculator(.*)"
        component={SalaryCalculator}
      />
      <Route path="/(.*)?/Calculator" component={CalculatorsList} />
      <Route path="/(.*,)?e,kalkulator(.*)" component={CalculatorsList} />
      <Route path="/(.*)?/file-not-found" component={FileNotFound} />
      <Route path="/(.*)?/404" component={NoContentPage} />
      <Route path="/(.*,)?e,ankiety.html" component={Surveys} />
      {userLogInEnabled && (
        <Switch>
          <PrivateRoute component={UserPanel} path="/(.*)?/UserPanel" />
          <PrivateRoute component={UserPanel} path="/(.*,)?e,userPanel.html" />
          <PublicRoute component={SignUp} path="/(.*)?/Login/register.html" />
          <PublicRoute component={SignUp} path="/(.*,)?e,login,register.html" />
          <PublicRoute
            component={RemindPassword}
            path="/(.*)?/Login/forgotPassword.html"
          />
          <PublicRoute
            component={RemindPassword}
            path="/(.*,)?e,login,forgotPassword.html"
          />
          <PublicRoute component={SignIn} path="/(.*)?/Login/index.html" />
          <PublicRoute component={SignIn} path="/(.*,)?e,login.html" />
          <PublicRoute component={NoContentPage} />
        </Switch>
      )}
      {showNoContentRoute && <Route component={NoContentPage} />}
    </Switch>
  );
};

Routes.propTypes = {
  homePath: PropTypes.string,
  redirectUrl: PropTypes.string,
  showNoContentRoute: PropTypes.bool,
  userLogInEnabled: PropTypes.bool,
  startArticleId: PropTypes.string
};

const mapStateToProps = ({ context, loader }) => ({
  userLogInEnabled: context.userLogInEnabled,
  startArticleId: context.startArticleId,
  startMenuId: context.startMenuId,
  loader: loader,
  contextFriendlyUrl: context.contextFriendlyUrl
});

const enhance = compose(withRouter);

export default enhance(Routes);
