import axios from "axios";
import ApiConfig from "./api.config";
import { IArticle } from "../types/IArticle";

const apiUrl = `${ApiConfig.getEntryPoint()}article-versions`;

export const getArticleVersion = (
  articleVersionId: number
): Promise<IArticle> => {
  return axios
    .get(`${apiUrl}/${articleVersionId}`)
    .then(response => response.data);
};

export const getArticleVersionXmlUrl = (versionId: number) =>
  `${apiUrl}/${versionId}/xml`;

export const getArticleVersionPdfUrl = (versionId: number) =>
  `${apiUrl}/${versionId}/pdf`;
