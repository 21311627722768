/**
 * Created by fkurzawa on 04.07.18.
 */
import styled from "styled-components";

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.text.secondaryColor};
  font-size: 1.125em;
  font-weight: bold;
  margin: 0;
  padding: 0.625rem 0;
`;

export default SectionTitle;
