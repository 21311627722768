import React from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";

const HeaderEditable = () => {
  const { headHtml, headHtmlMobile, isBifTheme } = useMappedState(
    mapStateToProps
  );
  return (
    <StyledContainer isBifTheme={isBifTheme}>
      <div
        className="d-none d-md-block"
        dangerouslySetInnerHTML={{
          __html: headHtml
        }}
      />
      <div
        className="d-md-none"
        dangerouslySetInnerHTML={{
          __html: headHtmlMobile
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  a {
    color: ${({ theme }) => theme.text.secondaryColor};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
    }
  }
  ${({ isBifTheme }) =>
    isBifTheme &&
    `
    color: #bcbcbc;
    text-transform: uppercase;
`};
`;

const mapStateToProps = ({ context }: any) => ({
  headHtml: context.headHtml,
  headHtmlMobile: context.headHtmlMobile,
  isBifTheme: context.siteTheme === "bif"
});

export { HeaderEditable };
