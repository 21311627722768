import React, { ReactNode, Suspense } from "react";

import { IRemoteData, RemoteData } from "./RemoteData";
import { Loader } from "../NewLoader";

interface IRemoteDataSuspense<T> extends IRemoteData<T> {
  fallback?: ReactNode;
}

const RemoteDataSuspense = <T extends {}>({
  fallback = <Loader size={40} />,
  ...props
}: IRemoteDataSuspense<T>) => {
  return (
    <Suspense fallback={fallback}>
      <RemoteData {...props} />
    </Suspense>
  );
};

export { RemoteDataSuspense };
