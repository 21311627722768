/**
 * Created by fkurzawa on 26.02.18.
 */
import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";
import styled from "styled-components";
import { NumberButton } from "./NumberButton";

const NumberOnPage = ({ numbersSet, currentNumber, onNumberChange, id }) => {
  return (
    <Container>
      <StyledList>
        {numbersSet.map(number => {
          return (
            <li>
              <NumberButton
                id={`${id}-number-of-items-on-page-${number}`}
                key={number}
                number={number}
                currentNumber={currentNumber}
                onNumberChange={onNumberChange}
              />
            </li>
          );
        })}
      </StyledList>
      <Text>wyników</Text>
    </Container>
  );
};

NumberOnPage.propTypes = {
  id: PropTypes.string.isRequired,
  numbersSet: PropTypes.array.isRequired,
  currentNumber: PropTypes.number,
  onNumberChange: PropTypes.func
};

NumberOnPage.defaultProps = {
  numbersSet: [10, 20, 30, 40, 50],
  onNumberChange: () => {}
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 0 0 0;
  padding-inline-start: 0;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Text = styled.span`
  color: ${({ theme }) => theme.text.primaryColor};
  text-transform: uppercase;
  font-size: 0.875em;
  margin-left: 0.5rem;
`;

const composed = compose(
  withHandlers({
    onNumberChange: ({ onNumberChange }) => event => {
      onNumberChange(Number(event.target.value));
    }
  })
);

export default composed(NumberOnPage);
