import { createBreadcrumbItem } from "../../../utils/breadcrumbs";
import { BIP_MODULES } from "../../../constants/bip_modules";

export const createSurveyBreadCrumbs = (
  setContextBreadcrumbs: (item: any[]) => void,
  friendlyUrl: string,
  urlType: string,
  name: string
) => {
  setContextBreadcrumbs([
    createBreadcrumbItem(
      BIP_MODULES.SURVEY.name,
      `${
        friendlyUrl !== "default"
          ? `${friendlyUrl}${urlType === "full" ? "/" : ","}`
          : ""
      }` +
        `${
          urlType === "full"
            ? BIP_MODULES.SURVEY.fullUrl
            : BIP_MODULES.SURVEY.friendlyUrl
        }`
    ),
    createBreadcrumbItem(name)
  ]);
};
