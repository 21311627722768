import React from "react";
import Media from "react-media";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";

import Container from "../container";
import { FooterAside } from "./components/FooterAside";
import { FooterContent } from "./components/FooterContent";
import { FooterEditable } from "./components/FooterEditable";

const Footer = () => {
  const {
    footerHtml,
    footerHtmlMobile,
    footerLinks,
    isBifTheme
  } = useMappedState(mapState);

  return (
    <FooterContainer isBifTheme={isBifTheme}>
      <Content isBifTheme={isBifTheme}>
        <StaticFooterContainer>
          <Media query="(min-width: 992px)">
            {matches =>
              matches && (
                <AsideContainer>
                  <FooterAside />
                </AsideContainer>
              )
            }
          </Media>
          <FooterContentContainer>
            <FooterContent links={footerLinks} />
          </FooterContentContainer>
        </StaticFooterContainer>
        <FooterEditable
          footerHtml={footerHtml}
          footerHtmlMobile={footerHtmlMobile}
        />
      </Content>
    </FooterContainer>
  );
};

const mapState = ({ context }: any) => ({
  footerHtml: context.footerHtml,
  footerHtmlMobile: context.footerHtmlMobile,
  footerLinks: context.footerLinks,
  isBifTheme: context.siteTheme === "bif"
});

const Content = styled(Container.withComponent("footer"))`
  @media print {
    display: none;
  }

  background: ${({ theme }) => theme.footerBackground};
  ${({ isBifTheme, theme }) =>
    !isBifTheme &&
    `
    border-top: 3px solid ${
      theme.layoutDividerColor ? theme.layoutDividerColor : theme.secondaryColor
    };
`};
`;

const FooterContainer = styled.div`
  width: 100%;
  ${({ isBifTheme, theme }) =>
    isBifTheme &&
    `
        border-top: 3px solid ${
          theme.layoutDividerColor
            ? theme.layoutDividerColor
            : theme.secondaryColor
        };
  `}
`;

const StaticFooterContainer = styled.div`
  display: flex;
`;

const AsideContainer = styled.div`
  width: 250px;
  flex-shrink: 0;
`;

const FooterContentContainer = styled.div`
  flex-grow: 1;
  background: ${({ theme }) => theme.footerContentBackground};
`;

export { Footer };
