import React, { useEffect, useRef, useState } from "react";
import { DropdownList } from "react-widgets/esm";
import { DropdownProps } from "react-widgets/DropdownList";

export interface IProps<T> extends DropdownProps<T> {
}

const AccessibilityDropdown = <T extends {}>({ ...props }: IProps<T>) => {
  const ref = useRef(null);
  const dropdownContainer = ref?.current;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  //Walidator w3c rzuca błąd przez aria-owns który odnosi się do listy która jeszcze nie istnieje, bo tworzy się
  //dopiero w momencie kliknięcia w dropdowna
  useEffect(() => {
    // @ts-ignore
    const dropdown = dropdownContainer?.getElementsByClassName(
      "rw-dropdown-list"
    )[0];
    dropdown?.removeAttribute("aria-owns");
  }, [dropdownContainer]);

  //open dropdown list
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (
        (event.key === "Enter" || event.key === "ArrowDown") &&
        !isDropDownOpen
      ) {
        setIsDropDownOpen(prevState => !prevState);
      }
    };
    // @ts-ignore
    const dropdown = dropdownContainer?.getElementsByClassName(
      "rw-dropdown-list"
    )[0];

    // @ts-ignore
    dropdown?.addEventListener("keydown", handler);

    return () => {
      dropdown?.removeEventListener("keydown", handler);
    };
  }, [isDropDownOpen, dropdownContainer]);

  //close dropdown list
  useEffect(() => {
    // @ts-ignore
    const dropdown = dropdownContainer?.getElementsByClassName(
      "rw-dropdown-list"
    )[0];

    const handler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsDropDownOpen(false);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (!dropdown?.contains(event.target)) {
        setIsDropDownOpen(false);
      }
    };

    // @ts-ignore
    dropdown?.addEventListener("keydown", handler);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      dropdown?.removeEventListener("keydown", handler);
      dropdown?.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropDownOpen, dropdownContainer]);

  return (
    <div ref={ref}>
      <DropdownList
        {...props}
        open={isDropDownOpen}
        onClick={() => {
          setIsDropDownOpen(prevState => !prevState);
        }}
        onChange={(dataItem, metadata) => {
          setIsDropDownOpen(false);
          props && props.onChange && props.onChange(dataItem, metadata);
        }}
      />
    </div>
  );
};

export { AccessibilityDropdown };
