import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import BIP_MODULES from "./../../../constants/bip_modules";

const RedirectToSignIn = ({ pathname, redirectUrl }) => (
  <Redirect
    to={{
      pathname,
      state: {
        redirectUrl
      }
    }}
  />
);

RedirectToSignIn.propTypes = {
  pathname: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string
};

const mapStateToProps = ({ context }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  urlType: context.urlType
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ friendlyUrl, urlType }) => {
    const isFullUrl = urlType === "full";
    const context =
      friendlyUrl !== "default" ? `${friendlyUrl}${isFullUrl ? "/" : ","}` : "";
    return {
      pathname: `/${context}${
        isFullUrl
          ? BIP_MODULES.SIGN_IN.fullUrl
          : BIP_MODULES.SIGN_IN.friendlyUrl
      }`
    };
  })
);

export default enhance(RedirectToSignIn);
