import axios from "axios";
import ApiConfig from "./api.config";
import { IArticlePreview } from "../types/IArticlePreview";

const apiUrl = `${ApiConfig.getEntryPoint()}article-previews`;

const getArticle = (previewHash: string): Promise<IArticlePreview> =>
  axios.get(`${apiUrl}/${previewHash}`).then(response => response.data);

export { getArticle };
