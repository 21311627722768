import React from "react";
import styled from "styled-components";

interface IProps
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  > {}

const Td = ({ children, ...restProps }: IProps) => {
  return <StyledTd {...restProps}>{children}</StyledTd>;
};

const StyledTd = styled.td`
  padding: 0.375rem 0.5rem;
  width: ${({ $width }) => `${$width ? `${$width}%` : "auto"}`};

  img {
    max-width: 100px;
    height: auto;
  }

  a {
    color: ${({ theme }) => theme.text.secondaryColor};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
    }
  }
`;

export { Td };
