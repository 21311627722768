import { find } from "lodash";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HtmlToReact from "html-to-react";

import { sanitizeHtml } from "components/SanitizeHtml";

const ColumnField = ({ columnFields, articleFields }) => {
  //Trzeba dodać stop propagation na elementach button oraz anchor tags a nie na całą kolumnę, więc będzie
  //trzeba dodać parser, który wykryje powyższe tagi i doda do nich stop propagation
  const htmlToReactParser = new HtmlToReact.Parser();
  const isValidNode = () => true;

  const processColumnNode = columnId => {
    const content = find(
      articleFields,
      articleField => articleField.fieldId === columnId
    )?.value;

    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
      React
    );

    return htmlToReactParser.parseWithInstructions(
      sanitizeHtml(content),
      isValidNode,
      [
        {
          shouldProcessNode: node => node.name && node.name === "a",
          processNode: (node, children, index) => {
            const updatedNode = {
              ...node,
              attribs: {
                ...node.attribs,
                onClick: e => e.stopPropagation()
              }
            };
            return processNodeDefinitions.processDefaultNode(
              updatedNode,
              children,
              index
            );
          }
        },
        {
          shouldProcessNode: () => true,
          processNode: processNodeDefinitions.processDefaultNode
        }
      ]
    );
  };

  return columnFields.map(columnField => (
    <Fragment key={columnField.position}>
      {processColumnNode(columnField.id)}
    </Fragment>
  ));
};

ColumnField.propTypes = {
  columnFields: PropTypes.array,
  articleFields: PropTypes.array
};

ColumnField.defaultProps = {
  columnFields: [],
  articleFields: []
};

export default ColumnField;
