import { IContactFormField } from "types/IContactForm";

const contactFormDefaultFieldValueStrategy = () => {
  return "";
};

export const createContactFormDefaultValues = (fields: IContactFormField[]) => {
  let dynamicFieldsDefaultValues: any = {};
  fields.forEach(field => {
    dynamicFieldsDefaultValues[
      field.name
    ] = contactFormDefaultFieldValueStrategy();
  });
  return {
    recaptchaResponse: null,
    terms: 0,
    ...dynamicFieldsDefaultValues
  };
};
