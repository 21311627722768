import React from "react";
import { useHistory } from "react-router-dom";
import { useMappedState } from "redux-react-hook";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import { applicationKeys } from "../../../../../../App/keys";
import { AccessibilityDropdown } from "components/Dropdown";

const AccessibilityLanguageDropdown = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { availableLanguages, currentLangCode, isContrast } = useMappedState(
    mapStateToProps
  );

  const onChangeLanguage = async (startLink: string) => {
    history.push(`/${startLink}`);
    await queryClient.removeQueries(applicationKeys.application);
  };

  if (availableLanguages.length < 2) {
    return null;
  }

  return (
    <StyledDropdown isContrast={isContrast}>
      <AccessibilityDropdown<{
        langCode: string;
        langName: string;
        startLink: string;
      }>
        filter={false}
        value={currentLangCode}
        onChange={async item => {
          await onChangeLanguage(item.startLink);
        }}
        dataKey="langCode"
        textField={item => {
          // @ts-ignore
          const [langCode] = item.langCode.split("_");
          // @ts-ignore
          return langCode.toUpperCase();
        }}
        data={availableLanguages}
        renderListItem={({ item }) => {
          const [langCode, langTitle] = item.langCode.split("_");
          return (
            <>
              <span
                className={`flag-icon flag-icon-${langTitle.toLowerCase()}`}
              />{" "}
              {langCode.toUpperCase()}
            </>
          );
        }}
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div`
  .rw-widget-input {
    width: 55px;
  }

  .rw-dropdown-list {
    :focus-within {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }

  .rw-popup-container {
    width: 130px;
  }

  .rw-popup {
    width: 100px;
    background-color: ${({ theme, isContrast }) =>
      isContrast ? theme.buttons["secondary"].background : "white"};
    color: ${({ isContrast }) => (isContrast ? "black" : "white")};
  }

  .rw-dropdown-list-input {
    padding: 0 0.4em;
  }

  .rw-picker-caret {
    width: 15px;
  }

  .rw-picker-caret,
  .rw-widget-picker {
    color: ${({ isContrast }) => (isContrast ? "black" : "white")};
  }

  .rw-widget-picker {
    min-height: 35px;
    grid-template: 1fr/1fr 1.2em;
    background-color: ${({ theme }) => theme.buttons["secondary"].background};
    box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
    border: none;
    border-radius: 0;
  }
`;

const mapStateToProps = ({ context, language, accessibility }: any) => ({
  availableLanguages: context.availableLanguages,
  currentLangCode: language.langCode,
  isContrast: accessibility.isContrast
});

export { AccessibilityLanguageDropdown };
