import React from "react";

import { useFontSize } from "utils/useFontSize";
import { FONT_SIZES } from "constants/font-sizes";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const NormalLettersButton = () => {
  const { fontSize, setFontSize } = useFontSize();
  const label = "Normalna wielkość liter";

  return (
    <Tooltip label={label} id="normal-letters-button">
      <div>
        {/*eslint-disable-next-line*/}
        <DesktopAccessibilityButton
          aria-pressed={fontSize === FONT_SIZES.DEFAULT}
          id="normal-letters-button"
          aria-label={label}
          accessKey="J"
          onClick={() => setFontSize(FONT_SIZES.DEFAULT)}
        >
          A
        </DesktopAccessibilityButton>
      </div>
    </Tooltip>
  );
};

export { NormalLettersButton };
