/**
 * Created by fkurzawa on 26.03.18.
 */
import React from "react";
import styled from "styled-components";

import { CommentsList } from "./components/CommentsList";

import { IArticleComment } from "types/IArticle";
import {CommentForm} from "./components/CommentForm";

interface IProps {
  articleId: number;
  comments: IArticleComment[];
}

const Comments = ({ articleId, comments }: IProps) => {
  return (
    <CommentsContainer>
      {comments.length > 0 && <CommentsList comments={comments} />}
      <CommentForm articleId={articleId} />
    </CommentsContainer>
  );
};

const CommentsContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export { Comments };
