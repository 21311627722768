import React from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { get } from "lodash";
import styled from "styled-components";

import { getMenu } from "api/menus";

import { useGetIsOutdated } from "./utils/useGetIsOutdated";
import { useGetLocationParams } from "./utils/useGetLocationParams";
import { useGetMenuId } from "./utils/useGetMenuId";

import { fetchMenuSucceeded, selectMenuItem } from "redux/modules/menu";
import { articlesKeys } from "modules/articles/keys";
import { getMenuArticles } from "modules/articles/api/getMenuArticles";
import { setDocumentTitle } from "redux/modules/document-title";

import { createArticleBreadCrumbs } from "./utils/createArticleBreadCrumbs";
import { getArticleId } from "./utils/getArticleId";
import { checkIfHaveNoArticles } from "./utils/checkIfHaveNoArticles";

import { RemoteData } from "components/RemoteData/RemoteData";
import { useReactQuery } from "components/RemoteData/useReactQuery";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";
import { ErrorPanel } from "components/ErrorPanel";

import { IMenuElement } from "types/IMenuElement";
import { IMenuArticlesList } from "modules/articles/types/IMenuArticlesList";
import { SortType } from "types/enums/SortType";

import { withBreadcrumbs } from "components/Breadcrumb";

import { ArticleContentContainer } from "./components/ArticleContentContainer";
import MenuChildren from "./components/menu-children/MenuChildren";
import { ArchivedButtons } from "./components/ArchivedButtons";
import { TabsContainer } from "./components/tabs/TabsContainer";
import { ArticlesTableContainer } from "./components/articles-table/ArticlesTableContainer";
import { getBackground } from "components/buttons/utils";

import { showArticlesTable } from "./utils/showArticlesTable";

export interface IParsedParams {
  limit: number;
  sort: string;
  sort_dir: SortType;
  archived: boolean;
  tab: any;
  page: number;
}

interface IProps {
  setContextBreadcrumbs: (item: any[]) => void;
}

const ArticleListContainer = ({ setContextBreadcrumbs }: IProps) => {
  const { isContrast } = useMappedState(mapStateToProps);
  const dispatch = useDispatch();
  const menuId = useGetMenuId();

  const { data } = useReactQuery<IMenuElement>(
    [articlesKeys.menuItems, menuId],
    () => getMenu(menuId),
    { retry: false, suspense: !!menuId }
  );
  const parsedParams = useGetLocationParams();
  const isOutdated = useGetIsOutdated();
  const { tab } = parsedParams;

  if (!menuId) {
    return (
      <div>
        <Breadcrumb />
        <ErrorPanel content="Brak artykułu startowego" />
      </div>
    );
  }

  dispatch(fetchMenuSucceeded(data));
  dispatch(selectMenuItem(menuId));

  return (
    <div data-testid="test-ArticlesList">
      <Breadcrumb />
      <RemoteData<IMenuArticlesList>
        queryKey={[articlesKeys.allArticles, menuId, tab, isOutdated]}
        fetcher={() =>
          getMenuArticles(menuId, 10, 0, null, null, Number(isOutdated), tab)
        }
        errorBoundaryProps={{
          resetKeys: [menuId]
        }}
      >
        {allArticles => {
          const menuTitle = get(allArticles, "menuPath[0].name");
          setContextBreadcrumbs(createArticleBreadCrumbs(allArticles) || []);
          dispatch(setDocumentTitle(menuTitle));

          const articleId = getArticleId(allArticles);
          const haveNoArticles = checkIfHaveNoArticles(allArticles);
          return (
            <main tabIndex={-1}>
              {articleId ? (
                <ArticleContentContainer
                  articleId={articleId}
                  menuId={menuId}
                />
              ) : (
                <StyledH1Title isContrast={isContrast}>
                  {menuTitle}
                </StyledH1Title>
              )}
              {haveNoArticles && !allArticles.hasArchived ? (
                allArticles.recursiveMenuDisplayEnabled ? (
                  <ErrorPanel header="Brak artykułów" />
                ) : (
                  <MenuChildren
                    menuShowOrderedListEnabled={
                      allArticles.menuShowOrderedListEnabled
                    }
                  />
                )
              ) : (
                <>
                  {allArticles.hasArchived && (
                    <ArchivedButtons menuArticles={allArticles} />
                  )}
                  {allArticles.tabs?.length > 0 && (
                    <TabsContainer
                      tabs={allArticles.tabs}
                      showArticlesTable={allArticles.articles.length > 0}
                    />
                  )}
                  {showArticlesTable(
                    allArticles.total,
                    allArticles.mainArticleId,
                    allArticles.tabs.length
                  ) && <ArticlesTableContainer allArticles={allArticles} />}
                </>
              )}
            </main>
          );
        }}
      </RemoteData>
    </div>
  );
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const StyledH1Title = styled.h1`
  color: ${({ isContrast }) => (isContrast ? getBackground : "#37474f")};
  font-size: 1.75em;
  font-weight: bold;
  padding: 0.625rem 0;
  border-bottom: 1px solid #bdbdbd;
`;

export default withBreadcrumbs(ArticleListContainer);
