import React from "react";
import styled from "styled-components";
import {
  getFontSize,
  getPadding,
  getBoxShadow,
  getColor,
  getBackground,
  getHoverBackground,
  getHoverBorderColor
} from "./utils";
import { Tooltip } from "../Tooltip";
import { useMappedState } from "redux-react-hook";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  color?:
    | "primary"
    | "secondary"
    | "default"
    | "strongPrimary"
    | "strongSecondary";

  size?: "small" | "medium" | "large";
  "data-flat"?: "true" | "false";
}

const Button = ({ children, title, id, ...restProps }: IProps) => {
  const { contrast } = useMappedState(mapStateToProps);

  return (
    <Tooltip label={title} id={`${id}-tooltip`}>
      <StyledButton {...restProps} data-contrast={contrast} id={id}>
        {children}
      </StyledButton>
    </Tooltip>
  );
};

export const StyledButton = styled.button`
  && {
    text-align: center;
    font-size: ${getFontSize};
    padding: ${getPadding};
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 1px;
    box-shadow: ${getBoxShadow};
    color: ${getColor};
    background: ${getBackground};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover:not(:disabled) {
      background: ${getHoverBackground};
      border-color: ${getHoverBorderColor};
    }

    &:active:not(:disabled) {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }

    &:focus-within:not(:active) {
      outline: ${({ theme }) => theme.focusOutline}
    }

    &:-moz-focusring {
      outline: ${({ theme }) => theme.focusOutline}
    }
  }
`;

const mapStateToProps = ({ accessibility }: any) => ({
  contrast: accessibility.isContrast === true ? "true" : "false"
});

export { Button };
