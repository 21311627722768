import React from "react";

import { VideoPlayer } from "../../../videoPlayer";
import MK_MODULES from "./mk-modules.const";

export const replaceDataModuleVideoStreamInstructions = {
  replaceChildren: true,
  shouldProcessNode: node =>
    node.attribs && node.attribs["data-module"] === MK_MODULES.VIDEO_STREAM,
  processNode: node => <VideoPlayer url={node.attribs["data-url"]} />
};
