import React from "react";

import { ContentColumn } from "./ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";
import { Row } from "../../TableComponents/MetricGrid";
import { Translate } from "../../../translate";
import { HeadColumn } from "./HeadColumn";

interface IProps {
  createInformationDate: string;
}

const CreateInformationDate = ({ createInformationDate }: IProps) => {
  return (
    <Row>
      <HeadColumn>
        <Translate>Create real information date</Translate>
      </HeadColumn>
      <ContentColumn>
        <SpanWithTranslation>{createInformationDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { CreateInformationDate };
