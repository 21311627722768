import axios from "axios";

import ApiConfig from "../../../api/api.config";

const apiUrl = `${ApiConfig.getEntryPoint()}contexts`;

export const postSurvey = (
  friendlyUrl: string,
  surveyId: number,
  formData: any
) => {
  return axios.post(
    `${apiUrl}/${friendlyUrl}/surveys/${surveyId}/completed`,
    formData
  );
};
