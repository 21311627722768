/**
 * Created by fkurzawa on 05.07.18.
 */
import styled from "styled-components";

const ModalFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.dividerColor};
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 1rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export default ModalFooter;
