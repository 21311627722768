import React from "react";
import styled from "styled-components";

import { Tools } from "./components/tools/Tools";

const Toolbar = () => (
  <StickyContainer>
    <ToolbarContainer id="top-bar">
      <Content>
        <StyledTools>
          <Tools />
        </StyledTools>
      </Content>
    </ToolbarContainer>
  </StickyContainer>
);

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const StyledTools = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  background: ${({ theme }) =>
    theme.toolbarBackground || theme.lightPrimaryColor};
`;

const ToolbarContainer = styled.div`
  align-items: center;
  display: flex;

  @media print {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: auto;
  justify-content: space-between;
  flex-direction: column;

  &:not(:last-child) {
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }

    @media screen and (min-width: 768px) {
      margin-right: 1rem;
    }
  }
`;

export { Toolbar };
