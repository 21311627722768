import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Markers = ({ markers, openedMarkerId, onMarkerClick }) => {
  const selectMarker = (event, markerId) => {
    if (event.type === "click" || event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onMarkerClick(markerId);
    }
  };

  return (
    <React.Fragment>
      {false && <Header>Lista podmiotów:</Header>}
      <MarkersList aria-label={"lista podmiotów"}>
        {markers.map(marker => (
          <Marker
            key={marker.id}
            role="button"
            tabIndex={0}
            isActive={openedMarkerId === marker.id}
            onClick={event => selectMarker(event, marker.id)}
            onKeyPress={event => selectMarker(event, marker.id)}
          >
            <MarkerName>{marker.name}</MarkerName>
            <MarkerAddress>{marker.address}</MarkerAddress>
          </Marker>
        ))}
      </MarkersList>
    </React.Fragment>
  );
};

Markers.propTypes = {
  markers: PropTypes.array,
  openedMarkerId: PropTypes.number,
  onMarkerClick: PropTypes.func
};

Markers.defaultProps = {
  markers: [],
  onMarkerClick: () => {}
};

const Header = styled.h4`
  font-size: 1em;
  font-weight: bold;
  color: ${({ theme }) => theme.text.primaryColor};
`;

const MarkersList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
`;

const Marker = styled.li`
  font-size: 0.875em;
  color: ${({ theme }) => theme.text.primaryColor};
  padding: 0.625rem 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  border-right: ${({ theme, isActive }) =>
    isActive ? `6px solid ${theme.secondaryColor}` : "none"};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.secondaryColor};
    border-bottom-color: ${({ theme }) => theme.secondaryColor};
  }
`;

const MarkerName = styled.span`
  font-weight: bold;
`;

const MarkerAddress = styled.address`
  margin: 0;
`;

export default Markers;
