/**
 * Created by fkurzawa on 25.01.18.
 */
import { createStore } from "redux";
import reducer from "./reducer";
import enhancer from "./enhancer";

const store = createStore(reducer, enhancer);

export default store;
