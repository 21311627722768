import React from "react";
import MdiIcon from "@mdi/react";
import styled, { css } from "styled-components";
import { useMappedState } from "redux-react-hook";

import { getBackground, getColor, getHoverBackground } from "../buttons/utils";

export interface IIcon {
  path: string;
  title?: string;
  hoverAllowed?: boolean;
}

const Icon = ({ path, title, hoverAllowed = false }: IIcon) => {
  const { isContrast } = useMappedState(mapStateToProps);

  return (
    <StyledMdiIcon
      title={title}
      path={path}
      size="20px"
      isContrast={isContrast}
      hoverAllowed={hoverAllowed}
      aria-labelledby={null}
    />
  );
};

const StyledMdiIcon = styled(MdiIcon)`
  ${({ hoverAllowed }) =>
    hoverAllowed &&
    css`
      cursor: pointer;
      &:hover:not(:disabled) {
        background: ${getHoverBackground};
      }

      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      border: 1px solid transparent;
      &:active:not(:disabled) {
        opacity: 0.6;
      }

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }

      &:focus-within:not(:active) {
        outline: ${({ theme }) => theme.focusOutline};
      }
    `};

  background: ${({ isContrast }) =>
    isContrast ? getBackground : "transparent"};

  text-align: center;
  white-space: nowrap;

  text-transform: uppercase;

  color: ${getColor};
`;

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

export { Icon };
