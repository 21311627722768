/**
 * Created by fkurzawa on 15.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { UserTip } from "./components/UserTip";

const User = ({ user, id }) => {
  const tooltipId = `tip-${user.id}`;
  const { isBifTheme, isContrast } = useMappedState(mapStateToProps);

  return (
    <UserTip
      id={tooltipId}
      contact={user.contact}
      description={user.description}
      email={user.email}
      phone={user.phone}
    >
      <UserName isBifTheme={isBifTheme} isContrast={isContrast} id={id}>
        {user.name}
      </UserName>
    </UserTip>
  );
};

const mapStateToProps = ({ accessibility, context }) => ({
  isContrast: accessibility.isContrast,
  isBifTheme: context.siteTheme === "bif"
});

User.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.string
};

const UserName = styled.div`
  display: inline-flex;
  cursor: default;
  color: ${({ theme, isBifTheme, isContrast }) =>
    isBifTheme
      ? isContrast
        ? theme.text.primaryColor
        : "#ff8f00"
      : theme.text.primaryColor};
`;

export { User };
