import React from "react";

import { Row } from "../../TableComponents/MetricGrid";
import { ContentColumn } from "./ContentColumn";
import { SpanWithTranslation } from "./SpanWithTranslation";
import {Translate} from "../../../translate";
import {HeadColumn} from "./HeadColumn";

interface IProps {
  createDate: string;
}

const CreationDate = ({ createDate }: IProps) => {
  return (
    <Row>
      <HeadColumn>
        <Translate>Time of generating</Translate>
      </HeadColumn>
      <ContentColumn>
        <SpanWithTranslation>{createDate}</SpanWithTranslation>
      </ContentColumn>
    </Row>
  );
};

export { CreationDate };
