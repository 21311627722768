import React from "react";
import styled from "styled-components";

import { Dropdown } from "../../../../Dropdown";
import { NormalLettersButton } from "./Buttons/NormalLettersButton";
import { MediumLettersButton } from "./Buttons/MediumLettersButton";
import { BigLettersButton } from "./Buttons/BigLettersButton";
import { ParagraphSpacingButton } from "./Buttons/ParagraphSpacingButton";
import { LinesSpacingButton } from "./Buttons/LinesSpacingButton";
import { WordSpacingButton } from "./Buttons/WordSpacingButton";
import { LettersSpacingButton } from "./Buttons/LettersSpacingButton";
import { ContrastButton } from "./Buttons/ContrastButton";
import {DropdownButton} from "./Buttons/DropdownButton";

const MobileAccessibilityMenu = () => {
  return (
    <Dropdown
      button={
        <DropdownButton
          id="mobileAccessibilityMenu-dropdown"
          title="Wersja dla niedowidzących"
        >
          <span aria-hidden={true} className="fa fa-eye" /> <span aria-hidden={true} className="fa fa-caret-down" />
        </DropdownButton>
      }
    >
      <Container>
        <Section>
          <ContrastButton />
          <NormalLettersButton />
          <MediumLettersButton />
          <BigLettersButton />
        </Section>
        <Section>
          <ParagraphSpacingButton />
          <LinesSpacingButton />
          <WordSpacingButton />
          <LettersSpacingButton />
        </Section>
      </Container>
    </Dropdown>
  );
};

const Container = styled.div`
  padding: 0.75rem 1rem;
`;

const Section = styled.section`
  display: flex;
  justify-content: space-around;

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
    margin-bottom: 1rem;
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export { MobileAccessibilityMenu };
