import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { EmblemImage } from "./EmblemImage";

const Emblem = () => {
  const { url } = useMappedState(mapStateToProps);
  return (
    <div id="bip-emblem">
      {url ? (
        <Link href={url} target="_blank" rel="noopener noreferrer">
          <EmblemImage />
        </Link>
      ) : (
        <EmblemImage />
      )}
    </div>
  );
};

const Link = styled.a`
  display: block;
  color: ${({ theme }) => theme.text.primaryColor};
  &:hover {
    color: ${({ theme }) => theme.text.secondaryColor};
  }
  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

const mapStateToProps = ({ context }: any) => ({
  url: context.logoHref
});

export { Emblem };
