import React from "react";

import { Row } from "../../TableComponents/MetricGrid";
import { User } from "../../TableComponents/User";

import { IUser } from "types/IUser";
import { HeadColumn } from "./HeadColumn";
import { Translate } from "../../../translate";
import { ContentColumn } from "./ContentColumn";

interface IProps {
  createUser: IUser;
}

const ProducerOfInformation = ({ createUser }: IProps) => {
  return (
    <Row>
      <HeadColumn>
        <Translate>The person who produced the information</Translate>
      </HeadColumn>
      <ContentColumn>
        <User user={createUser} />
      </ContentColumn>
    </Row>
  );
};

export { ProducerOfInformation };
