import React, { useState } from "react";

import { IArticleRegistries } from "modules/contentRegistry/types/IArticleRegistries";
import { articlesKeys } from "modules/articles/keys";

import { getArticleRegistries } from "api/articles";

import { getOffsetFromPage, getPageFromOffset } from "utils";

import Pagination from "../../pagination";
import { Table } from "./components/Table";
import { Panel } from "../../Panel";
import { RemoteData } from "../../RemoteData/RemoteData";

interface IProps {
  articleId: number;
}

const Registries = ({ articleId }: IProps) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const handleLimitChange = (limit: number) => {
    setLimit(limit);
    setOffset(0);
  };

  const handlePageChange = (page: number) => {
    setOffset(getOffsetFromPage(page, limit));
  };

  return (
    <RemoteData<IArticleRegistries>
      fetcher={() => getArticleRegistries(articleId, limit, offset)}
      queryKey={[articlesKeys.articleRegistries, limit, offset, articleId]}
    >
      {({ elements, total, limit, offset, showArticleEditReason }) => {
        if (total === 0) {
          return <></>;
        }
        setLimit(limit);
        setOffset(offset);
        const totalPages = Math.ceil(total / limit);
        const showPagination = total > 10;

        return (
          <Panel id="history" title="History of changes" withCollapse>
            <Table
              items={elements}
              showArticleEditReason={showArticleEditReason}
            />
            {showPagination && (
              <Pagination
                id="article-registries"
                showNumberOnPage
                currentNumber={limit}
                onNumberChange={handleLimitChange}
                current={getPageFromOffset(offset, limit)}
                total={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </Panel>
        );
      }}
    </RemoteData>
  );
};

export { Registries };
