import React from "react";
import { useFormContext } from "react-hook-form";

import { CheckBoxFieldPrototype } from "./CheckBoxFieldPrototype";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  isRequired?: boolean;
  label?: string;
}

const CheckBoxField = ({ ...props }: IProps) => {
  const { setValue } = useFormContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Number(e.target.value);
    const newValue = currentValue === 0 ? 1 : 0;
    setValue(props.name, newValue, { shouldValidate: true });
  };

  return <CheckBoxFieldPrototype handleChange={handleChange} {...props} />;
};

export { CheckBoxField };
