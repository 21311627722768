import React from "react";
import styled, {css} from "styled-components";

export const processTable = {
  shouldProcessNode: node =>
      node.name &&
      node.name === "table" &&
      node.attribs &&
      node.attribs["border"] !== undefined,
  processNode: function(node, children, index) {
    const formatStringToCamelCase = str => {
      const splitted = str.split("-");
      if (splitted.length === 1) return splitted[0];
      return (
          splitted[0] +
          splitted
              .slice(1)
              .map(word => word[0].toUpperCase() + word.slice(1))
              .join("")
      );
    };

    const getStyleObjectFromString = str => {
      const style = {};
      str.split(";").forEach(el => {
        const [property, value] = el.split(":");
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
      });

      return style;
    };

    const borderSize = node.attribs.border;
    const tableStyle = node.attribs.style ? `${node.attribs.style}; border: solid ${borderSize}px` : `border: solid ${borderSize}px`;
    const { width, height, border, cellspacing, cellpadding, ...restAttribs } = node.attribs;

    return (
        <StyledTable
            {...restAttribs}
            style={getStyleObjectFromString(tableStyle)}
            borderSize={borderSize}
            cellSpacing={cellspacing}
            cellPadding={cellpadding}
        >
          {children}
        </StyledTable>
    );
  }
};
const StyledTable = styled.table.attrs(props => props) `
  ${props => props.bgcolor && css`background: ${props.bgcolor};`}
  ${props =>
    ('left' === props.align && css`margin-left: 0; margin-right: auto;`) ||
    ('right' === props.align && css`margin-left: auto; margin-right: 0;`) ||
    ('center' === props.align  && css`margin-left: auto; margin-right: auto;`)
}
  && td, th {
    border: ${({ borderSize }) => `solid ${borderSize}px`};
  }
`;
