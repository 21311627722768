import useLocalStorage from "./useLocalStorage";
import { FONT_SIZES } from "../constants/font-sizes";

export const useFontSize = () => {
  const [fontSizeLocalStorage, setFontSizeLocalStorage] = useLocalStorage<
    FONT_SIZES
  >("fontSize", FONT_SIZES.DEFAULT);

  const initFontSizeInDocument = () => {
    setDocumentFontClass(fontSizeLocalStorage);
  };

  const setDocumentFontClass = (fontSize: FONT_SIZES) => {
    document?.querySelector("#root")?.classList.add(`size-${fontSize}`);
  };

  const setFontSize = (fontSize: FONT_SIZES) => {
    clearFontSizes();
    setFontSizeLocalStorage(fontSize);
    setDocumentFontClass(fontSize);
  };

  function clearFontSizes() {
    const classList = document?.querySelector("#root")?.classList;
    classList &&
      Array.from(classList)
        .filter(className => /size-.+/.test(className))
        .forEach(className => {
          classList?.remove(className);
        });
  }

  return {
    setFontSize,
    fontSize: fontSizeLocalStorage,
    initFontSizeInDocument
  };
};
