/**
 * Created by fkurzawa on 27.03.18.
 */
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > input[type="checkbox"] {
    margin-right: 6px;
  }
`;

export default CheckboxContainer;
