import React from "react";

import { useAccessibilityClass } from "utils/index";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const WordSpacingButton = () => {
  const {
    isAccessibilityClassActive,
    toggleAccessibilityClass
  } = useAccessibilityClass("wordsSpacing", "words-spacing");
  const label = "Odstęp między słowami";
  return (
    <Tooltip label={label} id="word-spacing-button">
      <DesktopAccessibilityButton
        aria-pressed={isAccessibilityClassActive}
        id="word-spacing-button"
        aria-label={label}
        onClick={toggleAccessibilityClass}
      >
        <span aria-hidden={true} className="fa fa-arrows-h" />{" "}
        <span aria-hidden={true} className="fa fa-align-left" />
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

export { WordSpacingButton };
