import React, { ReactNode, useEffect, useState } from "react";

import { Button } from "components/buttons";

interface IProps {
  fileUrl: string;
  flat?: "true" | "false";
  id?: ReactNode;
}

export const CopyPathButton = ({ fileUrl, flat, id }: IProps) => {
  const [copied, setCopied] = useState<boolean>();

  const timeoutAction = () => setTimeout(() => setCopied(false), 3000);

  const handleCopyPath = () => {
    navigator?.clipboard?.writeText && navigator.clipboard.writeText(fileUrl);
    // IE
    let ieWindow = window as any;
    if (ieWindow.clipboardData) ieWindow.clipboardData.setData("Text", fileUrl);

    setCopied(true);
  };

  useEffect(() => {
    const timerId = timeoutAction();
    return () => clearTimeout(timerId);
  }, [copied]);

  return (
    <Button
      id={`copy-to-clipboard-button-${id}`}
      type="button"
      title={copied ? "Skopiowano link do pliku!" : "Skopiuj link do pliku"}
      aria-label={
        copied ? "Skopiowano link do pliku!" : "Skopiuj link do pliku"
      }
      size="small"
      onClick={handleCopyPath}
      data-flat={flat}
    >
      <span
        aria-hidden
        className={`fa ${copied ? "fa-check" : "fa-clipboard"}`}
      />
    </Button>
  );
};
