import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { ISurveyQuestion } from "modules/survey/types/ISurveyQuestion";

import { validationMessages } from "utils/yup/messages";

import { createCheckboxFieldName } from "./utils/createCheckboxFieldName";

import {
  TextField,
  FieldPrototype,
  ConditionalField
} from "components/HookForm";

import { Option } from "./Option";
import { SurveyCheckBoxField } from "./SurveyCheckbox";

interface IProps {
  name: string;
  questionField: ISurveyQuestion;
}

const MultipleChoiceField = ({ name, questionField }: IProps) => {
  const { watch, setError, clearErrors, getValues } = useFormContext();
  const label = questionField.name;
  const checkboxFieldName = createCheckboxFieldName(name, questionField.type);
  const withOpenField = questionField.isOpen === 1;
  const required = questionField.isRequired === 1;

  const someFieldChecked = useCallback(() => {
    const mappedNames = questionField.fields?.map(
      (field, index) => `${checkboxFieldName}_${index}`
    );
    return Object.values(getValues(mappedNames || [])).find(value => {
      return value;
    });
  }, [checkboxFieldName, getValues, questionField.fields]);

  const checkValidation = () => {
    if (someFieldChecked()) {
      clearErrors(name);
    } else {
      setError(name, errorMessage);
    }
  };

  const handleFieldChange = () => {
    required && checkValidation();
  };

  return (
    <FieldPrototype isRequired={required} label={label} name={name}>
      {() => {
        const showOwnAnswerField =
          watch(`${checkboxFieldName}_${questionField.fields?.length}`) ===
          "ownAnswer";
        return (
          <>
            {questionField.fields?.map((field, index) => (
              <Option
                key={index}
                checkboxFieldName={`${checkboxFieldName}_${index}`}
                commentFieldName={`${name}_${field.id}_ownAnswer`}
                checkboxLabel={field.name}
                optionHasComment={field.hasComment}
                value={field.id.toString()}
                customChange={handleFieldChange}
              />
            ))}

            {withOpenField && (
              <>
                <SurveyCheckBoxField
                  isRequired={false}
                  name={`${checkboxFieldName}_${questionField.fields?.length}`}
                  id={`${checkboxFieldName}_${questionField.fields?.length}`}
                  label="Podaj własną odpowiedź"
                  checkedValue="ownAnswer"
                />
                <ConditionalField
                  name={`${name}_ownAnswer`}
                  showChildren={showOwnAnswerField}
                >
                  <TextField
                    isRequired={false}
                    name={`${name}_ownAnswer`}
                    id={`${name}_ownAnswer`}
                    label="Własna odpowiedź"
                  />
                </ConditionalField>
              </>
            )}
          </>
        );
      }}
    </FieldPrototype>
  );
};

const errorMessage = {
  type: "manual",
  message: validationMessages.requiredField
};

export { MultipleChoiceField };
