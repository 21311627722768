/**
 * Created by fkurzawa on 22.03.18.
 */
import styled from "styled-components";

const Grid = styled.dl.attrs({
  className: "container-fluid"
})`
  font-size: 0.75em;
  color: ${({ theme }) => theme.text.secondaryColor};
`;

export { Grid };
