import React from "react";
import styled from "styled-components";

const Stamp = ({ children }) => {
  return (
    <StampContainer>
      <StampContent>{children}</StampContent>
    </StampContainer>
  );
};

const StampContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StampContent = styled.div`
  border: 4px solid ${({ theme }) => theme.errorColor};
  color: ${({ theme }) => theme.errorColor};
  border-radius: 5px;
  font-size: 1.5em;
  font-weight: bold;
  padding: 5px 10px;
  display: inline-block;
  margin: 15px 10px 15px 15px;
  text-align: center;
  transform: rotate(15deg);
  vertical-align: middle;
  word-wrap: break-word;
  width: 170px;
`;

export { Stamp };
