import * as yup from "yup";

import { IDynamicFieldSchema } from "utils/yup/IDynamicFieldSchema";

export const formFieldsGeneratorSchemaAdapter = <T>(
  fields: T[] | null,
  schemaStrategy: (formField: T) => IDynamicFieldSchema
) => {
  let dynamicFieldsSchema: IDynamicFieldSchema = {};
  fields?.forEach(field => {
    const value = schemaStrategy(field);
    Object.keys(value).forEach(key => {
      dynamicFieldsSchema[key] = value[key];
    });
  });

  return yup.object().shape(dynamicFieldsSchema);
};
