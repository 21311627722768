import React from "react";
import styled from "styled-components";

import {
  Form,
  SpinnerButton,
  FormFieldsGenerator,
  formFieldsGeneratorSchemaAdapter,
  ResetDynamicFormButton,
  FormFieldType,
  IFormField
} from "components/HookForm";

import { advancedSearchFormSchemaStrategy } from "./advancedSearchFormSchemaStrategy";

interface IProps {
  fields: IFormField[];
  onSubmit: () => void;
}

const AdvancedSearchForm = ({ fields, onSubmit }: IProps) => {
  const createDefaultValues = () => {
    let dynamicDefaultValues: any = {};

    fields.forEach(field => {
      if (field.type === FormFieldType.DATE_RANGE) {
        dynamicDefaultValues[`${field.name}-from`] = field.value.from;
        dynamicDefaultValues[`${field.name}-to`] = field.value.to;
        return;
      }
      if (field.type === FormFieldType.NUMBER_RANGE) {
        dynamicDefaultValues[`${field.name}-from`] = field.value.from;
        dynamicDefaultValues[`${field.name}-to`] = field.value.to;
        return;
      }
      dynamicDefaultValues[field.name] = field.value;
    });
    return dynamicDefaultValues;
  };

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={createDefaultValues()}
      resetOnSubmit={false}
      validationSchema={formFieldsGeneratorSchemaAdapter(
        fields,
        advancedSearchFormSchemaStrategy
      )}
    >
      {() => {
        return (
          <>
            {fields.map(field => (
              <FormFieldsGenerator key={field.id} field={field} />
            ))}
            <Buttons>
              <SpinnerButton
                label="Szukaj"
                id="advanced-search-form-submit-button"
              />
              <ResetDynamicFormButton
                id="advanced-search-form-reset-button"
                fields={fields}
              />
            </Buttons>
          </>
        );
      }}
    </Form>
  );
};

const Buttons = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export { AdvancedSearchForm };
