import React from "react";

import { PageTitle } from "components/typography/index";
import Translate from "components/translate/Translate";
import { Alert, Notification } from "components/Alert";

import { CompletedText } from "./CompletedText";

interface IProps {
  surveyName: string;
  completedCount: number;
  isDeprecated: boolean;
}

const SurveyHeader = ({ completedCount, isDeprecated, surveyName }: IProps) => {
  return (
    <>
      <PageTitle>{surveyName}</PageTitle>
      <CompletedText>
        <Translate>Filled</Translate>: {completedCount}
      </CompletedText>
      {isDeprecated && (
        <Alert type={Notification.ERROR}>Ankieta nieaktualna</Alert>
      )}
    </>
  );
};

export { SurveyHeader };
