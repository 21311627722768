import React from "react";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";
import { useMappedState } from "redux-react-hook";

const DeafArticleButton = () => {
  const label = "Informacja dla osób niesłyszących";
  const {
    deafArticleUrl,
  } = useMappedState(mapStateToProps);

  return (
    deafArticleUrl &&
    <Tooltip label={label} id="deaf-article-button">
      <DesktopAccessibilityButton
        aria-pressed="false"
        id="deaf-article-button"
        aria-label={label}
        onClick={() => { window.location.replace('/'+deafArticleUrl) }}
      >
        <span className="fa fa-deaf" />
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

const mapStateToProps = ({ context }: any) => ({
  deafArticleUrl: context.deafArticleUrl,
});

export { DeafArticleButton };
