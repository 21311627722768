import React from "react";
import styled from "styled-components";
import queryString from "query-string";
import { useMappedState } from "redux-react-hook";
import useRouter from "use-react-router";
import shallow from "zustand/shallow";

import { searchBarValidationSchema } from "modules/articles/schemas/searchBarValidationSchema";
import { useSearchValueStore } from "../../../../../../containers/search/utils/useSearchValueStore";

import { Form } from "components/HookForm";

import { SuggestionInputField } from "./components/SuggestionInputField";
import { Button } from "components/buttons";

const SearchBar = () => {
  const { searchUrl } = useMappedState(mapStateToProps);
  const { history } = useRouter();

  const searchStore = useSearchValueStore();
  const { setStoreSearchValue, storeSearchValue } = searchStore(
    state => ({
      setStoreSearchValue: state.setSearchValue,
      storeSearchValue: state.searchValue
    }),
    shallow
  );

  const onSearch = (model: { s: string }) => {
    setStoreSearchValue(model.s);
    history.push(`/${searchUrl}?${queryString.stringify(model)}`);
  };

  return (
    <StyledForm>
      <Form<{ s: string }>
        resetOnSubmit={false}
        defaultValues={{ s: storeSearchValue }}
        onSubmit={onSearch}
        validationSchema={searchBarValidationSchema}
      >
        {() => {
          return (
            <SearchFieldGroup>
              <SuggestionInputField onSearch={onSearch} />
              <Button
                id="main-search-bar-button"
                type="submit"
                data-color="primary"
                aria-label="Szukaj"
                title="Szukaj"
              >
                <span aria-hidden={true} className="fa fa-search" />
              </Button>
            </SearchFieldGroup>
          );
        }}
      </Form>
    </StyledForm>
  );
};

const SearchFieldGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

const StyledForm = styled.div`
  width: 100%;
  margin-top: 15px;

  @media screen and (min-width: 768px) {
    width: 50%;
    margin-top: initial;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
  }

  @media screen and (min-width: 1200px) {
    width: 30%;
  }
`;

const mapStateToProps = ({ context }: any) => ({
  searchUrl: context.searchUrl
});

export { SearchBar };
