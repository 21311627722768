import SEARCH_URLS from "../../constants/search_urls";
import {IContext} from "../../types/IContext";

export const createSearchUrl = (
  urlType: string,
  langCode: string,
  friendlyUrl: string | IContext = "default"
) => {
  const separator = urlType === "full" ? "/" : ",";
  const isNotDefaultContext = friendlyUrl !== "default";
  const context = `${isNotDefaultContext ? friendlyUrl : ""}`;
  const lang = /pl_\w+/.test(langCode)
    ? ""
    : `${isNotDefaultContext ? separator : ""}${
        urlType === "full" ? langCode : langCode.split("_")[0]
      }`;
  const hasContextOrLang = context !== "" || lang !== "";
  return `${context + lang}${hasContextOrLang ? separator : ""}${
    urlType === "friendly" ? SEARCH_URLS.FRIENDLY : SEARCH_URLS.FULL
  }`;
};
