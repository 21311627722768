import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { useMappedState } from "redux-react-hook";

import { articlesKeys } from "modules/articles/keys";
import { getSearchSuggestion } from "../../../../../../../api/search-suggestion";
import { useSearchValueStore } from "../../../../../../../containers/search/utils/useSearchValueStore";

import { Combobox } from "components/Combobox";
import { useReactQuery } from "components/RemoteData/useReactQuery";
import { StyledErrorMessage } from "components/HookForm";

import { ISuggestion } from "../../../../../../../types/ISuggestion";
import { getBackground } from "../../../../../../buttons/utils";

interface IProps {
  onSearch: (model: { s: string }) => void;
}

const SuggestionInputField = ({ onSearch }: IProps) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const { isContrast } = useMappedState(mapStateToProps);
  const ref = useRef(null);
  const inputContainer = ref?.current;

  const searchStore = useSearchValueStore();
  const storeSearchValue = searchStore(state => state.searchValue);

  const {
    errors,
    formState: { isSubmitting },
    setValue,
    getValues
  } = useFormContext();
  const searchFieldValue = getValues("s");

  const { data } = useReactQuery<ISuggestion>(
    [articlesKeys.menuItems, getValues("s")],
    () => getSearchSuggestion(searchFieldValue),
    { suspense: false }
  );

  useEffect(() => {
    setValue("s", storeSearchValue || "");
  }, [storeSearchValue, setValue]);

  //Po to aby była obsługa entera w samym inpucie i po enterze żeby leciał request
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        //wzięcie najaktualniejszej wartości ze względu na to, że stara wartość jest w searchFieldValue
        onSearch({ s: getValues("s") });
      }
    };
    // @ts-ignore
    const input = inputContainer?.getElementsByClassName(
      "rw-combobox-input"
    )[0];

    // @ts-ignore
    input?.addEventListener("keydown", handler);

    return () => {
      input?.removeEventListener("keydown", handler);
    };
  }, [searchFieldValue, getValues, inputContainer, onSearch]);

  return (
    <InputContainer id="suggestion-container" contrast={isContrast} ref={ref}>
      <Controller
        name="s"
        render={props => {
          return (
            // eslint-disable-next-line
            <Combobox
              id="search-suggestion-input-container"
              aria-activedescendant={undefined}
              role="search"
              onToggle={isOpen => {
                setIsListOpen(isOpen);
              }}
              aria-expanded={isListOpen}
              placeholder="Szukaj..."
              autoSelectMatches={true}
              hideCaret
              hideEmptyPopup={true}
              messages={{ emptyList: "Brak wyników..." }}
              accessKey="H"
              readOnly={isSubmitting}
              data={data?.elements}
              onSelect={dataItem => {
                //po to aby po wybraniu opcji i kliknięciu enter od razu wyszukiwało
                //aczkolwiek po tym na chwilę w wyszukiwarce pojawia się wartość z inputa a dopiero potem
                //pojawia się wartość wybrana z selecta
                onSearch({ s: dataItem });
              }}
              {...props}
            />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={"s"}
        render={({ message }) => {
          return (
            <StyledErrorMessage role="alert" style={{ position: "absolute" }}>
              {message}
            </StyledErrorMessage>
          );
        }}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  width: 100%;

  .rw-combobox {
    :focus-within {
      outline: ${({ theme }) => theme.focusOutline};
    }
  }

  .rw-widget-input {
    background-color: ${({ contrast }) => contrast && "transparent"};
    border-color: ${({ contrast }) => contrast && getBackground};
    color: ${({ contrast }) => contrast && getBackground};
  }

  & > input {
    display: block;
    color: ${({ theme }) => theme.text.primaryColor};
    border: 1px solid ${({ theme }) => theme.dividerColor};
    border-radius: 3px 0 0 3px;
    font-size: 1em;
    padding: 0.25rem 0.5rem;
    height: 24px;

    &::-webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
      cursor: pointer;
      padding: 0.125em;
    }
  }
`;

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

export { SuggestionInputField };
