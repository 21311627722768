/**
 * Created by fkurzawa on 08.03.18.
 */

export const parseFormModelToRestParams = formModel => {
  var restParams = {};
  for (let modelField in formModel) {
    if (Array.isArray(formModel[modelField])) {
      restParams = {
        ...restParams,
        ...parseFormModelArrayFieldToRestParams(
          modelField,
          formModel[modelField]
        )
      };
    } else if (formModel[modelField] instanceof Object) {
      restParams = {
        ...restParams,
        ...parseFormModelObjectFieldToRestParams(
          modelField,
          formModel[modelField]
        )
      };
    } else {
      if (!!formModel[modelField]) {
        restParams[`form-${modelField}`] = formModel[modelField];
      }
    }
  }
  return restParams;
};

const parseFormModelArrayFieldToRestParams = (fieldName, fieldValues = []) => {
  var restParams = {};
  const restParamName = `form-${fieldName}`;
  for (let fieldValue of fieldValues) {
    restParams[`${restParamName}[${fieldValue}]`] = fieldValue;
  }
  return restParams;
};

const parseFormModelObjectFieldToRestParams = (fieldName, fieldValues = {}) => {
  var restParams = {};
  const restParamName = `form-${fieldName}`;
  for (let fieldKey in fieldValues) {
    restParams[`${restParamName}-${fieldKey}`] = fieldValues[fieldKey];
  }
  return restParams;
};

export const setFormParams = (currentParams, formParams) => {
  var newParams = {};
  for (let param in currentParams) {
    if (!/^form-.+/.test(param)) {
      newParams[param] = currentParams[param];
    }
  }
  return { ...newParams, ...formParams };
};

export const filterFormParams = params => {
  var searchParams = {};
  for (let paramKey in params) {
    if (/^form-.+/.test(paramKey)) {
      searchParams[paramKey] = params[paramKey];
    }
  }
  return searchParams;
};
