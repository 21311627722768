import React from "react";
import styled from "styled-components";

const PageNotFound = () => {
  return (
    <Container data-testid="page-not-found">
      <Header>404</Header>
      <Content>Nie odnaleziono strony</Content>
    </Container>
  );
};

const Container = styled.section`
  margin: 1rem;
  border: 1px solid #bdbdbd;
  border-radius: 0.125rem;
  padding: 1rem;
  background: #eeeeee;

  & > *:not(:last-child) {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid darkgray;
    margin-bottom: 0.75rem;
  }
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 2.25em;
  font-weight: bold;
`;

const Content = styled.div`
  font-size: 1em;
`;

export default PageNotFound;
