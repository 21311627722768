import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { isSurveyCompletedIcLocalStorage } from "modules/survey/utils/isSurveyCompletedIcLocalStorage";

import SurveyResults from "./components/SurveyResults";
import SurveyForm from "./components/SurveyForm";

const SurveyPreview = () => {
  const { id: paramsId } = useParams();
  const surveyId = Number(paramsId);
  const [isCompleted, setIsCompleted] = useState(
    isSurveyCompletedIcLocalStorage(surveyId)
  );

  return (
    <Container>
      {isCompleted ? (
        <SurveyResults surveyId={surveyId} isDeprecated={false} />
      ) : (
        <SurveyForm surveyId={surveyId} setIsCompleted={setIsCompleted} />
      )}
    </Container>
  );
};

const Container = styled.section`
  margin-bottom: 1rem;
  padding: 2rem;
`;

export default SurveyPreview;
