import React from "react";

import { Row } from "../../TableComponents/MetricGrid";
import { User } from "../../TableComponents/User";

import { IUser } from "types/IUser";
import { Translate } from "../../../translate";
import { HeadColumn } from "./HeadColumn";
import { ContentColumn } from "./ContentColumn";

interface IProps {
  user: IUser;
}

const ResponsibleUser = ({ user }: IProps) => {
  return (
    <Row>
      <HeadColumn>
        <Translate>Responsible person</Translate>
      </HeadColumn>
      <ContentColumn>
        <User user={user} />
      </ContentColumn>
    </Row>
  );
};

export { ResponsibleUser };
