import { useEffect } from "react";

import { Browser, checkIfBrowser } from "../../../utils/checkIfBrowser";
import { onScrollTop } from "../../../utils";

export const useScrollToTopAccessKey = () => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (checkIfBrowser(Browser.Firefox)) {
        if (e.altKey && e.shiftKey && (e.key === "t" || e.key === "T")) {
          onScrollTop();
        }
      } else {
        if (e.altKey && (e.key === "T" || e.key === "t")) {
          onScrollTop();
        }
      }
    };
    document.addEventListener("keyup", handler, false);

    return () => document.removeEventListener("keyup", handler);
  });

  return null;
};
