export enum ContactFormFieldType {
  TEXT_FIELD = "textfield",
  MAIL_FIELD = "mailfield",
  TEXT_AREA = "textarea"
}

export interface IContactFormField {
  id: number;
  name: string;
  type: ContactFormFieldType;
}

export interface IContactForm {
  id: number;
  name: string;
  fields: IContactFormField[];
  conset: string;
}
