/**
 * Created by fkurzawa on 26.03.18.
 */
import React from "react";
import styled from "styled-components";

import { IArticleCommentAnswer } from "../../../../types/IArticle";

interface IProps {
  answers: IArticleCommentAnswer[];
}

const AnswersList = ({ answers }: IProps) => {
  return (
    <List>
      {answers.map(answer => (
        <ListItem key={answer.id}>
          <Publisher>{answer.publisher}</Publisher>
          <Content>{answer.content}</Content>
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.ul`
  margin: 0;
  list-style: none;
  border-radius: 1px;
  background: ${({ theme }) => theme.lightPrimaryColor};
  padding: 0.5rem;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    padding-bottom: 3px;
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
    margin-bottom: 3px;
  }
`;

const Publisher = styled.div`
  font-size: 0.75em;
  color: ${({ theme }) => theme.text.primaryColor};
  font-weight: bold;
`;

const Content = styled.div`
  font-size: 0.75em;
  color: ${({ theme }) => theme.text.primaryColor};
  word-wrap: break-word;
`;

export { AnswersList };
