export enum FormFieldType {
  TEXT_FIELD = "textfield",
  MAIL_FIELD = "mailfield",
  TEXT_AREA = "textarea",
  CHECKBOX_FIELD = "checkbox",
  CHECKBOX_GROUP = "checkboxGroup",
  SELECT = "select",
  DATE_RANGE = "dateRange",
  NUMBER_RANGE = "numberRange",
  DATE = "date",
  NUMBER = "number"
}
