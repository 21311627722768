import React from "react";
import Translate from "./Translate";

const withTranslate = BaseComponent => ({ children, ...props }) => (
  <BaseComponent {...props}>
    <Translate>{children}</Translate>
  </BaseComponent>
);

export default withTranslate;
