import { ILanguageTranslation } from "../../types/ILanguage";

export const createTranslationsDictionary = (
  translations: ILanguageTranslation[] = []
) => {
  return translations.reduce((map, obj) => {
    // @ts-ignore
    map[obj.key.toLowerCase()] = obj.translation;
    return map;
  }, {});
};
