import React from "react";

import { IFormField } from "components/HookForm/types";
import { SearchForm } from "components/SearchForm/SearchForm";

import AdvancedSearch from "./components/advanced-search/AdvancedSearch";

interface IProps {
  fields: IFormField[];
  onSubmit: (model: any) => void;
}

const ContextSearch = ({ fields, onSubmit }: IProps) => {
  const contextSearchId = "cs-s";

  return (
    <>
      <SearchForm
        name={contextSearchId}
        onSubmit={onSubmit}
      />
      <AdvancedSearch fields={fields} onSubmit={onSubmit} />
    </>
  );
};

export { ContextSearch };
