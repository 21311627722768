import { find } from "lodash";
import queryString from "query-string";
import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { useMappedState } from "redux-react-hook";

import { useGetLocationParams } from "../../utils/useGetLocationParams";

import { IMenuArticleListSortField } from "modules/articles/types/IMenuArticleListSortField";

import { UnderlineSpan } from "components/UnderlineSpan";
import { SortType } from "../../../../types/enums/SortType";

interface IProps {
  fields: IMenuArticleListSortField[];
  sortDirName?: string;
}

const ArticlesSorting = ({ fields, sortDirName = "sort_dir" }: IProps) => {
  const { isContrast } = useMappedState(mapStateToProps);
  const parsedParams = useGetLocationParams();
  const currentSorting = find(fields, field => field.selected);

  if (fields.length > 0) {
    return (
      <Container>
        <Row>
          <Label>Sortuj według:</Label>
          <StyledList>
            {fields.map((field, index) => (
              <StyledLi>
                <SortFieldLink
                  isContrast={isContrast}
                  key={index}
                  to={`?${queryString.stringify({
                    ...parsedParams,
                    sort: field.value
                  })}`}
                  isActive={() =>
                    field.value === (currentSorting && currentSorting.value)
                  }
                  isActualSorting={
                    field.value === (currentSorting && currentSorting.value)
                  }
                >
                  <UnderlineSpan>{field.name}</UnderlineSpan>
                </SortFieldLink>
              </StyledLi>
            ))}
          </StyledList>
        </Row>
        {currentSorting && currentSorting.value !== "weight" && (
          <Row>
            <Label>Kolejność:</Label>
            <StyledList>
              <li>
                <SortDirLink
                  to={`?${queryString.stringify({
                    ...parsedParams,
                    sort: currentSorting.value,
                    [sortDirName]: SortType.ASC
                  })}`}
                  isActive={() => currentSorting.sortDir === SortType.ASC}
                  isActualSorting={currentSorting.sortDir === SortType.ASC}
                  isContrast={isContrast}
                >
                  <span aria-hidden={true} className="fa fa-chevron-up" />
                  <UnderlineSpan>Rosnąco </UnderlineSpan>
                </SortDirLink>
              </li>
              <li>
                <SortDirLink
                  to={`?${queryString.stringify({
                    ...parsedParams,
                    sort: currentSorting.value,
                    [sortDirName]: SortType.DESC
                  })}`}
                  isActive={() => currentSorting.sortDir === SortType.DESC}
                  isActualSorting={currentSorting.sortDir === SortType.DESC}
                  isContrast={isContrast}
                >
                  <span aria-hidden={true} className="fa fa-chevron-down" />
                  <UnderlineSpan>Malejąco</UnderlineSpan>
                </SortDirLink>
              </li>
            </StyledList>
          </Row>
        )}
      </Container>
    );
  }

  return <></>;
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0 0 0 0;
  display: flex;

  li:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Container = styled.section`
  font-size: 0.75em;
`;

const StyledLi = styled.li`
  &:not(:last-child) {
    padding-right: 0.5rem;
    border-right: 1px solid ${({ theme }) => theme.dividerColor};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

const Label = styled.div`
  color: ${({ theme }) => theme.text.primaryColor};
  flex-basis: 100px;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.text.secondaryColor};
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  ${({ isContrast, isActualSorting }) =>
    isActualSorting &&
    (isContrast
      ? css`
          color: white;
        `
      : css`
          color: #c62828;
        `)};

  &:active,
  &[aria-current="true"] {
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

const SortFieldLink = styled(StyledLink)`
  &:not(:first-child) {
    padding-left: 0.5rem;
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

const SortDirLink = styled(StyledLink)`
  &:not(:last-child) {
    margin-right: 12px;
  }

  .fa {
    color: inherit;
    font-size: 0.625em;
    border: 1px solid;
    padding: 2px;
    margin-right: 6px;
  }

  &:focus:not(:active) {
    outline: ${({ theme }) => theme.focusOutline};
  }
`;

export { ArticlesSorting };
