import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router";
import { compose, withProps } from "recompose";
import BIP_MODULES from "./../../../constants/bip_modules";

const PublicRoute = ({
  component: Component,
  isAuthenticated,
  redirectUrl,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === false ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectUrl,
            state: {
              from: props.location
            }
          }}
        />
      )
    }
  />
);

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  redirectUrl: PropTypes.string.isRequired
};

const mapStateToProps = ({ authentication, context }) => ({
  friendlyUrl: context.contextFriendlyUrl,
  isAuthenticated: authentication.isAuthenticated,
  urlType: context.urlType
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withProps(({ friendlyUrl, location, urlType }) => {
    const isFullUrl = urlType === "full";
    const context =
      friendlyUrl !== "default" ? `${friendlyUrl}${isFullUrl ? "/" : ","}` : "";
    const userPanelUrl = `/${context}${
      isFullUrl
        ? BIP_MODULES.USER_PANEL.fullUrl
        : BIP_MODULES.USER_PANEL.friendlyUrl
    }`;
    const redirectUrl =
      (location.state && location.state.redirectUrl) || userPanelUrl;
    return {
      redirectUrl
    };
  })
);

export default enhance(PublicRoute);
