import * as yup from "yup";

import { validationMessages } from "utils/yup/messages";

export const searchBarValidationSchema = yup.object<{ s: string }>().shape({
  s: yup
    .string()
    .max(200, validationMessages.maxTwoHundredSymbols)
    .test("s", validationMessages.minThreeSymbols, function(textField) {
      const textLength = textField.replace(/\s/g, "").length;
      return textLength > 2;
    })
});
