/**
 * Created by fkurzawa on 16.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { User } from "../../TableComponents/User";

const DateTd = styled.td`
  min-width: 100px;
`;

const TableRow = ({ date, user, description, showReason, reason }) => {
  return (
    <tr>
      <DateTd>{date}</DateTd>
      <td>
        <User user={user} />
      </td>
      <td>{description}</td>
      {showReason && <td>{reason}</td>}
    </tr>
  );
};

TableRow.propTypes = {
  date: PropTypes.string,
  user: PropTypes.object,
  description: PropTypes.string,
  showReason: PropTypes.bool,
  reason: PropTypes.string
};

export default TableRow;
