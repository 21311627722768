// @ts-ignore
import { NotificationManager } from "react-notifications";

export enum INotificationType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info"
}

export const toastNotification = (
  type: INotificationType,
  title: string,
  description: string
) => {
  const duration = 3000;

  switch (type) {
    case "info":
      return NotificationManager.info(description, title, duration);
    case "success":
      return NotificationManager.success(description, title, duration);
    case "error":
      return NotificationManager.error(description, title, duration);
  }
};
