import React from "react";
import styled from "styled-components";

import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {}

const FormRow = ({ children }: IProps) => {
  return <StyledFormRow>{children}</StyledFormRow>;
};

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export { FormRow };
