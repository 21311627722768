import React from "react";
import styled from "styled-components";

import { postArticleForward } from "../../../../../../api/articles";

import { Button } from "../../../../../buttons";
import { IForwardValues } from "./types/IForwardValues";
import { useForwardValuesValidationSchema } from "./useForwardValuesValidationSchema";

import {
  Form,
  TextAreaField,
  TextField,
  RecaptchaField,
  SpinnerButton,
  FormGroup
} from "components/HookForm";

interface IProps {
  onClose: () => void;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
  articleId: number;
  isHistoricalArticle: boolean;
}

const ForwardModalForm = ({
  articleId,
  onClose,
  onError,
  onSuccess,
  isHistoricalArticle
}: IProps) => {
  const onSubmit = async (model: IForwardValues) => {
    let formData = new FormData();
    Object.keys(model).forEach(key => {
      formData.append(key, model[key as keyof typeof model]);
    });
    try {
      await postArticleForward(articleId, formData, isHistoricalArticle);
      onSuccess();
    } catch (e) {
      onError(e.response.data.message);
    }
  };

  return (
    <>
      <Form<IForwardValues>
        onSubmit={onSubmit}
        validationSchema={useForwardValuesValidationSchema()}
        defaultValues={{
          "recipient-email": "",
          "referrer-email": "",
          content: "",
          "recaptcha-response": null
        }}
      >
        {() => {
          return (
            <>
              <FormGroup>
                <TextField
                  name="recipient-email"
                  id="recipient-email"
                  label="Adres email odbiorcy"
                  isRequired={true}
                />
                <TextField
                  name="referrer-email"
                  id="referrer-email"
                  label="Adres email polecającego"
                  isRequired={true}
                />
                <TextAreaField
                  name="content"
                  id="content"
                  label="Treść wiadomości"
                  isRequired={true}
                />
                <RecaptchaField
                  name="recaptcha-response"
                  id="recaptcha-response"
                />
              </FormGroup>

              <ButtonsFormGroup>
                <SpinnerButton
                  label="Wyślij"
                  id="forward-modal-form-submit-button"
                />
                <Button
                  id="close-forward-modal-form-button"
                  type={"button"}
                  data-color="secondary"
                  onClick={() => onClose()}
                >
                  Zamknij
                </Button>
              </ButtonsFormGroup>
            </>
          );
        }}
      </Form>
    </>
  );
};

const ButtonsFormGroup = styled(FormGroup)`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export { ForwardModalForm };
