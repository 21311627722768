import { FIELD_TYPES } from "modules/survey/types/FieldTypes";

export const createCheckboxFieldName = (
  name: string,
  questionFieldType: FIELD_TYPES
) => {
  if (questionFieldType === FIELD_TYPES.LIST_MANY_OPEN) {
    return `${name}[listManyOpen]`;
  } else if (questionFieldType === FIELD_TYPES.LIST_MANY_COMMENT) {
    return `${name}[listManyComment]`;
  } else {
    return name;
  }
};
