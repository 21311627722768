import create from "zustand";
import useRouter from "use-react-router";
import queryString from "query-string";

interface IAuthorizationStore {
  searchValue: string | null;
  setSearchValue: (value: string) => void;
}

export const useSearchValueStore = () => {
  const { location } = useRouter<{
    s: string;
  }>();

  const { s } = queryString.parse(location.search);

  return create<IAuthorizationStore>(set => ({
    searchValue: s?.toString() || "",
    setSearchValue: (value: string) => {
      set({ searchValue: value });
    }
  }));
};
