import axios from "axios";
import ApiConfig from "./api.config";
import { IContactForm } from "../types/IContactForm";

const apiUrl = `${ApiConfig.getEntryPoint()}contact-forms`;

export const getContactForm = (formId: number): Promise<IContactForm> => {
  return axios.get(`${apiUrl}/${formId}`).then(response => response.data);
};

export const postContactForm = (formId: number, formData: any) => {
  return axios.post(`${apiUrl}/${formId}`, formData);
};
