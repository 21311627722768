import React from "react";
import styled from "styled-components";

import { Grid, Row } from "../../../../TableComponents/MetricGrid";

import { IArticleAttachment } from "modules/articles/types/IArticleAttachment";
import {ContentColumn, HeadColumn} from "../../../../metrics/components";

interface IProps {
  attachment: IArticleAttachment;
}

const AttachmentMetricPrintPreview = ({ attachment }: IProps) => {
  return (
    <AttachmentsMetricPreviewContainer>
      <Grid>
        <Row>
          <HeadColumn>Podmiot udostępniający informację</HeadColumn>
          <ContentColumn>{attachment.unitName}</ContentColumn>
        </Row>
        <Row>
          <HeadColumn>Osoba wprowadzająca informację</HeadColumn>
          <ContentColumn>{attachment.createUser.name}</ContentColumn>
        </Row>
        <Row>
          <HeadColumn>Osoba odpowiedzialna za informację</HeadColumn>
          <ContentColumn>{attachment.responseUser.name}</ContentColumn>
        </Row>
        <Row>
          <HeadColumn>Czas wygenerowania</HeadColumn>
          <ContentColumn>{attachment.createDate}</ContentColumn>
        </Row>
        <Row>
          <HeadColumn>Czas publikacji</HeadColumn>
          <ContentColumn>{attachment.publishDate}</ContentColumn>
        </Row>
      </Grid>
    </AttachmentsMetricPreviewContainer>
  );
};

const AttachmentsMetricPreviewContainer = styled.div`
  display: none;
  @media print {
    display: initial;
    padding-left: 20px;
  }
`;

export { AttachmentMetricPrintPreview };
