import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { getBackground } from "../buttons/utils";

const Divider = () => {
  const { isContrast } = useMappedState(mapStateToProps);
  return (
    <StyledDivider aria-hidden isContrast={isContrast}>
      /
    </StyledDivider>
  );
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const StyledDivider = styled.span`
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: ${({ isContrast }) => (isContrast ? getBackground : "#6c757d")};
`;

export { Divider };
