/**
 * Created by fkurzawa on 13.06.18.
 */
import axios from "axios";
import ApiConfig from "./api.config";
import { ILanguage, ILanguageTranslation } from "../types/ILanguage";

const apiUrl = `${ApiConfig.getEntryPoint()}language`;

const getAvailableLanguages = (): Promise<ILanguage> =>
  axios.get(`${apiUrl}/language`).then(response => response.data);

const getTranslation = (langCode: string): Promise<ILanguageTranslation[]> =>
  axios
    .get(`${apiUrl}/${langCode}/translation`)
    .then(response => response.data);

export { getAvailableLanguages, getTranslation };
