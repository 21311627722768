import React, { FunctionComponent, ReactNode } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps
} from "react-error-boundary";

import { Alert, Notification } from "../Alert";
import { ErrorPanel } from "../ErrorPanel";

interface IProps
  extends Omit<ErrorBoundaryPropsWithComponent, "FallbackComponent"> {
  children: ReactNode;
  fallback?: FunctionComponent<FallbackProps>;
}

const ErrorFallback = ({ error }: any) => {
  const status = error?.response?.status;

  switch (status) {
    case 404:
      return <ErrorPanel header="404" content="Nie odnaleziono strony" />;
    default:
      return (
        <Alert type={Notification.ERROR}>
          Coś poszło nie tak, skontaktuj się z administratorem
        </Alert>
      );
  }
};

const ErrorBoundary = ({
  fallback = ErrorFallback,
  children,
  ...restProps
}: IProps) => {
  return (
    <ReactErrorBoundary FallbackComponent={fallback} {...restProps}>
      {children}
    </ReactErrorBoundary>
  );
};

export { ErrorBoundary, ErrorFallback };
