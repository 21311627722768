import React from "react";
import { Controller, useFormContext } from "react-hook-form";
// @ts-ignore
import { arrayMove } from "react-sortable-hoc";

import {
  ISurveyQuestion,
  ISurveyQuestionField
} from "modules/survey/types/ISurveyQuestion";

import { FieldPrototype } from "components/HookForm";

import { SortableList } from "./SortableList";

interface IProps {
  name: string;
  questionField: ISurveyQuestion;
}

const RankingField = ({ questionField, name }: IProps) => {
  const { setValue, getValues } = useFormContext();

  const required = questionField.isRequired === 1;
  const label = questionField.name;
  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    const updatedItems: ISurveyQuestionField[] = arrayMove(
      getValues(name),
      oldIndex,
      newIndex
    );
    setValue(name, updatedItems);
  };

  return (
    <FieldPrototype label={label} isRequired={required} name={name}>
      {() => {
        return (
          <Controller
            name={name}
            render={props => {
              return (
                <SortableList
                  name={name}
                  items={props.value}
                  onSortEnd={onSortEnd}
                />
              );
            }}
          />
        );
      }}
    </FieldPrototype>
  );
};

export { RankingField };
