import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { isSurveyCompletedIcLocalStorage } from "modules/survey/utils/isSurveyCompletedIcLocalStorage";

import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";

import SurveyResults from "./components/SurveyResults";
import SurveyForm from "./components/SurveyForm";

const Survey = () => {
  const { id: paramsId } = useParams();
  const surveyId = Number(paramsId);
  const [isCompleted, setIsCompleted] = useState(
    isSurveyCompletedIcLocalStorage(surveyId)
  );

  return (
    <Container data-testid="test-Survey">
      <Breadcrumb />
      <main tabIndex={-1}>
        {isCompleted ? (
          <SurveyResults surveyId={surveyId} />
        ) : (
          <SurveyForm surveyId={surveyId} setIsCompleted={setIsCompleted} />
        )}
      </main>
    </Container>
  );
};

const Container = styled.section`
  margin-bottom: 1rem;
`;

export default Survey;
