import { useMappedState } from "redux-react-hook";

export const useGetDeclarationOfAvailabilityUrl = () => {
  const { contextFriendlyUrl } = useMappedState(mapStateToProps);
  const domainName = `${window.location.origin}/`;

  if (contextFriendlyUrl === "default") {
    return domainName + "e,deklaracja.html";
  }

  return domainName + contextFriendlyUrl + ",e,deklaracja.html";
};

const mapStateToProps = ({ context }: any) => ({
  contextFriendlyUrl: context.contextFriendlyUrl
});
