import React from "react";

import { Form } from "../HookForm";
import { SearchField } from "./SearchField";
import { searchFormValidationSchema } from "./searchFormValidationSchema";
import { useGetLocationParams } from "../../containers/articles-list/utils/useGetLocationParams";

interface IProps {
  name: string;
  onSubmit: (model: any) => void;
}

const SearchForm = ({ name, onSubmit }: IProps) => {
  const parsedParams = useGetLocationParams();
  return (
    <Form
      defaultValues={{
        [name]: parsedParams["form-cs-s"]?.toString() || ""
      }}
      onSubmit={onSubmit}
      validationSchema={searchFormValidationSchema(name)}
      resetOnSubmit={false}
    >
      {() => {
        return <SearchField name={name} />;
      }}
    </Form>
  );
};

export { SearchForm };
