import styled from "styled-components";

const TableHeadCell = styled.th`
  color: ${({ theme }) => theme.text.primaryColor};
  font-size: 0.875em;
  padding: 0.75rem;
  font-weight: bold;
`;

export default TableHeadCell;
