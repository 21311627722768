import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListItem = ({ children, index, menuShowOrderedListEnabled }) => {
  const itemIndex = index + 1;
  return (
    <StyledListItem>
      {children.redirectUrl ? (
        <a
          href={children.link}
          target={children.target !== "_self" ? children.target : undefined}
        >
          {menuShowOrderedListEnabled ? (
            <>{itemIndex + ". " + children.name}</>
          ) : (
            <>{children.name}</>
          )}
          {children.description && (
            <Description>{children.description}</Description>
          )}
        </a>
      ) : (
        <Link
          to={`/${children.link}`}
          target={children.target !== "_self" ? children.target : undefined}
        >
          {menuShowOrderedListEnabled ? (
            <>{itemIndex + ". " + children.name}</>
          ) : (
            <>{children.name}</>
          )}
          {children.description && (
            <Description>{children.description}</Description>
          )}
        </Link>
      )}
    </StyledListItem>
  );
};

const Description = styled.span`
  font-size: 0.875em;
  display: block;
  color: ${({ theme }) => theme.text.secondaryColor};
`;

const StyledListItem = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  }

  & a {
    font-size: 1em;
    display: block;
    padding: 0.75rem;
    color: ${({ theme }) => theme.text.primaryColor};

    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
      text-decoration: none;
      background: ${({ theme }) => theme.lightPrimaryColor};
    }
  }
`;

ListItem.propTypes = {
  children: PropTypes.object,
  index: PropTypes.number,
  menuShowOrderedListEnabled: PropTypes.bool
};

export { ListItem };
