import { useState } from "react";

import { getFile } from "./getFile";
import { getFileName } from "./getFileName";
import { initDownloadToLocal } from "./initDownloadToLocal";

export const useDownloadFile = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (fileUrl: string) => {
    setIsDownloading(true);
    await getFile(fileUrl)
      .then(res => {
        const filename = getFileName(res.headers);
        initDownloadToLocal(res.data, filename);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return { downloadFile, isDownloading };
};
