import * as yup from "yup";
import {
  ContactFormFieldType,
  IContactFormField
} from "../../../types/IContactForm";
import { emailValidationSchema } from "utils/yup/emailValidationSchema";
import { validationMessages } from "utils/yup/messages";
import { checkboxRequiredValidationSchema } from "utils/yup/checkboxRequiredValidationSchema";
import { IDynamicFieldSchema } from "../../../utils/yup/IDynamicFieldSchema";
import {useRecaptchaValidationSchema} from "utils/yup/useRecaptchaValidationSchema";

const textSchema = yup.string().required(validationMessages.requiredField);

const contactFormSchemaStrategy = (fieldType: ContactFormFieldType) => {
  switch (fieldType) {
    case ContactFormFieldType.MAIL_FIELD:
      return emailValidationSchema.required(validationMessages.requiredField);
    case ContactFormFieldType.TEXT_AREA:
      return textSchema;
    case ContactFormFieldType.TEXT_FIELD:
      return textSchema;
  }
};
export const useCreateContactFormValidationSchema = (
  fields: IContactFormField[]
) => {
  let dynamicFieldsSchema: IDynamicFieldSchema = {};
  fields.forEach(field => {
    dynamicFieldsSchema[field.name] = contactFormSchemaStrategy(field.type);
  });

  return yup.object().shape({
    recaptchaResponse: useRecaptchaValidationSchema(),
    terms: checkboxRequiredValidationSchema,
    ...dynamicFieldsSchema
  });
};
