import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import uuid from "uuid/v4";
import { mdiInformationOutline, mdiRefresh } from "@mdi/js";
import { useMappedState } from "redux-react-hook";

import Modal from "../../../../../modal/Modal";
import { Button } from "components/buttons";
import { Icon } from "components/Icon";
import { CopyPathButton } from "./CopyPathButton";

const PreviewModal = ({
  attachmentName,
  fileUrl,
  show,
  toggle,
  isPhotoFormat,
}) => {
  const { isContrast } = useMappedState(mapStateToProps);
  const [frameId] = useState(`frame-${uuid()}`);
  const { protocol } = window.location;
  const [frameSrc] = useState(
    `${protocol}//docs.google.com/gview?url=${fileUrl}&embedded=true`
  );

  const refreshFrame = useCallback(() => {
    let frame = document.querySelector(`#${frameId}`);
    const src = frame.src;
    if (frame) {
      frame.src = src;
    }
  }, [frameId]);

  return (
    <Modal show={show} onHide={toggle} width="90%" height="90%">
      <Content>
        <Header>
          <HeaderContent>
            <Title>Podgląd: {attachmentName}</Title>
            <Button
              id="attachment-refresh-button"
              type="button"
              aria-label="Odśwież podgląd"
              title="Odśwież podgląd"
              data-flat="true"
              onClick={refreshFrame}
            >
              <Icon path={mdiRefresh} />
            </Button>
            <Icon
              path={mdiInformationOutline}
              title="W przypadku niezaładowania podglądu należy odświeżyć widok"
            />
            <CopyPathButton fileUrl={fileUrl} flat="true" />
          </HeaderContent>
          <CloseButton
            isContrast={isContrast}
            type="button"
            aria-label="Zamknij"
            title="Zamknij"
            onClick={toggle}
          >
            <span aria-hidden="true" className="fa fa-close" />
          </CloseButton>
        </Header>
        {isPhotoFormat ? (
          <ImageContainer>
            {fileUrl && <Image id={frameId} src={fileUrl} />}
          </ImageContainer>
        ) : (
          <Body>
            {frameSrc && (
              <IFrame
                id={frameId}
                src={frameSrc}
                title={`File ${attachmentName} preview`}
              />
            )}
          </Body>
        )}
      </Content>
    </Modal>
  );
};

const mapStateToProps = ({ accessibility }) => ({
  isContrast: accessibility.isContrast,
});

PreviewModal.propTypes = {
  attachmentName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  isPhotoFormat: PropTypes.bool.isRequired,
  show: PropTypes.bool,
  toggle: PropTypes.func,
};

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.dividerColor};
  padding: 1em;
`;

const HeaderContent = styled.div`
  display: inline-flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 9px;
  }
`;

const Title = styled.h1`
  font-size: 1.25em;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.text.primaryColor};
`;

const CloseButton = styled.button.attrs({
  className: "close",
})`
  && {
    padding: 0.2rem 0.4rem;
  }
  &:-moz-focusring {
    outline: 2px solid ${({ isContrast }) => (isContrast ? "white" : "black")};
  }
`;

const Body = styled.div`
  flex-grow: 1;
  display: flex;
`;

const ImageContainer = styled.div`
  background-color: #999;
  display: inline-block;
`;

const Image = styled.img`
  width: 100%;
`;

const IFrame = styled.iframe`
  border: none;
  flex-grow: 1;
  height: 100%;
`;

export { PreviewModal };
