/**
 * Created by fkurzawa on 26.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Element from "./components/Element";

const Faq = ({ faq }) => {
  return (
    <React.Fragment>
      <Description>{faq.description}</Description>
      <ElementsList>
        {faq.elements &&
          faq.elements.map(element => (
            <ElementsListItem key={element.id}>
              <Element element={element} />
            </ElementsListItem>
          ))}
      </ElementsList>
    </React.Fragment>
  );
};

Faq.propTypes = {
  faq: PropTypes.object.isRequired
};

const Description = styled.p`
  font-size: 1em;
  color: ${({ theme }) => theme.text.primaryColor};
`;

const ElementsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ElementsListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default Faq;
