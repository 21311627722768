import { IMenuArticlesList } from "modules/articles/types/IMenuArticlesList";

export const getArticleId = (menuArticles: IMenuArticlesList) => {
  return (
    menuArticles.mainArticleId ||
    (menuArticles.tabs.length === 0 &&
      menuArticles.total === 1 &&
      menuArticles.articles[0].id)
  );
};
