import React from "react";
import { useFormContext } from "react-hook-form";

import { TextField } from "components/HookForm/TextField";

import { SurveyCheckBoxField } from "./SurveyCheckbox";

interface IProps {
  checkboxFieldName: string;
  commentFieldName: string;
  checkboxLabel: string;
  optionHasComment: number;
  value: string;
  customChange?: (value: string) => void;
}

const Option = ({
  checkboxFieldName,
  checkboxLabel,
  commentFieldName,
  optionHasComment,
  value,
  customChange
}: IProps) => {
  const { watch } = useFormContext();
  const showCommentField =
    watch(checkboxFieldName) === 1 && optionHasComment === 1;
  return (
    <>
      <SurveyCheckBoxField
        isRequired={false}
        name={checkboxFieldName}
        id={checkboxFieldName}
        label={checkboxLabel}
        checkedValue={value}
        customChange={customChange}
      />
      {showCommentField && (
        <TextField name={commentFieldName} id={commentFieldName} label="Dodaj komentarz" />
      )}
    </>
  );
};

export { Option };
