import React from "react";

import { useFontSize } from "utils/useFontSize";
import { FONT_SIZES } from "constants/font-sizes";

import { Tooltip } from "components/Tooltip";

import { DesktopAccessibilityButton } from "./DesktopAccessibilityButton";

const BigLettersButton = () => {
  const { fontSize, setFontSize } = useFontSize();
  const label = "Bardzo duża wielkość liter";

  return (
    <Tooltip label={label} id="big-letters-button">
      <DesktopAccessibilityButton
        aria-pressed={fontSize === FONT_SIZES.LARGE}
        id="big-letters-button"
        aria-label={label}
        onClick={() => setFontSize(FONT_SIZES.LARGE)}
      >
        A++
      </DesktopAccessibilityButton>
    </Tooltip>
  );
};

export { BigLettersButton };
