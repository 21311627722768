import React from "react";
// @ts-ignore
import { Chart } from "react-google-charts";

import { FIELD_TYPES } from "modules/survey/types/FieldTypes";
import { getMatrixDataValuesFromAnswer } from "modules/survey/utils/getMatrixDataValuesFromAnswer";
import { getDefaultDataValuesFromAnswer } from "modules/survey/utils/getDefaultDataValuesFromAnswer";
import { ISurveyQuestion } from "modules/survey/types/ISurveyQuestion";

interface IProps {
  answer: ISurveyQuestion;
}

const SingleSurveyResult = ({ answer }: IProps) => {
  const options = {
    title: answer.name,
    legend: {
      position: "right",
      maxLines: 3
    },
    bar: {
      groupWidth: "75%"
    }
  };

  const dataAnswers = [
    "Title",
    ...answer.answersCounter.map(answerCounter => answerCounter.answer)
  ];
  const dataValues =
    answer.type === FIELD_TYPES.LIST_MATRIX
      ? getMatrixDataValuesFromAnswer(answer)
      : getDefaultDataValuesFromAnswer(answer);

  const data = [dataAnswers, ...dataValues];

  return (
    <Chart
      key={answer.id}
      chartType="ColumnChart"
      width="100%"
      data={data}
      options={options}
    />
  );
};

export { SingleSurveyResult };
