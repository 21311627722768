/**
 * Created by fkurzawa on 08.05.18.
 */
export enum FIELD_TYPES {
  OPEN = "field_open",
  DATE = "field_data",
  NUMBER = "field_num",
  LIST_ONE_CLOSED = "list_one_closed",
  LIST_ONE_OPEN = "list_one_open",
  LIST_ONE_COMMENT = "list_one_comment",
  LIST_MANY_CLOSED = "list_many_closed",
  LIST_MANY_OPEN = "list_many_open",
  LIST_MANY_COMMENT = "list_many_comment",
  LIST_RANKING = "list_ranking",
  LIST_MATRIX = "list_matrix"
}
