import React from "react";
import MK_MODULES from "./mk-modules.const";
import { ActionButtons } from "../components";

export const processActionButtons = (articleId, isHistorical, isPreview) => {
  return {
    shouldProcessNode: node =>
      node.attribs && node.attribs["data-module"] === MK_MODULES.ACTION_BUTTONS,
    processNode: (node, children, index) => (
      <ActionButtons
        key={index}
        articleId={articleId}
        contactFormId={
          node.attribs["data-contact-form-id"] !== "null"
            ? Number(node.attribs["data-contact-form-id"])
            : null
        }
        isHistorical={isHistorical}
        isPreview={isPreview}
        showForward={node.attribs["data-forward"] === "true"}
        showDownloadPdf={node.attribs["data-pdf"] === "true"}
        showDownloadXml={node.attribs["data-xml"] === "true"}
        showPrint={node.attribs["data-print"] === "true"}
        showContactForm={node.attribs["data-contact-form-id"] !== "null"}
      />
    )
  };
};
