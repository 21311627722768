import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

import { withTranslate } from "./../../translate";

interface IProps {
  id: string;
  title: string;
  onClick: () => void;
  isOpen: boolean;
  withCollapse: boolean;
}

const Heading = ({ id, title, onClick, isOpen, withCollapse }: IProps) => {
  const iconClassName = `fa fa-chevron-${isOpen ? "up" : "down"}`;
  const { isContrast } = useMappedState(mapStateToProps);

  return (
    <h3 style={{ fontSize: "0.875rem" }}>
      <HeadingContainer
        id={`${id}-head`}
        isContrast={isContrast}
        aria-expanded={isOpen}
        onClick={onClick}
        withCollapse={withCollapse}
      >
        {withCollapse && (
          <ArrowIcon
            id={`${id}-fa-chevron-${isOpen ? "up" : "down"}`}
            className={iconClassName}
          />
        )}
        <Title>{title}</Title>
      </HeadingContainer>
    </h3>
  );
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const HeadingContainer = styled.button`
  background: ${({ theme }) => theme.lightPrimaryColor};
  width: 100%;
  min-height: 55px;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.dividerColor};
  &:hover {
    cursor: ${({ withCollapse }) => (withCollapse ? "pointer" : "default")};
  }
  &:-moz-focusring {
    outline: 2px solid ${({ isContrast }) => (isContrast ? "white" : "black")};
  }
`;

const ArrowIcon = styled.span`
  color: ${({ theme }) => theme.secondaryColor};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  margin-right: 12px;
  padding: 5px 6px 6px 7px;

  && {
    @media print {
      display: none;
    }
  }
`;

const Title = withTranslate(styled.span`
  color: ${({ theme }) => theme.text.primaryColor};
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
`);

export { Heading };
