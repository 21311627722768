import React, { FunctionComponent } from "react";
import {
  ErrorBoundaryPropsWithComponent,
  FallbackProps
} from "react-error-boundary";
import { QueryKey } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";

import { useReactQuery } from "./useReactQuery";
import { ErrorBoundary } from "../ErrorBoundary";

import { ChildrenAsFunction } from "types/ChildrenAsFunction";

export interface IRemoteData<T> {
  queryKey: QueryKey;
  fetcher: () => Promise<T>;
  children: ChildrenAsFunction<T>;
  errorFallback?: FunctionComponent<FallbackProps>;
  configuration?: UseQueryOptions<T>;
  errorBoundaryProps?: Omit<
    ErrorBoundaryPropsWithComponent,
    "FallbackComponent"
  >;
}

const RemoteDataThrowable = <T extends {}>({
  queryKey,
  fetcher,
  children,
  configuration
}: IRemoteData<T>) => {
  const { data, error } = useReactQuery(queryKey, fetcher, configuration);

  if (error) {
    throw new Error(error.message);
  }

  if (data) {
    return <>{children(data)}</>;
  }

  return <></>;
};

const RemoteData = <T extends {}>({
  errorFallback,
  errorBoundaryProps,
  ...props
}: IRemoteData<T>) => {
  return (
    <ErrorBoundary fallback={errorFallback} {...errorBoundaryProps}>
      <RemoteDataThrowable {...props} />
    </ErrorBoundary>
  );
};

export { RemoteData };
