import { ISurveyQuestion } from "../types/ISurveyQuestion";
import { FIELD_TYPES } from "../types/FieldTypes";

export const createDefaultSurveyFormValues = (questions: ISurveyQuestion[] | null) => {
  let dynamicDefaultValues: any = {};

  questions?.forEach(question => {
    if (question.type === FIELD_TYPES.LIST_RANKING) {
      dynamicDefaultValues[`form_${question.id.toString()}`] = question.fields;
      return;
    }
  });
  return dynamicDefaultValues;
};
