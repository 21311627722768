export const validationMessages = {
  requiredField: "Pole wymagane",
  requiredRecaptchaField: "Uzupełnij checkbox weryfikacyjny",
  invalidEmail: "Niepoprawny format email",
  minThreeSymbols: "Szukana fraza powinna mieć co najmniej 3 znaki",
  maxTwoHundredSymbols:
    "Szukana fraza jest za długa. Maksymalna liczba znaków to 200",
  dateFrom: 'Data powinna być wcześniejsza od daty "Do"',
  dateTo: 'Data powinna być późniejsza od daty "Od"'
};
