import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { MenuTile } from "./MenuTile";

const MenuTilesChildren = ({ menuChildren }) => {
  return (
    <StyledContainer>
      {menuChildren.map(tile => {
        return (
          <Fragment key={tile.id}>
            {tile.redirectUrl ? (
              <StyledHyperLink
                href={tile.link}
                target={tile.target !== "_self" ? tile.target : undefined}
                title={tile.description}
              >
                <MenuTile tile={tile} />
              </StyledHyperLink>
            ) : (
              <StyledLink
                to={`/${tile.link}`}
                target={tile.target !== "_self" ? tile.target : undefined}
                title={tile.description}
              >
                <MenuTile tile={tile} />
              </StyledLink>
            )}
          </Fragment>
        );
      })}
    </StyledContainer>
  );
};

const StyledContainer = styled.li`
  margin: 30px auto;
  display: grid;
  grid-gap: 1rem;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledHyperLink = styled.a`
  text-decoration: none !important;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
`;

MenuTilesChildren.propTypes = {
  menuChildren: PropTypes.array
};

export { MenuTilesChildren };
