import styled from "styled-components";

const PageTitle = styled.h1`
  color: ${({ theme }) =>
    theme.pageTitleColor ? theme.pageTitleColor : theme.text.primaryColor};
  font-size: 1.75em;
  font-weight: bold;
  padding: 0.625rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
`;

export default PageTitle;
