import { useMappedState } from "redux-react-hook";

export const useTranslation = () => {
  const { translations } = useMappedState(mapStateToProps);
  const translate = (text: string) => {
    const key = text.toLowerCase();
    return translations[key] || text;
  };

  return { translate };
};

const mapStateToProps = ({ language }: any) => ({
  translations: language.translations
});
