/**
 * Created by fkurzawa on 08.05.18.
 */
import React from "react";
import styled from "styled-components";
// @ts-ignore
import { SortableContainer } from "react-sortable-hoc";

import { ISurveyQuestionField } from "modules/survey/types/ISurveyQuestion";

import SortableItem from "./SortableItem";

interface IProps {
  name: string;
  items: ISurveyQuestionField[];
}

const SortableList = SortableContainer(({ name, items }: IProps) => {
  return (
    <List>
      {items.map((item, index) => {
        const value = index + 1;
        return (
          <SortableItem
            name={`${name}_${item.id}`}
            value={value}
            key={`item-${index}`}
            index={index}
            item={item}
          />
        );
      })}
    </List>
  );
});

const List = styled.ol`
  margin: 0;
  padding: 0 0 0 1rem;

  & > li {
    list-style: decimal;
  }
`;

export { SortableList };
