/**
 * Created by fkurzawa on 28.03.18.
 */
import styled from "styled-components";

export const FormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
