import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";
import { mdiCalendar } from "@mdi/js";
import pl from "date-fns/locale/pl";
import ReactDOM from "react-dom";
import { useMappedState } from "redux-react-hook";

import { Icon } from "components/Icon";
import { getBackground } from "../../buttons/utils";

export interface IDatePickerInput
  extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  hasError: boolean;
  value: string;
  handleChange: (date: Date) => void;
  id: string;
  lang?: string;
  label?: string;
  className?: string;
  isRequired?: boolean;
}

const DatePickerInput = ({
  isRequired = true,
  name,
  lang,
  label,
  className,
  handleChange,
  value,
  hasError,
  ...datePickerProps
}: IDatePickerInput) => {
  const { contrast } = useMappedState(mapStateToProps);
  const [isOpen, setIsOpen] = useState(false);
  const selected = value && moment(value.toString()).toDate();
  const ref = useRef(null);

  const node = ref?.current;

  // Remove aria-label from react-datepicker__month div because always have english title and no DatePicker props can change it
  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      const monthContainersInCalendar = ReactDOM.findDOMNode(node)
        // @ts-ignore
        ?.getElementsByClassName("react-datepicker__month")
        .item(0);
      monthContainersInCalendar.removeAttribute("aria-label");
    }
  }, [node, isOpen]);

  return (
    <InputGroup ref={ref} isContrast={contrast}>
      <DatePicker
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        previousMonthButtonLabel={""}
        previousMonthAriaLabel="Poprzedni miesiąc"
        nextMonthButtonLabel=""
        nextMonthAriaLabel="Następny miesiąc"
        chooseDayAriaLabelPrefix="Wybierz"
        disabledDayAriaLabelPrefix="Niedostępny dzień"
        // @ts-ignore
        selected={selected}
        name={name}
        locale={pl}
        className={className || ""}
        dateFormat="yyyy-MM-dd"
        selectsEnd
        customInput={<Input hasError={hasError} type="text" value={value} />}
        showMonthDropdown
        showYearDropdown
        {...datePickerProps}
        onChange={handleChange}
      />
      <InputCalendar hasError={hasError}>
        <Icon path={mdiCalendar} title={"Kalendarz"} />
      </InputCalendar>
    </InputGroup>
  );
};

const mapStateToProps = ({ accessibility }: any) => ({
  contrast: accessibility.isContrast
});

const InputGroup = styled.div`
  border-color: #0f6674 !important;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;

  .react-datepicker {
    background-color: ${({ theme, isContrast }) =>
      isContrast ? theme.darkPrimaryColor : "white"};
    border: 1px solid
      ${({ isContrast }) => (isContrast ? getBackground : "#aeaeae")};
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${({ isContrast }) =>
      isContrast ? getBackground : "black"};
  }

  .react-datepicker__navigation--next {
    border-left-color: ${({ isContrast }) =>
      isContrast ? getBackground : "black"};
  }

  .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: black;
  }

  .react-datepicker__navigation--years-upcoming {
    border-bottom-color: ${({ isContrast }) =>
      isContrast ? getBackground : "black"};
  }

  .react-datepicker__navigation--years-previous {
    border-top-color: ${({ isContrast }) =>
      isContrast ? getBackground : "black"};
  }

  .react-datepicker__navigation--years-previous:hover {
    border-top-color: black;
  }

  .react-datepicker__header {
    background-color: transparent;
  }

  .react-datepicker__current-month,
  .react-datepicker__header,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${({ isContrast }) => (isContrast ? getBackground : "black")};
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: ${({ isContrast, theme }) =>
      isContrast ? theme.darkPrimaryColor : "#f0f0f0"};
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    border-top-color: ${({ isContrast }) =>
      isContrast ? getBackground : "black"};
  }

  .react-datepicker__month-option:hover,
  .react-datepicker__year-option:hover {
    background-color: ${({ isContrast }) =>
      isContrast ? getBackground : "#ccc"};
    color: black;
  }

  .react-datepicker__day--disabled {
    color: ${({ isContrast }) => (isContrast ? "white" : "black")};
    background-color: ${({ isContrast }) =>
      isContrast ? "#423D3D" : "lightgray"};
  }

  .react-datepicker__day--disabled:hover {
    color: ${({ isContrast }) => (isContrast ? "white" : "black")};
  }

  .react-datepicker__week:not(:last-child) {
    border-bottom: solid 1px
      ${({ isContrast }) => (isContrast ? getBackground : "black")};
  }

  .react-datepicker__week .react-datepicker__day:not(:last-child) {
    border-right: 1px solid
      ${({ isContrast }) => (isContrast ? getBackground : "black")};
  }

  .react-datepicker__day {
    padding: 0.166rem;
    margin: 0;
    width: 2rem;
  }

  .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    border-radius: 0;
    color: black;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 0;
    background-color: ${({ isContrast }) =>
      isContrast ? getBackground : "#1870c9"};
    color: ${({ isContrast }) => (isContrast ? "black" : "white")};
  }
`;

const Input = styled.input.attrs({ type: "text" })`
  color: ${({ theme }) => theme.text.primaryColor};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
  background: ${({ theme }) => theme.defaultBackground};
  font-size: 1em;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
`;

const InputCalendar = styled.div`
  display: -ms-flexbox;
  display: flex;
  margin-left: -1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.8rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  && {
    font-size: 1em;
    color: ${({ theme }) => theme.text.primaryColor};
    background: ${({ theme }) => theme.lightPrimaryColor};
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.errorColor : theme.dividerColor};
    border-radius: 0 3px 3px 0;
  }
`;

export { DatePickerInput };
