import React, { useState } from "react";
import styled from "styled-components";

import { postContactForm } from "api/contactForms";

import { useCreateContactFormValidationSchema } from "../utils/useCreateContactFormValidationSchema";
import { createContactFormDefaultValues } from "../utils/createContactFormDefaultValues";

import { Alert, Notification } from "components/Alert";
import { PageTitle } from "components/typography";
import { IForwardValues } from "components/ArticleComponents/Content/components/ActionButtons/components/types/IForwardValues";
import {
  CheckBoxField,
  Form,
  RecaptchaField,
  FormGroup
} from "components/HookForm";

import { ContactFormFieldsSwitch } from "./ContactFormFieldsSwitch";

import { IContactForm } from "types/IContactForm";
import { IChildrenProp } from "types/IChildrenProp";

interface IProps extends IChildrenProp {
  contactForm: IContactForm;
  formId: number;
}

const ContactForm = ({
  contactForm: { fields, conset, name },
  formId,
  children
}: IProps) => {
  const [alert, setAlert] = useState<{
    type: Notification;
    message: string;
  } | null>(null);

  const onSuccess = () => {
    setAlert({
      type: Notification.SUCCESS,
      message: "Twoja wiadomość została wysłana"
    });
  };

  const onError = () => {
    setAlert({
      type: Notification.ERROR,
      message: "Wiadomość nie została wysłana"
    });
  };

  const handleOnSubmit = async (model: any) => {
    setAlert(null);
    let formData = new FormData();
    fields.forEach(field => {
      formData.append(`form[f_${field.id}]`, model[field.name]);
    });
    formData.append("recaptcha-response", model["recaptchaResponse"]);
    formData.append("terms", model.terms);

    try {
      await postContactForm(formId, formData);
      onSuccess();
    } catch {
      onError();
    }
  };
  return (
    <>
      <PageTitle>{name}</PageTitle>
      <MessageWrapper>
        {alert && <Alert type={alert.type}>{alert.message}</Alert>}
      </MessageWrapper>

      <Form<IForwardValues>
        onSubmit={handleOnSubmit}
        validationSchema={useCreateContactFormValidationSchema(fields)}
        defaultValues={createContactFormDefaultValues(fields)}
      >
        {() => {
          return (
            <>
              <FormGroup>
                {fields.map(field => (
                  <ContactFormFieldsSwitch key={field.id} field={field} />
                ))}
                <CheckBoxField name="terms" id="terms" label={conset} />
                <RecaptchaField name="recaptchaResponse" id="recaptchaResponse" />
              </FormGroup>

              {children}
            </>
          );
        }}
      </Form>
    </>
  );
};

const MessageWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export { ContactForm };
