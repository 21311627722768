import React from "react";
import styled from "styled-components";

import { Button, ExternalLinkButton, LinkButton } from "../../buttons";
import Translate from "../../translate";
import BIP_MODULES from "../../../constants/bip_modules";
import { IFooterLink } from "../../../types/IFooterLink";
import { useScrollToTopAccessKey } from "./useScrollToTopAccessKey";
import { onScrollTop } from "../../../utils";

interface IProps {
  links: IFooterLink[];
}

const FooterContent = ({ links = [] }: IProps) => {
  const isExternalLink = (url: string) => /^https?.*/.test(url);
  useScrollToTopAccessKey();

  return (
    <Container>
      <LinksList>
        {links.map((link, index) => (
          <LinkListItem key={index}>
            {isExternalLink(link.link) ? (
              <ExternalLinkButton
                id={`external-footer-link-${link.position}`}
                href={link.link}
                target={link.target === "_self" ? null : link.target}
                data-flat={"true"}
              >
                {link.title}
              </ExternalLinkButton>
            ) : (
              <LinkButton
                id={`internal-footer-link-${link.position}`}
                to={`/${link.link}`}
                target={link.target === "_self" ? null : link.target}
                onClick={onScrollTop}
                data-flat={"true"}
                {...(BIP_MODULES.SITE_MAP.friendlyUrl === link.link && {
                  accessKey: "M"
                })}
              >
                {link.title}
              </LinkButton>
            )}
          </LinkListItem>
        ))}
      </LinksList>
      {/*eslint-disable-next-line*/}
      <FooterGoUpButton
        id="go-to-top-button"
        accessKey="Q"
        data-flat={"true"}
        onClick={onScrollTop}
        type="button"
      >
        <Translate>Up</Translate>{" "}
        <span aria-hidden={true} className="fa fa-arrow-up" />
      </FooterGoUpButton>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
`;

const LinksList = styled.ul`
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LinkListItem = styled.li`
  &:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  & a {
    color: ${({ theme }) =>
      theme.footerLinkColor ? theme.footerLinkColor : ""};

    &:hover:not(:disabled) {
      border-color: ${({ theme }) =>
        theme.footerLinkColor ? theme.footerLinkColor : ""};
    }
  }
`;

const FooterGoUpButton = styled(Button)`
  && {
    color: ${({ theme }) =>
      theme.footerLinkColor ? theme.footerLinkColor : ""};

    &:hover:not(:disabled) {
      border-color: ${({ theme }) =>
        theme.footerLinkColor ? theme.footerLinkColor : ""};
    }
  }
`;

export { FooterContent };
