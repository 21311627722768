import * as yup from "yup";

import { validationMessages } from "utils/yup/messages";

export const searchFormValidationSchema = (name: string) =>
  yup.object().shape({
    [name]: yup
      .string()
      .max(200, validationMessages.maxTwoHundredSymbols)
      .test(name, validationMessages.minThreeSymbols, function(textField) {
        const textLength = textField.replace(/\s/g, "").length;
        return textLength > 2;
      })
      .nullable()
  });
