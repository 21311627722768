import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/array/from";
import "core-js/fn/object/assign";
import "core-js/fn/string/includes";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App/App";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import { Providers } from "./App/Providers";

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root")
);
unregisterServiceWorker();
