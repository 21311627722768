/**
 * Created by fkurzawa on 20.03.18.
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Overlay } from "react-overlays";

import { AttachmentMetric } from "./AttachmentMetric";

/*
@todo: W przyszłości spróbować zmienić na komponent funkcyjny, dokumentacja https://react-bootstrap.github.io/react-overlays/api/Overlay
Aktualnie po zamianie na komponent funkcyjny layout całkowicie się psuje
 */

class MetricOverlay extends React.Component {
  render() {
    const { attachment, show, toggle } = this.props;

    return (
      <OverlayContainer>
        <Overlay show={show} onHide={toggle} container={this} rootClose>
          <OverlayContent>
            <AttachmentMetric attachment={attachment} />
          </OverlayContent>
        </Overlay>
      </OverlayContainer>
    );
  }
}

MetricOverlay.propTypes = {
  attachment: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

const OverlayContainer = styled.div`
  position: relative;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 6px !important;

  @media screen and (max-width: 575px) {
    width: calc(100vw - 30px);
  }

  @media screen and (min-width: 576px) {
    width: 400px;
  }

  @media screen and (min-width: 768px) {
    width: 500px;
  }

  @media screen and (min-width: 992px) {
    right: 0 !important;
    left: auto !important;
  }

  padding: 6px;
  background: ${({ theme }) => theme.lightPrimaryColor};
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 1px;
  z-index: 1;
`;

export { MetricOverlay };
