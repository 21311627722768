import { useEffect } from "react";

import { Browser, checkIfBrowser } from "../../../../../utils/checkIfBrowser";
import { FONT_SIZES } from "../../../../../constants/font-sizes";
import { useFontSize } from "../../../../../utils/useFontSize";

export const useIncreaseDecreaseFontSize = () => {
  const { fontSize, setFontSize } = useFontSize();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (checkIfBrowser(Browser.Firefox)) {
        if (e.altKey && e.shiftKey && (e.key === "L" || e.key === "l")) {
          increaseFontSize();
        }
        if (e.altKey && e.shiftKey && (e.key === "s" || e.key === "S")) {
          decreaseFontSize();
        }
      } else {
        if (e.altKey && (e.key === "L" || e.key === "l")) {
          increaseFontSize();
        }

        if (e.altKey && (e.key === "s" || e.key === "S")) {
          decreaseFontSize();
        }
      }
    };
    document.addEventListener("keyup", handler, false);

    return () => document.removeEventListener("keyup", handler);
  });

  const increaseFontSize = () => {
    switch (fontSize) {
      case "0":
        setFontSize(FONT_SIZES.MEDIUM);
        break;
      case "1":
        setFontSize(FONT_SIZES.LARGE);
        break;
      default:
        return;
    }
  };

  const decreaseFontSize = () => {
    switch (fontSize) {
      case "1":
        setFontSize(FONT_SIZES.DEFAULT);
        break;
      case "2":
        setFontSize(FONT_SIZES.MEDIUM);
        break;
      default:
        return;
    }
  };
};
