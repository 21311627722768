import React from "react";
import HtmlToReact from "html-to-react";

import { replaceDataModuleVideoStreamInstructions } from "./replaceDataModuleVideoStreamInstructions";
import { processActionButtons } from "./processActionButtons";
import { processOrganizationUnits } from "./processOrganizationUnits";
import { processAnchor } from "./processAnchor";
import { processAnchorLink } from "./processAnchorLink";
import { processGoogleMap } from "./processGoogleMap";
import { processImageSource } from "./processImageSource";
import { processAudio } from "./processAudio";
import { processTitle } from "./processTitle";
import { processIFrameWithTraseoMap } from "./processIFrameWithTraseoMap";
import { processTableRows } from "./processTableRows";
import { processTable } from "./processTables";

export const getProcessingInstructions = (
  articleId,
  isHistorical,
  isPreview
) => {
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  return [
    processTable,
    processActionButtons(articleId, isHistorical, isPreview),
    processOrganizationUnits,
    processAnchor,
    processAnchorLink,
    processGoogleMap,
    processImageSource(processNodeDefinitions),
    processAudio(processNodeDefinitions),
    processIFrameWithTraseoMap,
    processTitle,
    processTableRows(processNodeDefinitions),
    replaceDataModuleVideoStreamInstructions,
    {
      shouldProcessNode: () => true,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ];
};
