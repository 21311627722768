import React from "react";
import queryString from "query-string";
import useRouter from "use-react-router";

import { IMenuArticleListSortField } from "modules/articles/types/IMenuArticleListSortField";

import { parseFormModelToRestParams, setFormParams } from "utils/search-params";

import { IFormField } from "components/HookForm/types";

import TitleBar from "../../title-bar/TitleBar";
import { ContextSearch } from "../../contextSearch/ContextSearch";
import { HideInPrintContent } from "../../HideInPrintContent";

import { ArticlesSorting } from "../../articlesSorting/ArticlesSorting";

interface IProps {
  sortFields: IMenuArticleListSortField[];
  searchFields: IFormField[];
  listTitle: string;
}

const FilterBar = ({ listTitle, searchFields, sortFields }: IProps) => {
  const { history, location } = useRouter();

  const onSearchSubmit = (model: any) => {
    const searchParams = parseFormModelToRestParams(model);
    const newParams = setFormParams(
      queryString.parse(location.search),
      searchParams
    );
    history.push(
      `?${queryString.stringify({
        ...newParams,
        page: 1
      })}`
    );
  };

  return (
    <HideInPrintContent>
      <TitleBar title={listTitle || undefined} />
      <div style={{ display: "flex" }}>
        <div className="col-md-6">
          <ArticlesSorting fields={sortFields} />
        </div>
        <div className="col-md-6" style={{ paddingRight: 0 }}>
          <ContextSearch onSubmit={onSearchSubmit} fields={searchFields} />
        </div>
      </div>
    </HideInPrintContent>
  );
};

export { FilterBar };
