import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, pure } from "recompose";
import styled from "styled-components";

import { ErrorPanel } from "components/ErrorPanel";

import { ListItem } from "./components/ListItem";
import { MenuTilesChildren } from "./MenuTilesChildren";

const MenuChildren = ({
  menuChildren,
  menuShowOrderedListEnabled,
  showChildrenAsTiles
}) => {
  return (
    <>
      {menuChildren.length > 0 ? (
        <List>
          {showChildrenAsTiles ? (
            <MenuTilesChildren menuChildren={menuChildren} />
          ) : (
            <>
              {menuChildren.map((children, index) => {
                return (
                  <ListItem
                    children={children}
                    key={children.id}
                    index={index}
                    menuShowOrderedListEnabled={menuShowOrderedListEnabled}
                  />
                );
              })}
            </>
          )}
        </List>
      ) : (
        <ErrorPanel header="Brak artykułów" />
      )}
    </>
  );
};

MenuChildren.propTypes = {
  title: PropTypes.string,
  menuChildren: PropTypes.array,
  menuShowOrderedListEnabled: PropTypes.bool
};

MenuChildren.defaultProps = {
  menuChildren: []
};

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const mapStateToProps = ({ menu }) => {
  const { items = [], selectedId } = menu;
  const menuItem = findMenuItemById(items, selectedId);
  if (!menuItem) return {};
  const { children: menuChildren, showChildrenAsTiles } = menuItem;

  return {
    menuChildren: menuChildren !== null ? menuChildren : [],
    showChildrenAsTiles: showChildrenAsTiles
  };
};

const enhance = compose(connect(mapStateToProps), pure);

const findMenuItemById = (menuItems, selectedId) => {
  if (!menuItems) {
    return;
  }
  for (const item of menuItems) {
    // Test current object
    if (item.id?.toString() === selectedId.toString()) {
      return item;
    }
    // Test children recursively
    const child = findMenuItemById(item.children, selectedId);
    if (child) {
      return child;
    }
  }
};

export default enhance(MenuChildren);
