import React from "react";
import styled from "styled-components";

interface IProps {
  header?: string;
  content?: string;
}

const ErrorPanel = ({ header, content }: IProps) => (
  <Container data-testid="test-NoStartArticlePage">
    {header && <Header>{header}</Header>}
    {content && <Content>{content}</Content>}
  </Container>
);

const Container = styled.section`
  border: 1px solid ${({ theme }) => theme.dividerColor};
  border-radius: 0.125rem;
  padding: 1rem;
  background: ${({ theme }) => theme.lightPrimaryColor};

  & > *:not(:last-child) {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
    margin-bottom: 0.75rem;
  }
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.text.primaryColor};
  font-size: 2.25em;
  font-weight: bold;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.text.primaryColor};
  font-size: 1em;
`;

export { ErrorPanel };
