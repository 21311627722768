import React from "react";
import styled from "styled-components";

const Table = ({ children, id }) => {
  return (
    <ResponsiveContainer data-testid={id || "test-Table"}>
      <StyledTable>{children}</StyledTable>
    </ResponsiveContainer>
  );
};

const ResponsiveContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
`;

const StyledTable = styled.table`
  width: 100%;
  max-width: 100%;
`;

export default Table;
