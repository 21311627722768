import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const MenuTile = ({ tile }) => {
  const ref = useRef(null);
  const textLinesCountInTile = 7;
  const [titleLinesCount, setTitleLinesCount] = useState(0);

  useEffect(() => {
    const height = ref ? ref.current.offsetHeight : 0;
    //Divide by 30 because line heigh is 30px
    setTitleLinesCount(Math.round(height / 30));
  }, [ref]);

  return (
    <Tile>
      <HeaderTitle aria-label={tile.name} ref={ref}>
        {tile.name}
      </HeaderTitle>
      <Description
        aria-label={tile.description}
        lineClamp={textLinesCountInTile - titleLinesCount}
      >
        {tile.description}
      </Description>
    </Tile>
  );
};

MenuTile.propTypes = {
  tile: PropTypes.object.isRequired
};

const HeaderTitle = styled.div`
  line-height: 30px;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.dividerColor};
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  max-height: 90px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  overflow: hidden;
  max-height: 145px;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lineClamp};
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-style: oblique;
`;

const Tile = styled.div`
  overflow: hidden;
  padding: 15px;
  height: 200px;
  white-space: pre-line;
  cursor: pointer;

  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  transition: 0.3s;
  border: 1px solid
    ${({ theme }) =>
      theme.layoutDividerColor
        ? theme.layoutDividerColor
        : theme.secondaryColor};
  color: ${({ theme }) => theme.asideMenuItemColor || theme.text.primaryColor};

  &:hover {
    background: ${({ theme }) => theme.lightPrimaryColor};
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
`;

export { MenuTile };
