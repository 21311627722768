import { useMappedState } from "redux-react-hook";

export const useShowDeclarationOfAvailabilitySkipLink = () => {
  const { accessibilityPageEnabled, accessibilityMenuEnabled } = useMappedState(
    mapStateToProps
  );

  return (
    (accessibilityPageEnabled && accessibilityMenuEnabled) ||
    (accessibilityPageEnabled && !accessibilityMenuEnabled)
  );
};

const mapStateToProps = ({ context }: any) => ({
  accessibilityPageEnabled: context.accessibilityPageEnabled,
  accessibilityMenuEnabled: context.accessibilityMenuEnabled
});
