import React from "react";
import queryString from "query-string";
import useRouter from "use-react-router";

import { IMenuArticleTab } from "modules/articles/types/IMenuArticleTab";

import { useGetLocationParams } from "../../utils/useGetLocationParams";

import { ErrorPanel } from "components/ErrorPanel";

import Tabs from "./Tabs";
import { HideInPrintContent } from "../HideInPrintContent";

interface IProps {
  tabs: IMenuArticleTab[];
  showArticlesTable: boolean;
}

const TabsContainer = ({ tabs, showArticlesTable }: IProps) => {
  const { history } = useRouter();
  const parsedParams = useGetLocationParams();

  const onTabChange = (tab: any) => {
    const newParams = {
      ...parsedParams,
      limit: 10,
      page: 1,
      tab
    };
    history.push(`?${queryString.stringify(newParams)}`);
  };

  return (
    <HideInPrintContent>
      <Tabs tabs={tabs} onTabClick={onTabChange} />
      {!showArticlesTable && <ErrorPanel content="Brak pozycji" />}
    </HideInPrintContent>
  );
};

export { TabsContainer };
