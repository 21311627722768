import React from "react";
import { useFormContext } from "react-hook-form";

import { CheckBoxFieldPrototype } from "components/HookForm/CheckBoxFieldPrototype";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  checkedValue: string;
  id: string;
  isRequired?: boolean;
  label?: string;
  customChange?: (currentValue: string) => void;
}

const SurveyCheckBoxField = ({ customChange, ...props }: IProps) => {
  const { setValue } = useFormContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value.toString();
    if (!currentValue || currentValue === "") {
      setValue(props.name, props.checkedValue, {
        shouldValidate: true
      });
    } else {
      setValue(props.name, undefined, {
        shouldValidate: true
      });
    }
    customChange && customChange(currentValue);
  };

  return (
    <CheckBoxFieldPrototype
      handleChange={handleChange}
      {...props}
    />
  );
};

export { SurveyCheckBoxField };
