import { ISurveyQuestion } from "../types/ISurveyQuestion";

export const getDefaultDataValuesFromAnswer = (answer: ISurveyQuestion) => {
  return [
    [
      "Odpowiedzi",
      ...answer.answersCounter.map(answerCounter => answerCounter.value)
    ]
  ];
};
