import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useMappedState } from "redux-react-hook";

import Pagination from "../../pagination/Pagination";
import { createArticlePages } from "./utils/createArticlePages";
import { getBackground } from "../../buttons/utils";

interface IProps {
  articleId: number;
  content: string;
  isHistorical: boolean;
  isPreview: boolean;
}

const Content = ({ articleId, content, isHistorical, isPreview }: IProps) => {
  const { isContrast } = useMappedState(mapStateToProps);
  const [currentPage, setCurrentPage] = useState(1);
  const articlePages = createArticlePages(
    content,
    articleId,
    isHistorical,
    isPreview
  );

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div id="article-section">
      <ArticlePage isContrast={isContrast}>
        {articlePages[currentPage - 1]}
      </ArticlePage>
      {articlePages.length > 1 && (
        <Pagination
          id={"article-section"}
          total={articlePages.length}
          current={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ accessibility }: any) => ({
  isContrast: accessibility.isContrast
});

const ArticlePage = styled.section`
  color: ${({ theme }) => theme.text.primaryColor};
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.text.secondaryColor};
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.secondaryColor};
    }
  }

  a > img {
    display: block;
  }

  li a {
    display: initial;
  }

  a:-moz-focusring {
    outline: 2px solid ${({ isContrast }) => (isContrast ? "white" : "black")};
  }

  span:not(:where(button > span)),
  p {
    ${({ isContrast }) =>
      isContrast &&
      css`
        color: ${getBackground} !important;
      `}
  }
`;

export { Content };
