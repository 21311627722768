import React from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";

const ContentColumn = ({ children }) => {
  const { isBifTheme, isContrast } = useMappedState(mapStateToProps);
  return (
    <StyledColumn
      isBifTheme={isBifTheme}
      isContrast={isContrast}
      className="col-12 col-md-6"
    >
      {children}
    </StyledColumn>
  );
};

const mapStateToProps = ({ context, accessibility }) => ({
  showCreateInformationDate:
    context.showCreateInformationDate.toString() === "true",
  isBifTheme: context.siteTheme === "bif",
  isContrast: accessibility.isContrast
});

const StyledColumn = styled.dd`
  margin: 0;
  color: ${({ theme, isBifTheme, isContrast }) =>
    isBifTheme
      ? isContrast
        ? theme.text.primaryColor
        : "#ff8f00"
      : theme.text.primaryColor};
`;

export { ContentColumn };
