import React from "react";
import PasswordStrengthBar, {
  PasswordStrengthBarProps
} from "react-password-strength-bar";

interface IProps extends PasswordStrengthBarProps {}

const PasswordStrength = ({ ...props }: IProps) => {
  return (
    <PasswordStrengthBar
      scoreWords={[
        "Słabe hasło",
        "Słabe hasło",
        "Przeciętne hasło",
        "Dobre hasło",
        "Silne hasło"
      ]}
      shortScoreWord="Słabe hasło"
      {...props}
    />
  );
};

export { PasswordStrength };
